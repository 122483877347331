<div class="manage-cars-outer" [ngClass]="{'manage-cars-expanded': expandedView }">
    <div class="vehicles-outer" [ngClass]="{'dialog-loader': vehicleListLoader}">
        <ng-scrollbar #vehicleScrollbar class="scrollable-body" [autoHeightDisabled]="false" [autoWidthDisabled]="true">
            <div class="scroll-active-width">
                <div class="vehicle-element" *ngFor="let vehicle of vehicleList; index as i">
                    <div class="vehicle-model" [ngClass]="vehicle.selected ? 'active' : ''"
                        (click)="selectCar(vehicle)">
                        <img class="vehicle-img" [src]="'/assets/icons/dark/ecars/' + vehicle.ECarType.Icon + '.svg'" />
                        <div class="vehicle-name">{{ getSelectedCarName(vehicle) }}</div>
                    </div>
                    <img class="manage-icon-svg"
                        [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/settings_icon.svg'"
                        (click)="editCar(vehicle.userVehicleID)" />
                    <img class="manage-icon-svg"
                        [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/delete_icon.svg'"
                        [ngClass]="{'inactive': vehicleList.length==1}" (click)="removeCar(vehicle)" />
                </div>
                <div class="vehicle-element">
                    <div class="vehicle-model add-car" (click)="addCar()" [hidden]="vehicleList.length>4">
                        <div class="vehicle-img">+</div>
                        <div class="vehicle-name">{{ languageService.languageJSON.ManageCars_AddNewCar }}</div>
                    </div>
                    <div class="silent-warning" [hidden]="vehicleList.length<=4">{{ languageService.languageJSON.ManageCars_VehicleLimitReached }}</div>
                </div>
            </div>
        </ng-scrollbar>
    </div>
    <div class="dialog-loader-outer" *ngIf="vehicleListLoader">
        <mat-spinner class="dialog-loading-spinner"></mat-spinner>
    </div>
</div>