import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChargingStation } from 'src/app/models/charging-station';
import { AccountService } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { LanguageService } from 'src/app/services/language.service';
import { MapService, ShowOnMapEnum } from 'src/app/services/map.service';
import { SettingsService } from 'src/app/services/settings.service';
import { MapElementType } from '../../map/map.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-charger-panel',
  templateUrl: './charger-panel.component.html',
  styleUrls: ['./charger-panel.component.scss']
})
export class ChargerPanelComponent implements OnInit {

  @Input() charger;
  @Input() chargerType: MapElementType;
  constructor(private consoleLoggerService: ConsoleLoggerService, private mapService: MapService,
    private matDialog: MatDialog,
    public settingsService: SettingsService,
    public accountService: AccountService,
    public languageService: LanguageService) { }

  ngOnInit(): void {
    this.mapService.setSearchedMapElement(this.chargerType, this.charger);
  }

  public unmark(): void {
    if (this.chargerType == MapElementType.DisabledChargingStation) {
      this.removeDisabledCharger(this.charger);
    }
    if (this.chargerType == MapElementType.FavoriteChargingStation) {
      this.removeFavoriteCharger(this.charger);
    }
  }

  public removeDisabledCharger(charger: ChargingStation): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      { data: { title: this.languageService.languageJSON.SettingsDialog_ChargingStation_ReEnable_Title,
        message: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_Desc,
        buttons: [this.languageService.languageJSON.Global_Normal,
          this.languageService.languageJSON.Global_Cancel] }, autoFocus: false });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        this.settingsService.setToNormalChargingStation(charger.id);
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setDisabledChargingStations().subscribe();
        }
        this.mapService.ObservableSetChargingStationRecalc.next(true);
        this.mapService.ObservableShowOnMap.next({ type: ShowOnMapEnum.BackSettings, data: this.chargerType });
      }
    });
  }

  public removeFavoriteCharger(charger: ChargingStation): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      { data: { title: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_Title,
        message: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_Desc,
        buttons: [this.languageService.languageJSON.Global_Normal,
          this.languageService.languageJSON.Global_Cancel] }, autoFocus: false });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        this.settingsService.setToNormalChargingStation(charger.id);
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setFavoriteChargingStations().subscribe();
        }
        this.mapService.ObservableSetChargingStationRecalc.next(true);
        this.mapService.ObservableShowOnMap.next({ type: ShowOnMapEnum.BackSettings, data: this.chargerType });
      }
    });
  }

  public goBack(): void {
    this.mapService.ObservableShowOnMap.next({ type: ShowOnMapEnum.BackSettings, data: this.chargerType });
  }
}
