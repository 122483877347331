import { VoiceCommand } from "../services/speech.service";

export class RecognizedVoiceCommand {
    CommadType: VoiceCommand;
    Value: any;

    constructor(commandType: VoiceCommand, value: number) {
        this.CommadType = commandType;
        this.Value = value;
    }
}