<div class="account-dialog" [ngClass]="{'dialog-loader': registerLoader}">

    <h1 mat-dialog-title>
        <span [innerHtml]="accountDialogTitles[activeStepIndex]">{{
            languageService.languageJSON.AccountDialog_Title_Account }}</span>
    </h1>

    <div mat-dialog-content>
        <ng-scrollbar #accountScrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="scrollable-body">
            <!-- SIGN IN -->
            <form [formGroup]="firstStepFormGroup" class="signin" *ngIf="activeStepIndex==0">
                <div class="mat-dialog-row">
                    <div class="input-el">
                        <input placeholder="Email" name="email" formControlName="loginEmail" type="email">
                    </div>
                    <div class="input-el">
                        <input class="password-input" name="current-password"
                            [placeholder]="languageService.languageJSON.Global_Password" formControlName="loginPassword"
                            type="password">
                    </div>
                    <div class="input-el checkbox">
                        <mat-checkbox formControlName="rememberMe">{{
                            languageService.languageJSON.AccountDialog_RememberMe }}</mat-checkbox>
                    </div>
                    <div class="input-el">
                        <h5 class="error-text" *ngIf="firstStepError!=''">{{ firstStepError }}</h5>
                    </div>
                    <div class="input-el">
                        <button class="account-btn" (click)="signIn()">{{
                            languageService.languageJSON.AccountDialog_SignIn }}</button>
                    </div>
                    <div class="input-el">
                        <h5 class="forgot-password" (click)="openForgotPassword()">{{
                            languageService.languageJSON.AccountDialog_ForgotYourPassword }}</h5>
                    </div>
                </div>
                <div class="mat-dialog-row">
                    <div class="input-el" *ngIf="webviewService.IsGPSTWebview()">
                        <button class="account-btn google-btn" (click)="accountService.signInWithGoogle()">
                            <img src="assets/icons/default/social/google_icon.svg" />
                            <span class="account-btn-text">{{ languageService.languageJSON.AccountDialog_SignIn_Google
                                }}</span>
                        </button>
                    </div>

                    <div class="input-el" *ngIf="!webviewService.IsGPSTWebview()">
                        <div id="google-button"></div>
                    </div>

                    <div class="input-el">
                        <button class="account-btn facebook-btn" (click)="accountService.signInWithFacebook()">
                            <img src="assets/icons/default/social/facebook_icon.svg" />
                            <span class="account-btn-text">{{ languageService.languageJSON.AccountDialog_SignIn_Facebook
                                }}</span>
                        </button>
                    </div>
                    <div class="input-el">
                        <button class="account-btn apple-btn" (click)="accountService.signInWithApple()"
                            *ngIf="!preiOS13WebView && !androidWebView">
                            <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/social/apple_logo.svg'" />
                            <span class="account-btn-text">{{ languageService.languageJSON.AccountDialog_SignIn_Apple
                                }}</span>
                        </button>
                    </div>
                </div>
                <div class="mat-dialog-row">
                    <div class="input-el">
                        <button class="account-btn" (click)="nextStep()">{{
                            languageService.languageJSON.AccountDialog_Register }}</button>
                    </div>
                </div>
            </form>

            <!-- REGISTER EMAIL PW -->
            <form [formGroup]="secondStepFormGroup" class="register" *ngIf="activeStepIndex==1">
                <div class="mat-dialog-row">
                    <div class="input-el">
                        <input required name="email" placeholder="Email" formControlName="registerEmail" type="email">
                    </div>
                    <h6 class="invalid" *ngIf="registerEmail.invalid && registerEmail.touched">
                        {{ languageService.languageJSON.AccountDialog_Email_Invalid }}
                    </h6>
                    <h6 class="invalid" *ngIf="existingEmailMsg">{{ existingEmailMsg }}</h6>
                    <div class="input-el">
                        <input required name="new-password" autocomplete="new-password" class="password-input"
                            [placeholder]="languageService.languageJSON.Global_Password"
                            formControlName="registerPassword" type="password">
                    </div>
                    <h6 class="invalid" *ngIf="registerPassword.invalid && registerPassword.touched">
                        {{ languageService.languageJSON.AccountDialog_Password_Invalid }}
                    </h6>
                    <div class="input-el">
                        <input required name="new-password" autocomplete="new-password" class="password-input"
                            [placeholder]="languageService.languageJSON.Global_PasswordConfirmation"
                            formControlName="registerPasswordConfirmation" type="password">
                    </div>
                    <span class="error-container">
                        <h6 class="invalid"
                            *ngIf="registerPasswordConfirmation.invalid && registerPasswordConfirmation.touched">
                            {{ languageService.languageJSON.AccountDialog_Password_Invalid }}</h6>
                        <h6 class="invalid" *ngIf="registerPasswordsMatch">
                            {{ languageService.languageJSON.AccountDialog_Password_NotMatch }}
                        </h6>
                    </span>
                </div>
                <div class="mat-dialog-row">
                    <div class="input-el" *ngIf="webviewService.IsGPSTWebview()">
                        <button class="account-btn google-btn" (click)="accountService.signInWithGoogle()">
                            <img src="assets/icons/default/social/google_icon.svg" />
                            <span class="account-btn-text">{{ languageService.languageJSON.AccountDialog_SignIn_Google
                                }}</span>
                        </button>
                    </div>

                    <div class="input-el" *ngIf="!webviewService.IsGPSTWebview()">
                        <div id="google-button"></div>
                    </div>
                    
                    <div class="input-el">
                        <button type="button" class="account-btn facebook-btn"
                            (click)="accountService.signInWithFacebook()">
                            <img src="assets/icons/default/social/facebook_icon.svg" />
                            <span class="account-btn-text">{{ languageService.languageJSON.AccountDialog_SignIn_Facebook
                                }}</span>
                        </button>
                    </div>
                    <div class="input-el">
                        <button type="button" class="account-btn apple-btn"
                            (click)="accountService.signInWithApple()" *ngIf="!preiOS13WebView && !androidWebView">
                            <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/social/apple_logo.svg'" />
                            <span class="account-btn-text">{{ languageService.languageJSON.AccountDialog_SignIn_Apple
                                }}</span>
                        </button>
                    </div>
                </div>
                <div class="mat-dialog-row">
                    <div class="input-el checkbox">
                        <mat-checkbox formControlName="registerNewsletter">
                            {{ languageService.languageJSON.AccountDialog_SubscribeNewsletter }}
                        </mat-checkbox>
                    </div>
                    <div class="input-el">
                        <h5>{{ languageService.languageJSON.AccountDialog_PrivacyPolicyText }}
                            <a (click)="openPrivacyPolicy()">
                                {{ languageService.languageJSON.AccountDialog_PrivacyPolicyLink }}
                            </a>
                            {{ languageService.languageJSON.Global_And.toLowerCase() }}
                            <a (click)="openTermsOfUse()">
                                {{ languageService.languageJSON.AccountDialog_TermsofUseLink }}
                            </a>
                        </h5>
                    </div>
                </div>
            </form>

            <!-- REGISTER/EDIT PROFILE -->
            <form [formGroup]="thirdStepFormGroup" class="register" *ngIf="activeStepIndex==2 || activeStepIndex==5">
                <div class="mat-dialog-row">
                    <div class="input-el">
                        <label for="avatarimg" name="avatar_btn">
                            <div class="modify-avatar" *ngIf="avatarImg!=null"
                                (click)="deleteAvatarImg(); $event.preventDefault();">
                                <img src="assets/icons/dark/ui/cancel_icon.svg" />
                            </div>
                            <div class="modify-avatar" *ngIf="avatarImg==null">
                                <div *ngIf="avatarImg==null" class="plus-icon">+</div>
                            </div>
                            <div class="avatar">
                                <img id="add-avatar-icon" *ngIf="avatarImg==null"
                                    [src]="'/assets/icons/dark/ui/user_icon.svg'" />
                                <div id="avatar-div" style="display:none"></div>
                                <img id="avatar-img" style="display:none" />
                            </div>
                        </label>
                    </div>
                    <input style="display: none;" name="avatar-upload" type="file" id="avatarimg"
                        (change)="onAvatarChange($event)" accept="image/png, image/jpeg, image/jpg" />
                    <h6 class="invalid invalid-img" *ngIf="invalidImgType">
                        {{ languageService.languageJSON.AccountDialog_Avatar_Error_Type }}
                    </h6>
                    <h6 class="invalid invalid-img" *ngIf="invalidImgSize">
                        {{ languageService.languageJSON.AccountDialog_Avatar_Error_Size }}
                    </h6>
                    <div class="input-el flex-column">
                        <label>{{ languageService.languageJSON.AccountDialog_FirstName }}</label>
                        <div class="required">
                            <input name="firstname" required formControlName="registerFirstName" type="text"
                                maxlength="64">
                        </div>
                    </div>
                    <h6 class="invalid" *ngIf="registerFirstName.invalid && registerFirstName.touched">
                        {{ languageService.languageJSON.AccountDialog_FirstName_Error }}
                    </h6>
                    <div class="input-el flex-column">
                        <label>{{ languageService.languageJSON.AccountDialog_LastName }}</label>
                        <div class="required">
                            <input name="lastname" formControlName="registerLastName" type="text" maxlength="50">
                        </div>
                    </div>
                    <h6 class="invalid" *ngIf="registerLastName.invalid && registerLastName.touched">
                        {{ languageService.languageJSON.AccountDialog_LastName_Error }}
                    </h6>
                    <div class="input-el flex-column">
                        <label>{{ languageService.languageJSON.AccountDialog_Country }}</label>
                        <mat-form-field>
                            <mat-select disableRipple
                                [placeholder]="languageService.languageJSON.AccountDialog_Country_Select"
                                aria-placeholder="" formControlName="registerCountry" class="brand-mat-select"
                                *ngIf="countryList">
                                <mat-option value="">{{ languageService.languageJSON.AccountDialog_PreferNotToTell }}
                                </mat-option>
                                <mat-option *ngFor="let countryEl of countryList" [value]="countryEl.code">
                                    {{countryEl.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <h6 class="invalid" *ngIf="registerCountry.invalid && registerCountry.touched">
                        {{ languageService.languageJSON.AccountDialog_Country_Select }}
                    </h6>
                    <div class="input-el flex-column">
                        <label>{{ languageService.languageJSON.AccountDialog_Phone }}</label>
                        <input formControlName="registerPhone" type="tel" name="telephone" inputmode="numeric"
                            maxlength="50" />
                    </div>
                    <h6 class="invalid"
                        *ngIf="registerPhone.value.length>0 && registerPhone.invalid && registerPhone.touched">
                        {{ languageService.languageJSON.AccountDialog_Phone_Error }}
                    </h6>
                    <div class="input-el flex-column">
                        <h6 class="invalid registration-error" *ngIf="backendErrorMsg && activeStepIndex!=5">
                            {{ languageService.languageJSON.AccountDialog_RegistrationError }}
                            {{ backendErrorMsg }}</h6>
                        <h6 class="invalid registration-error" *ngIf="backendErrorMsg && activeStepIndex==5">
                            {{ backendErrorMsg }}</h6>
                    </div>
                    <div *ngIf="activeStepIndex==5">
                        <div class="input-el checkbox">
                            <mat-checkbox formControlName="registerNewsletter">
                                {{ languageService.languageJSON.AccountDialog_SubscribeNewsletter }}
                            </mat-checkbox>
                        </div>
                        <div class="profile-actions">
                            <h5 *ngIf="!accountService.social" (click)="nextStep()">{{
                                languageService.languageJSON.AccountDialog_ChangePassword }}</h5>
                            <h5 (click)="deleteProfile()">{{ languageService.languageJSON.AccountDialog_DeleteAccount }}
                            </h5>
                        </div>
                    </div>
                </div>
            </form>
            <div class="dialog-loader-outer" *ngIf="registerLoader"
                [ngClass]="{'changepassword-loader': activeStepIndex==6}">
                <mat-spinner class="dialog-loading-spinner"></mat-spinner>
            </div>

            <!-- REGISTER VERIFICATION -->
            <div class="register" *ngIf="activeStepIndex==3">
                <div class="mat-dialog-row">
                    <div class="input-el flex-column">
                        <div class="succ-icon">
                            <img
                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/registration_icon_email.svg'" />
                        </div>
                        <h3>{{ languageService.languageJSON.AccountDialog_Verification }}</h3>
                        <h5 class="h5-verify">
                            {{ languageService.languageJSON.AccountDialog_Verification_Description }}
                        </h5>
                        <h5 *ngIf="!emailSent" class="h5-link" (click)="resendEmail()">
                            {{ languageService.languageJSON.AccountDialog_ResendEmail }}
                        </h5>
                        <h5 *ngIf="emailSent" class="h5-link inactive">{{
                            languageService.languageJSON.AccountDialog_EmailSent }}</h5>
                    </div>
                </div>
            </div>

            <!-- REGISTER VERIFICATION SUCC -->
            <div class="register" *ngIf="activeStepIndex==4">
                <div class="mat-dialog-row">
                    <div class="input-el flex-column" *ngIf="!verifyFailed">
                        <div class="succ-icon">
                            <img
                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/registration_icon_success.svg'" />
                        </div>
                        <h3>{{ languageService.languageJSON.AccountDialog_Success }}</h3>
                        <h5 class="h5-succ">{{
                            languageService.languageJSON.AccountDialog_Verification_Success_Description }}</h5>
                    </div>
                    <div class="input-el flex-column" *ngIf="verifyFailed">
                        <div class="failed-icon">
                            <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/cancel_icon.svg'" />
                        </div>
                        <h3>{{ languageService.languageJSON.AccountDialog_Verification_Error }}</h3>
                        <h5 class="h5-failed">
                            {{ languageService.languageJSON.AccountDialog_Verification_Error_Description }}
                        </h5>
                    </div>
                </div>
            </div>

            <!-- CHANGE PASSWORD -->
            <form class="register" [formGroup]="sixthStepFormGroup" *ngIf="activeStepIndex==6" autocomplete="off">
                <div class="mat-dialog-row" *ngIf="!passwordChangeSuccess">
                    <div class="input-el flex-column">
                        <label>{{ languageService.languageJSON.AccountDialog_ActualPassword }}</label>
                        <span class="pw-span">
                            <input name="old-password" required autocomplete="off" class="password-input"
                                [placeholder]="languageService.languageJSON.Global_Password"
                                formControlName="actualPassword" type="password">
                        </span>
                    </div>
                    <h6 class="invalid" *ngIf="actualPassword.invalid && actualPassword.touched">
                        {{ languageService.languageJSON.AccountDialog_ActualPassword_Invalid }}
                    </h6>
                    <h6 class="invalid" *ngIf="invalidActualPassword">{{
                        languageService.languageJSON.AccountDialog_ActualPassword_Error }}</h6>
                    <div class="input-el flex-column">
                        <label>{{ languageService.languageJSON.AccountDialog_NewPassword }}</label>
                        <span class="pw-span">
                            <input name="new-password" required autocomplete="new-password" class="password-input"
                                [placeholder]="languageService.languageJSON.Global_Password"
                                formControlName="newPassword" type="password">
                        </span>
                    </div>
                    <h6 class="invalid" *ngIf="newPassword.invalid && newPassword.touched">
                        {{ languageService.languageJSON.AccountDialog_Password_Invalid }}
                    </h6>
                    <div class="input-el flex-column">
                        <label>{{ languageService.languageJSON.AccountDialog_NewPasswordConfirmation }}</label>
                        <span class="pw-span">
                            <input required name="new-password" autocomplete="new-password" class="password-input"
                                [placeholder]="languageService.languageJSON.Global_PasswordConfirmation"
                                formControlName="newPasswordConfirmation" type="password">
                        </span>
                    </div>
                    <span class="error-container">
                        <h6 class="invalid" *ngIf="newPasswordConfirmation.invalid && newPasswordConfirmation.touched">
                            {{ languageService.languageJSON.AccountDialog_Password_Invalid }}</h6>
                        <h6 class="invalid" *ngIf="newPasswordsMatch">
                            {{ languageService.languageJSON.AccountDialog_Password_NotMatch }}
                        </h6>
                    </span>
                </div>

                <div class="mat-dialog-row" *ngIf="passwordChangeSuccess">
                    <div class="input-el flex-column">
                        <div class="succ-icon">
                            <img
                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/registration_icon_success.svg'" />
                        </div>
                        <h3>{{ languageService.languageJSON.AccountDialog_Success }}</h3>
                        <h5 class="h5-succ">{{ languageService.languageJSON.AccountDialog_PasswordChanged }}</h5>
                    </div>
                </div>
            </form>

            <!-- FORGOT PASSWORD -->
            <form class="register" [formGroup]="seventhStepFormGroup" *ngIf="activeStepIndex==7">
                <div class="mat-dialog-row">
                    <p>{{ languageService.languageJSON.AccountDialog_ForgotPassword_Description }}:</p>
                    <div class="input-el">
                        <input name="email" required placeholder="Email" formControlName="recoveryEmail" type="email">
                    </div>
                    <h6 class="invalid" *ngIf="recoveryEmail.invalid && recoveryEmail.touched">
                        {{ languageService.languageJSON.AccountDialog_Email_Invalid }}
                    </h6>
                    <h6 class="invalid" *ngIf="existingEmailMsg">{{ existingEmailMsg }}</h6>
                    <p *ngIf="emailSent">{{ languageService.languageJSON.AccountDialog_EmailSent }}</p>
                </div>
            </form>

            <div class="input-el error-bottom" [ngClass]="{'error-bottom-margin': activeStepIndex!== 1 }"
                *ngIf="registerError">
                <h5 class="error-text">{{ languageService.languageJSON.Global_SomethingWentWrong }}</h5>
            </div>
        </ng-scrollbar>
    </div>

    <div mat-dialog-actions [hidden]="activeStepIndex!=0 && activeStepIndex!=3 && activeStepIndex!=4">
        <button *ngIf="activeStepIndex!=0" name="exit_button" mat-button (click)="close()">
            {{ languageService.languageJSON.Global_Ok }}
        </button>
        <button *ngIf="activeStepIndex==0" name="exit_button" mat-button (click)="close()">
            {{ languageService.languageJSON.Global_Cancel }}
        </button>
    </div>

    <div mat-dialog-actions [hidden]="activeStepIndex!=1 && activeStepIndex!=2">
        <button name="back_button" mat-button (click)="goBack()">{{ languageService.languageJSON.Global_Back }}</button>
        <button name="next_button" mat-button (click)="nextStep()">{{ languageService.languageJSON.Global_Next
            }}</button>
    </div>

    <div mat-dialog-actions [hidden]="activeStepIndex!=5">
        <button name="exit_button" mat-button (click)="saveProfile()">{{ languageService.languageJSON.Global_Save
            }}</button>
        <button name="exit_button" mat-button (click)="close()">{{ languageService.languageJSON.Global_Cancel
            }}</button>
    </div>

    <div mat-dialog-actions [hidden]="activeStepIndex!=6">
        <button *ngIf="!passwordChangeSuccess" name="exit_button" mat-button (click)="changePassword()">{{
            languageService.languageJSON.Global_Change }}</button>
        <button *ngIf="!passwordChangeSuccess" name="exit_button" mat-button (click)="goBack()">{{
            languageService.languageJSON.Global_Cancel }}</button>
        <button *ngIf="passwordChangeSuccess" name="exit_button" mat-button (click)="close()">{{
            languageService.languageJSON.Global_Ok }}</button>
    </div>

    <div mat-dialog-actions [hidden]="activeStepIndex!=7">
        <button *ngIf="!emailSent" name="exit_button" mat-button (click)="recoverPassword()">{{
            languageService.languageJSON.Global_Send }}</button>
        <button *ngIf="!emailSent" name="exit_button" mat-button (click)="goBackToLogin()">{{
            languageService.languageJSON.Global_Back }}</button>
        <button *ngIf="emailSent" name="exit_button" mat-button (click)="goBackToLogin()">
            {{ languageService.languageJSON.Global_Ok }}
        </button>
    </div>
</div>