import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionType } from 'src/app/services/account.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-subscription-success-dialog',
  templateUrl: './subscription-success-dialog.component.html',
  styleUrl: './subscription-success-dialog.component.scss'
})
export class SubscriptionSuccessDialogComponent {

  public type: SubscriptionType;

  constructor(public dialogRef: MatDialogRef<SubscriptionSuccessDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService) {
    
    this.type = this.data.subscriptionType;
  }

  public close():void {
    this.dialogRef.close();
  }
}
