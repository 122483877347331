import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InputParamsService } from 'src/app/services/input-params.service';
import { HttpClient } from '@angular/common/http';
import { RecaptchaComponent } from 'ng-recaptcha';
import device from 'current-device';
import { UtilsService } from 'src/app/services/utils.service';
import { BehaviorSubject } from 'rxjs';
import { WebviewService } from 'src/app/services/webview.service';
import { SettingsService } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  @ViewChild('captchaRef') reCaptcha: RecaptchaComponent;
  public rating: number = 0;
  public starCount: number = 5;
  public ratingArr = [];
  public reviewText: string = "";
  public allowSensitiveData: boolean = false;
  public email: string = "";
  public captchaResponse: string = null;
  public recaptchaError: boolean = false;
  public msgError: boolean = false;
  public emailError: boolean = false;
  public success: boolean = false;
  public sending: boolean = false;
  public failed: boolean = false;
  public webView: boolean = false;
  public mobile: boolean = false;

  public ObservableTabChangeRequest: BehaviorSubject<string>;

  constructor(private inputParamsService: InputParamsService, private http: HttpClient, public matDialog: MatDialog, private utilsService: UtilsService,
    public webviewService: WebviewService, public settingsService: SettingsService, public languageService: LanguageService,
    public accountService: AccountService) {
    this.ObservableTabChangeRequest = new BehaviorSubject<string>(null);

    if (this.webviewService.IsGPSTWebview()) {
      this.webView = true;
    }
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.mobile = device.mobile();

    if (this.accountService.getIsAuthorized()) {
      this.email = this.accountService.getUserProfile().Profile.Email;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.mobile) {
      setTimeout(() => {
        if (document.getElementsByClassName("g-recaptcha-bubble-arrow").length > 0) {
          document.getElementsByClassName("g-recaptcha-bubble-arrow")[0].parentElement.classList.add("recaptcha-resize");
        }
        else {
          // really slow devices
          setTimeout(() => {
            if (document.getElementsByClassName("g-recaptcha-bubble-arrow").length > 0) {
              document.getElementsByClassName("g-recaptcha-bubble-arrow")[0].parentElement.classList.add("recaptcha-resize");
            }
          }, 3000);
        }
      }, 1000);
    }
  }

  public showIcon(index: number): boolean {
    if (this.rating >= index + 1) {
      return true;
    } else {
      return false;
    }
  }

  public openPrivacyPolicy(): void {
    this.ObservableTabChangeRequest.next("privacy");
  }

  public openEula(): void {
    this.ObservableTabChangeRequest.next("legal");
  }

  public accept(): void {
    if (!this.failed && !this.sending && !this.success) {
      this.msgError = false;
      this.recaptchaError = false;
      this.emailError = false;
      this.failed = false;

      if (this.captchaResponse == null) {
        this.recaptchaError = true;
      }
      if (this.email != "" && !this.utilsService.validateEmail(this.email) || this.email == "") {
        this.emailError = true;
      }
      if (this.rating == 0 && this.reviewText == "") {
        this.msgError = true;
      }
      if (!this.msgError && !this.emailError && !this.recaptchaError) {
        if (this.email == "") {
          this.email = null;
        }
        const emailJson = {
          captchaResponse: this.captchaResponse, email: this.email, rating: this.rating, reviewText: this.reviewText,
          userAgent: null, inputParams: null
        };
        if (this.allowSensitiveData) {
          emailJson.userAgent = navigator.userAgent;
          emailJson.inputParams = JSON.stringify(this.inputParamsService.getRangeParams());
        }
        if (this.accountService.getIsAuthorized()) {
          let userJson = { ...this.accountService.getUserProfile() };
          if (userJson.HistoryList) {
            delete userJson.HistoryList;
          }
          emailJson.inputParams += "\r\n" + JSON.stringify(userJson);
        }
        this.sending = true;
        let emailUrl = `${environment.BACKEND_URL}mail/mail.php`;

        this.http.post(emailUrl, emailJson).subscribe((resp) => {
          this.sending = false;
          this.success = true;
        },
          () => {
            this.sending = false;
            this.failed = true;
            setTimeout(() => {
              this.failed = false;
            }, 5000);
          });
      }
    }
  }

  public resetform(): void {
    this.sending = false;
    this.success = false;
    this.rating = 0;
    this.email = "";
    this.reviewText = "";
    if (this.accountService.getIsAuthorized()) {
      this.email = this.accountService.getUserProfile().Profile.Email;
    }
  }

  public resolved(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;
  }

  ngOnDestroy() {
  }
}
