import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, Observer, forkJoin } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { HttpClient } from '@angular/common/http';
import { MapService } from '../services/map.service';
import { DataParserService } from '../services/data-parser.service';
import { AccountService } from '../services/account.service';
import { LanguageService } from '../services/language.service';
import { SettingsService } from '../services/settings.service';
import { environment } from 'src/environments/environment.dev';

export const initResolver: ResolveFn<Observable<boolean>> = (route, state) => {

  const utilsService = inject(UtilsService);
  const http = inject(HttpClient);
  const mapService = inject(MapService);
  const dataParserService = inject(DataParserService);
  const accountService = inject(AccountService);
  const settingsSerivce = inject(SettingsService);
  const languageService = inject(LanguageService);

  const versionUrl = `${environment.BACKEND_URL}chargingstations/getversion.php`;

  const observable: Observable<boolean> = new Observable((observer: Observer<boolean>) => {
    http.get(versionUrl, { responseType: 'text' }).subscribe((version: any) => {
      mapService.setChargingStationVersion(version);

      const allVehiclesUrl = `${environment.BACKEND_URL}chargingstations/allvehicles.json?ver=${version}`;
      const chargingOperatorsUrl = `${environment.BACKEND_URL}chargingstations/operators.json?ver=${version}`;

      let languageSettings = "en";
      try {
        if (localStorage.getItem("language")) {
          languageSettings = localStorage.getItem("language");
        }
      } catch (e) { }

      forkJoin([http.get(allVehiclesUrl), http.get(chargingOperatorsUrl), http.get(`/assets/json/language_${languageSettings}.json`)]).subscribe((resp: any) => {
        if (resp[1]?.operators?.length > 0) {
          const parsedOperators = dataParserService.parseChargingOperatorsArray(resp[1].operators);
          mapService.setOperatorsArray(parsedOperators);
        }

        if (resp[0]?.length > 0) {
          const parsedVehices = dataParserService.parseVehiclesArray(resp[0]);
          accountService.initAllVehicles(parsedVehices);
          mapService.initAllVehicles(parsedVehices);

          // user vehicles must be loaded after all vehicles initialized
          if (accountService.getIsAuthorized()) {
            accountService.getUserVehicles().subscribe(() => {
              observer.next(true);
              observer.complete();
            }, (error) => {
              observer.next(true);
              observer.complete();
            });
          }
          else {
            observer.next(true);
            observer.complete();
          }
        }

        if (resp[2]) {
          settingsSerivce.setDefaultLanguage(languageSettings);
          languageService.setDefaultLanguage(resp[2]);
          utilsService.initTurnList();
        }
      });
    });
  });

  return observable;
};
