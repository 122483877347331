import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { skip } from 'rxjs/operators';
import { SettingsService } from 'src/app/services/settings.service';
import { SpeechService } from 'src/app/services/speech.service';

@Component({
  selector: 'app-voice-command-listen-dialog',
  templateUrl: './voice-command-listen-dialog.component.html',
  styleUrls: ['./voice-command-listen-dialog.component.scss']
})
export class VoiceCommandListenDialogComponent implements OnInit {

  public recognizedCommand = {
    sentences: "Say something...",
    confidence: 0
  };

  constructor(
    public dialogRef: MatDialogRef<VoiceCommandListenDialogComponent>,
    public settingsService: SettingsService,
    private speechService: SpeechService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.speechService.ObservableTestRecognizedVoiceCommand.pipe(skip(1)).subscribe((resp) => {
      if (resp) {
        this.recognizedCommand = resp;
        this.cdr.detectChanges();
      }
    })

    setTimeout(() => {
      this.dialogRef.close();
    }, 5000);
  }
}
