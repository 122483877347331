import { ECarType } from "./ecar-type";

export class ECar {
  userVehicleID: number;
  name: string;
  ECarType: ECarType;
  selected: boolean;
  settings: ECarSettings;
}

export interface ECarSettings {
  batteryLevel: number, weight: number, tirePressure: number, userBehavior: number, speedLimiter: number,
  batterySafetyLimit?: number, batterySafetyLimitRha: number, batterySafetyLimitRoute: number, minBatteryAtDestination: number,
  batteryStateOfHealth?: number, fastChargeLimit?: number, tripRecording?: number
}