import { Pipe, PipeTransform } from '@angular/core';

/** Gives back objects within the selected property contains the searched test */

@Pipe({
  name: 'searchProperty'
})
export class SearchPropertyPipe implements PipeTransform {

  /** Comparing input values property to searched string */
  public transform(searchList: any[], propertyName: string, searchValue: string) {
    if (searchValue) {
      return searchList.filter((el: any) => {
        if (el && el[propertyName]) {
          return (el[propertyName] as string).toString().toLocaleLowerCase().includes(searchValue.toString().toLocaleLowerCase());
        }
        else {
          return false;
        }
      });
    }
    else {
      return searchList;
    }
  }
}