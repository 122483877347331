<div class="policy-text">
    <a href=""></a>
    <h3>What Are Cookies?</h3>

    <p>As is common practice with almost all professional websites, this site uses cookies, which are tiny files that
        are downloaded to your computer to improve your experience. This page describes what information they gather,
        how we use it, and why we sometimes need to store these cookies. We will also share how you can prevent these
        cookies from being stored, however, this may downgrade or "break" certain elements of the site's functionality.
    </p>

    <p>For more general information on cookies, see the Wikipedia article on HTTP Cookies.</p>

    <h3>How We Use Cookies</h3>

    <p>We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, there are no industry
        standard options for disabling cookies without completely disabling the functionality and features they add to
        this site. If you are not sure whether you need them in a service you use, it is recommended that you leave on
        all cookies.</p>

    <h3>Disabling Cookies</h3>

    <p>You can prevent the use of cookies by adjusting the settings in your browser (see your browser's "Help" option on
        how to do this). Be aware that disabling cookies will affect the functionality of this site, along with many
        other websites you visit. Disabling cookies usually also results in disabling certain functionalities and
        features of the website. Therefore, it is recommended that you do not disable cookies.</p>

    <h3>The Cookies We Set</h3>
    <ul>
        <li>Site preferences cookies.</li>

        <li>In order to provide you with a great experience on this site, we provide the functionality to set your
            preferences on how this site runs while you use it. In order to remember your preferences, we need to set
            cookies so that this information can be called whenever you interact with a page that is affected by your
            preferences.</li>
    </ul>

    <h3>Third Party Cookies</h3>

    <p>In some special cases, we also use cookies provided by trusted third parties. The following section details which
        third party cookies you might encounter through this site:</p>

    <ul>
        <li>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the
            web for helping us understand how you use the site and ways that we can improve your experience. These
            cookies may track things such as how much time you spend on the site and the pages you visit, so that we can
            continue producing engaging content.</li>

        <li>For more information on Google Analytics cookies, see the official Google Analytics page.</li>
        <li>We use the Google Places API. Google Places API may collect and process information about your actual
            location. We do not combine the information collected through the use of Google Analytics with personally
            identifiable information. Google’s ability to use and share information collected by the Google Places API
            is governed by the
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Google Privacy
                Policy</a>.
        </li>
    </ul>

    <h3>Data transfers to third countries</h3>

    <p>A transfer to states outside the European Economic Area does not take place.</p>

    <h3>Period of data storage</h3>

    <p>We erase or anonymize the data to be processed as soon as they are no longer required for the purposes
        for which we have collected or used them in accordance with the above paragraphs. As a rule, we store
        the data to be processed for the duration of the usage or contractual relationship via the app plus a
        period of 30 days during which we store backup copies after erasure, provided that this data is no
        longer needed for criminal prosecution or to secure, pursue or enforce legal claims.</p>

    <p>Specific details in this data protection declaration or legal requirements for the storage and erasure of
        personal data, in particular those which we have to store for tax reasons, remain unaffected.</p>

    <h3>Rights of the data subject</h3>

    <p>Contact: <a href="mailto:support@evnavigation.com">support&#64;evnavigation.com</a></p>

    <h3>Right of access</h3>

    <p>You have the right to obtain from us at any time upon request information on the personal data processed
        by us concerning you in the scope of Art. 15 GDPR. For this purpose, you may submit an application by
        post or by e-mail to the address given above.</p>

    <h3>Right to rectification</h3>

    <p>You have the right to ask us to correct your personal data immediately if it is inaccurate. If you wish
        to do so, please contact us at the above address.</p>

    <h3>Right to erasure</h3>

    <p>You have the right, under the conditions described in Art. 17 GDPR, to request us to erase your personal
        data. In particular, these conditions provide for a right of erasure if the personal data are no longer
        necessary for the purposes for which they were collected or otherwise processed, or in cases of unlawful
        processing, the existence of an objection or the existence of a duty to erase under Union law or the law
        of the Member State to which we are subject. For the period of data storage, please see Section 5 of
        this data protection declaration. In order to assert your right to erasure, please contact us at the
        above address.</p>

    <h3>Right to restriction processing</h3>

    <p>You have the right to demand from us the restriction of processing in accordance with Art. 18 GDPR. This
        right exists in particular if the correctness of the personal data is disputed between the user and us,
        for the duration which the verification of the correctness requires, as well as in the event that the
        user demands limited processing instead of erasure in the case of an existing right to erasure;
        furthermore in the event that the data is no longer necessary for the purposes pursued by us, but the
        user requires it for the assertion, exercise or defense of legal claims, and if the successful exercise
        of an objection between us and the user is still disputed. To exercise your right to restrict
        processing, please contact us at the above address.</p>

    <h3>Right to data portability</h3>

    <p>You have the right to receive from us the personal data concerning you which you have provided to us in a
        structured, common, machine-readable format in accordance with Art. 20 GDPR. To exercise your right to
        data portability, please contact us at the above address.</p>

    <h3>Right to object</h3>

    <p>You have the right, for reasons arising from your particular situation, to object at any time to the
        processing of personal data concerning you, which is carried out, among other things, on the basis of
        Art. 6 para. 1 lit. e) or f) GDPR, pursuant to Art. 21 GDPR. We will terminate the processing of the
        data to be processed unless we can prove compelling grounds for processing worthy of protection which
        outweigh your interests, rights and freedoms, or if the processing serves the assertion, exercise or
        defense of legal claims.</p>

    <h3>Right to complain</h3>

    <p>You also have the right to address complaints to the competent supervisory authority.</p>


    <h3>Changes to this Data Protection Declaration</h3>

    <p>We always keep this data protection declaration up to date. Therefore, we reserve the right to change it
        from time to time and to update changes in the collection, processing or use of your data.</p>

    <h3>Third Party Accounts</h3>

    <p>EVNavigation.com allows you to sign up and log in to the Services using accounts you create with third-party
        products
        and services, such as Facebook, Google, or Apple (collectively, “Third-Party Accounts”). If you access the
        Services with
        Third-Party Accounts we will collect information that you have agreed to make available such as your name, email
        address, profile information, and preferences. This information is collected by the Third-Party Account provider
        and is
        provided to EVNavigation.com under their privacy policies. You can generally control the information that we
        receive
        from these sources using the privacy controls in your Third-Party Account.</p>

    <h3>More Information</h3>

    <p>If you are looking for more information, feel free to contact us via email:</p>

    <p>Email: <a href="mailto:info&#64;gpstuner.com">info&#64;gpstuner.com</a></p>
</div>