<!-- LOADER -->        
<div class="dialog-loader-outer" *ngIf="historyLoader">
    <mat-spinner class="dialog-loading-spinner"></mat-spinner>
</div>
<div class="account-dialog" [ngClass]="{'dialog-loader': historyLoader}">

    <h1 mat-dialog-title>
        <span>{{ languageService.languageJSON.Global_History }}</span>
    </h1>
    <div mat-dialog-content>
        <!-- CAR SELECTOR -->
        <div class="mat-dialog-row remove-top-padding user-car-selector-row">
            <app-user-cars-selector [historySelector]="true" (valueChange)="setSelectedUserVehicle($event)">
            </app-user-cars-selector>
        </div>

        <!-- HISTORIES -->
        <div class="history-outer">
            <ng-scrollbar class="scrollable-body inner-scrollbar" [autoHeightDisabled]="false" [autoWidthDisabled]="true">
                <div class="history-element-outer" *ngFor="let historyItem of historyList">
                    <div class="history-element-outer-header" (click)="historyItem.opened = ! historyItem.opened">
                        <div class="title">
                            {{ historyItem.startTime | date:'shortDate' }}
                        </div>
                        <div class="opener" [ngClass]="{'opened': historyItem.opened}"><img
                                [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/open_arrow.svg'" />
                        </div>
                    </div>
                    <div class="history-element" [ngClass]="{'opened': historyItem.opened}"
                        (click)="openRouteHistoryOnMap(historyItem)">
                        <div class="header">
                            <div class="title">
                                <p>{{ historyItem.startLocation }} - {{ historyItem.targetLocation }}</p>
                                <p>{{ historyItem.startTime | date }}</p>
                            </div>
                            <div class="remove" (click)="removeHistoryElement($event, historyItem)">
                                <img src="/assets/icons/dark/ui/cancel_icon.svg" />
                            </div>
                        </div>
                        <div class="content">
                            <div class="vehicle-row">
                                <div class="vehicle-tile-property">
                                    <div class="vehicle-tile-property-name">
                                        <img
                                            [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_range.svg'" />
                                    </div>

                                    <div class="vehicle-tile-property-value"
                                        [hidden]="settingsService.getUnit().Distance!='metric'" name="range_value">
                                        {{ historyItem.distance }} km
                                    </div>
                                    <div class="vehicle-tile-property-value"
                                        [hidden]="settingsService.getUnit().Distance!='imperial'" name="range_value">
                                        {{ (historyItem.distance * 6.21371192)/10 | turnValueIntegerThousands }} mi
                                    </div>
                                </div>
                                <div class="vehicle-tile-property">
                                    <div class="vehicle-tile-property-name">
                                        <img
                                            [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/trip_icon_total_time.svg'" />
                                    </div>
                                    <div class="vehicle-tile-property-value vehicle-tile-property-time"
                                        name="time_value">
                                        <span *ngIf="historyItem.totalTime.h">
                                            {{ historyItem.totalTime.h }}{{ languageService.languageJSON.Unit_Hour_Short
                                            }}
                                            {{ historyItem.totalTime.i }} {{
                                            languageService.languageJSON.Unit_Minute_Short }}
                                        </span>
                                        <span *ngIf="!historyItem.totalTime.h">
                                            {{ historyItem.totalTime.i }} {{
                                            languageService.languageJSON.Unit_Minute_Short }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="vehicle-row">
                                <div class="vehicle-tile-property">
                                    <div class="vehicle-tile-property-name">
                                        <img
                                            [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_capacity.svg'" />
                                    </div>
                                    <div class="vehicle-tile-property-value"
                                        [hidden]="settingsService.getUnit().Distance!='metric'" name="topspeed_value">
                                        {{ historyItem.avgConsumption }}
                                        kWh/100km</div>
                                    <div class="vehicle-tile-property-value"
                                        [hidden]="settingsService.getUnit().Distance!='imperial'" name="topspeed_value">
                                        {{ (historyItem.avgConsumption *16.09344)/10 | turnValueIntegerThousands }} kWh/100mi</div>
                                </div>
                                <div class="vehicle-tile-property">
                                    <div class="vehicle-tile-property-name">
                                        <img
                                            [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/trip_icon_total_consumption.svg'" />
                                    </div>
                                    <div class="vehicle-tile-property-value" name="totalpower_value">{{
                                        historyItem.totalConsumption }} kWh</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-element-outer delete-all">
                    <button class="evnavi-button" [ngClass]="{'inactive': !historyList || historyList.length==0 }"
                        (click)="removeAllHistoryElement()">
                        {{ languageService.languageJSON.HistoryDialog_DeleteAllTrip}}</button>
                </div>
            </ng-scrollbar>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="close()">{{ languageService.languageJSON.Global_Close }}</button>
    </div>

</div>