import { Injectable } from '@angular/core';
import { LatLng } from 'leaflet';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { LocationService } from './location.service';
import { WebviewService } from './webview.service';
import { ConsoleLoggerService } from './console-logger.service';
import { RangeParams, Waypoint } from '../models/range-params';
import { Position } from '../models/position';
import { WaypointAddress } from '../models/route';
import { RouteListElement } from '../models/route-list';
import { WindTemp } from '../models/wind-temp';
import { ECarSettings } from '../models/ecar';
import { FailedPlan } from '../models/failed-plan';

@Injectable({
  providedIn: 'root'
})
export class InputParamsService {

  private ECarSettings: ECarSettings;
  private WaypointsParams: Waypoint[] = [];
  private SelectedMode: string = "rha";
  private Navigation: boolean = false;
  private SearchedRangeOrRoute: boolean = false;
  private ReverseGeocodedLocations: WaypointAddress[];
  private Bearing: number = null;
  private FailedPlan: FailedPlan = null;
  private WaypointsRouteList: RouteListElement[] = [];
  public SetMapToBoundingBox: boolean = false;
  public AcceptCookies: boolean = true;
  private NextWaypointIdx: number = 0;

  public ObservableRangeInputParams: BehaviorSubject<RangeParams>;
  public ObservableWindTempParams: BehaviorSubject<WindTemp>;
  public ObservableWaypointsParams: BehaviorSubject<Waypoint[]>;
  public ObservableWaypointsRouteListParams: BehaviorSubject<RouteListElement[]>;
  public ObservableSelectedMode: BehaviorSubject<string>;
  private ObservableSearchedRangeOrRoute: BehaviorSubject<boolean>;
  public ObservableReverseGeocodedLocations: BehaviorSubject<WaypointAddress[]>;
  public ObservableBearing: BehaviorSubject<number>;
  public ObservableSameCoordsError: BehaviorSubject<boolean>;
  public ObservableFailedPlan: BehaviorSubject<FailedPlan>;
  public ObservableNoRoute: BehaviorSubject<boolean>;
  public ObservableGpsErrorDialog: BehaviorSubject<boolean>;
  public ObservableAddEndPoint: BehaviorSubject<RouteListElement>;
  public ObservableAddRoutePoint: BehaviorSubject<{ RouteListElement: RouteListElement, Index: number }>;
  public ObservableSetStartPointGeocode: BehaviorSubject<RouteListElement>;
  public ObservableReverseGeocodingLoader: BehaviorSubject<boolean>;
  public ObservableBatteryChanged: BehaviorSubject<number>;

  constructor(private locationService: LocationService, public webviewService: WebviewService,
    private consoleLoggerService: ConsoleLoggerService) {

    this.initObservables();
  }

  private initObservables(): void {
    this.ObservableGpsErrorDialog = new BehaviorSubject<boolean>(null);
    this.ObservableAddEndPoint = new BehaviorSubject <RouteListElement>(null);
    this.ObservableAddRoutePoint = new BehaviorSubject<{ RouteListElement: RouteListElement, Index: number }>(null);
    this.ObservableSetStartPointGeocode = new BehaviorSubject<RouteListElement>(null);
    this.ObservableReverseGeocodingLoader = new BehaviorSubject<boolean>(null);
    this.ObservableRangeInputParams = new BehaviorSubject<RangeParams>(null);
    this.ObservableWaypointsParams = new BehaviorSubject<Waypoint[]>(this.WaypointsParams);
    this.ObservableWaypointsRouteListParams = new BehaviorSubject<RouteListElement[]>(this.WaypointsRouteList);
    this.ObservableSelectedMode = new BehaviorSubject<string>(this.SelectedMode);
    this.ObservableSearchedRangeOrRoute = new BehaviorSubject<boolean>(this.SearchedRangeOrRoute);
    this.ObservableReverseGeocodedLocations = new BehaviorSubject<WaypointAddress[]>(this.ReverseGeocodedLocations);
    this.ObservableBearing = new BehaviorSubject<number>(this.Bearing);
    this.ObservableSameCoordsError = new BehaviorSubject<boolean>(null);
    this.ObservableFailedPlan = new BehaviorSubject<FailedPlan>(this.FailedPlan);
    this.ObservableNoRoute = new BehaviorSubject<boolean>(false);
    this.ObservableBatteryChanged = new BehaviorSubject<number>(null);
  }

  resolve(): void {
    this.loadCookies();
  }

  private loadCookies(): void {
    //read cached data
    let waypointsStorage = localStorage.getItem("waypoints");
    if (waypointsStorage && waypointsStorage != 'null') {
      this.setWaypointParams(JSON.parse(waypointsStorage));
    }
    else {
      //default start coords params
      this.setStartCoordsParams(new LatLng(52.520008, 13.404954));
    }
    if (waypointsStorage && JSON.parse(waypointsStorage).length > 1) {
      this.setSelectedMode('route');
    }
  }

  public loadCarSettingsCookies(): void {
    let batteryStateOfHealth, fastChargeLimit;
    try {
      batteryStateOfHealth = JSON.parse(localStorage.getItem("batteryStateOfHealth"));
    } catch (e) { }
    if (batteryStateOfHealth != null) {
      this.setBatteryStateOfHealth(batteryStateOfHealth)
    }
    try {
      fastChargeLimit = JSON.parse(localStorage.getItem("fastChargeLimit"));
    } catch (e) { }
    if (fastChargeLimit != null) {
      this.setFastChargeLimit(fastChargeLimit);
    }
  }

  public setSliderRangeInputParams(params: any): void {
    if (this.ECarSettings && this.ECarSettings.batteryStateOfHealth) {
      params.batteryStateOfHealth = this.ECarSettings.batteryStateOfHealth;
    }
    if (this.ECarSettings && this.ECarSettings.fastChargeLimit) {
      params.fastChargeLimit = this.ECarSettings.fastChargeLimit;
    }
    this.ECarSettings = params;
    this.paramsUpdate();
  }

  public setECarSettings(params: ECarSettings): void {
    this.ECarSettings = params;
    this.paramsUpdate();
  }

  public getECarSettings(): ECarSettings {
    return this.ECarSettings;
  }

  public getBattery(): number {
    return this.ECarSettings.batteryLevel;
  }

  public setBattery(batteryLevel: number): void {
    if (batteryLevel) {
      this.ECarSettings.batteryLevel = batteryLevel;
    }
  }

  public setWeight(weight: number): void {
    this.ECarSettings.weight = weight;
  }

  public getWaypointsParams(): Waypoint[] {
    return this.WaypointsParams;
  }

  public getBatteryStateOfHealth(): number {
    if (this.ECarSettings.batteryStateOfHealth) {
      return this.ECarSettings.batteryStateOfHealth;
    }
    else {
      return 100;
    }
  }

  public setBatteryStateOfHealth(batteryStateOfHealth): void {
    this.ECarSettings.batteryStateOfHealth = batteryStateOfHealth;
    this.paramsUpdate();
  }

  public getFastChargeLimit(): number {
    return this.ECarSettings.fastChargeLimit;
  }

  public setFastChargeLimit(fastChargeLimit): void {
    this.ECarSettings.fastChargeLimit = fastChargeLimit;
    this.paramsUpdate();
  }

  public setNextWaypointIdx(nextWaypointIdx: number) {
    this.NextWaypointIdx = nextWaypointIdx;
  }

  public setWaypointParams(waypoints: Waypoint[]): void {
    if (waypoints.length > 1 && this.getSelectedMode() == "rha") {
      this.setSelectedMode("route");
    }

    for (let i = 0; i < waypoints.length; i++) {
      if (waypoints[i].lat == 0 && waypoints[i].lng == 0) {
        waypoints.splice(i, 1);
        i--;
      }
    }

    let notChangedArray = true;
    if (waypoints.length == this.WaypointsParams.length) {
      for (let i = 0; i < waypoints.length; i++) {
        if (waypoints[i].lat != this.WaypointsParams[i].lat || waypoints[i].lng != this.WaypointsParams[i].lng) {
          notChangedArray = false;
        }
      }
    }
    else {
      notChangedArray = false
    }

    if (notChangedArray == false) {
      // same coordinates error exception
      /*let samecoordErr = false;
      if (waypoints.length == 2) {
        for (let i = 1; i < waypoints.length; i++) {
          if (Math.abs(waypoints[i - 1].lat - waypoints[i].lat) < 0.0004 && Math.abs(waypoints[i - 1].lng - waypoints[i].lng)) {
            samecoordErr = true;
          }
        }
      }

      if (samecoordErr) {
        this.ObservableSameCoordsError.next(true);
      }
      else {*/
      this.WaypointsParams = waypoints;
      this.ObservableWaypointsParams.next(waypoints);

      this.paramsUpdate();
      /*}*/
    }
  }

  public setWaypointsRouteList(param: RouteListElement[]): void {
    this.WaypointsRouteList = param;
    this.ObservableWaypointsRouteListParams.next(param);
  }

  public getWaypointsRouteList(): RouteListElement[] {
    return this.WaypointsRouteList;
  }

  public getStartCoordsParams(): Waypoint {
    return this.WaypointsParams[0];
  }

  public setStartCoordsParams(startcoords: Waypoint): void {
    const waypointsCopy = [...this.WaypointsParams];
    waypointsCopy[0] = startcoords;
    this.setWaypointParams(waypointsCopy);
  }

  public unshiftStartCoordsParams(startcoords: Waypoint): void {
    const waypointsCopy = [...this.WaypointsParams];
    waypointsCopy.unshift(startcoords);
    this.setWaypointParams(waypointsCopy);
  }

  public addEndpoint(endpoint: RouteListElement): void {
    // remove last endpoint if planning failed
    if (this.WaypointsParams.length > 1 && this.SelectedMode == "rha") {
      this.deleteWaypoint(1);
    }

    const waypointsCopy = [... this.WaypointsParams];
    waypointsCopy.push(new Waypoint(endpoint.lat, endpoint.lng, endpoint.csid));
    endpoint.wp = waypointsCopy.length - 1;
    this.ObservableAddEndPoint.next(endpoint);
    this.setWaypointParams(waypointsCopy);
  }

  public addRoutePoint(routeListElement: RouteListElement, index: number): void {
    // remove last endpoint if planning failed
    if (this.WaypointsParams.length > 1 && this.SelectedMode == "rha") {
      this.deleteWaypoint(1);
    }

    const waypointsCopy = [... this.WaypointsParams];
    waypointsCopy.splice(index, 0, new Waypoint(routeListElement.lat, routeListElement.lng, routeListElement.csid));
    routeListElement.wp = waypointsCopy.length - 1;
    this.ObservableAddRoutePoint.next({ RouteListElement: routeListElement, Index: index });
    this.setWaypointParams(waypointsCopy);
  }

  public deleteWaypoint(i: number): void {
    const waypointsCopy = [...this.WaypointsParams];
    waypointsCopy.splice(i, 1);
    this.setWaypointParams(waypointsCopy);
  }

  public deleteWaypointWithoutReplan(i: number) {
    this.WaypointsParams.splice(i, 1);
    localStorage.setItem("waypoints", JSON.stringify(this.WaypointsParams));
  }

  public deleteChargingWaypointWithoutReplan(ChargingStationId) {
    this.WaypointsParams = this.WaypointsParams.filter((el) => { return !(el.csid && el.csid == ChargingStationId) });
    localStorage.setItem("waypoints", JSON.stringify(this.WaypointsParams));
  }

  public setStartCoordsToYourGeolocation(unshiftWaypoints?): Observable<LatLng | string> {
    return new Observable((observer: Observer<LatLng | string>) => {
      if (!this.webviewService.IsGPSTWebview() && !window.navigator.userAgent.includes("FB_IAB")) {
        this.locationService.getCurrentPosition().subscribe(
          (position) => {
            if (unshiftWaypoints) {
              this.unshiftStartCoordsParams(new LatLng(position.coords.latitude, position.coords.longitude));
            }
            else {
              this.setStartCoordsParams(new LatLng(position.coords.latitude, position.coords.longitude));
            }
            observer.next(new LatLng(position.coords.latitude, position.coords.longitude));
            observer.complete();
          },
          (error) => {
            observer.next("nogps");
            observer.complete();
          }
        );
      }
      else {
        this.webviewService.SetStartCoordsToYourGeolocation();
        const lastPosition: Position = this.locationService.getLastPosition();
        if (lastPosition) {
          if (unshiftWaypoints) {
            this.unshiftStartCoordsParams(new LatLng(lastPosition.Latitude, lastPosition.Longitude));
          }
          else {
            this.setStartCoordsParams(new LatLng(lastPosition.Latitude, lastPosition.Longitude));
          }
          observer.next(new LatLng(lastPosition.Latitude, lastPosition.Longitude));
          observer.complete();
        }
      }
    });
  }

  public setSelectedMode(param: string): void {
    this.SelectedMode = param;
    this.ObservableSelectedMode.next(param);
  }

  public getSelectedMode(): string {
    return this.SelectedMode;
  }

  public setNavigation(navigation: boolean): void {
    this.Navigation = navigation;
  }

  public getNavigation(): boolean {
    return this.Navigation;
  }

  public setSearchedRangeOrRoute(param: boolean): void {
    this.SearchedRangeOrRoute = param;
    this.ObservableSearchedRangeOrRoute.next(param);
  }

  public getSearchedRangeOrRoute(): boolean {
    return this.SearchedRangeOrRoute;
  }

  public paramsUpdate(): void {
    const RP: RangeParams = new RangeParams();
    RP.RangeInputParams = this.ECarSettings;
    RP.TempWindParams = { temperature: 0, windDeg: 0, windSpeed: 0 };

    /*const waypoints = [...this.WaypointsParams];
    if (this.Navigation) {
      waypoints.splice(1, this.NextWaypointIdx);
      this.WaypointsParams = waypoints;
      this.ObservableWaypointsParams.next(waypoints);
    }*/

    RP.Waypoints = this.WaypointsParams;
    this.ObservableRangeInputParams.next(RP);
  }

  public getRangeParams(): RangeParams {
    const RP: RangeParams = new RangeParams();
    RP.RangeInputParams = this.ECarSettings;
    RP.TempWindParams = { temperature: 0, windDeg: 0, windSpeed: 0 };
    RP.Waypoints = this.WaypointsParams;
    return RP;
  }

  public setReverseGeocodedLocations(params: WaypointAddress[]): void {
    this.ReverseGeocodedLocations = params;
    this.ObservableReverseGeocodedLocations.next(params);
  }

  public getReverseGeocodedLocations(): WaypointAddress[] {
    return this.ReverseGeocodedLocations;
  }

  public setBearing(param: number): void {
    this.Bearing = param;
    this.ObservableBearing.next(param);
  }

  public getBearing(): number {
    return this.Bearing;
  }

  public setFailedPlan(param: FailedPlan): any {
    this.FailedPlan = param;
    this.ObservableFailedPlan.next(param);
  }

  public getFailedPlan(): FailedPlan {
    return this.FailedPlan;
  }

  public initWebViewSetBattery(): void {
    this.webviewService.InitSetBattery((battery) => {
      this.setBattery(battery);
      this.paramsUpdate();
    });
  }
}
