import { Component, Inject } from '@angular/core';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-voucher-dialog',
  templateUrl: './voucher-dialog.component.html',
  styleUrl: './voucher-dialog.component.scss'
})
export class VoucherDialogComponent {

  public message: string = "";

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService) {
      
    if (data.voucher.success == "VOUC_MSG_001") {
      this.message = "Your voucher will be activated after user login.";
    }
    else if (data.voucher.error == "VOUC_ERR_014") {
      this.message = "This voucher is expired, if you think it is a mistake please contact us.";
    }
    else if (data.voucher.error == "VOUC_ERR_013") {
      this.message = "This voucher is already used, if you think it is a mistake please contact us.";
    }
    else{
      this.message = "Voucher activation failed. Invalid voucher.";
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
