import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Lane } from 'src/app/models/route';
import { NavigationService } from 'src/app/services/navigation.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-navigation-roadlanes',
  templateUrl: './navigation-roadlanes.component.html',
  styleUrls: ['./navigation-roadlanes.component.scss']
})
export class NavigationRoadlanesComponent implements OnInit {
  @Input() Desktop: boolean;
  private subscriptions: Subscription[] = [];
  public roadLanes: Lane[] = [];
  public roadLanesVisible: boolean = false;
  constructor(private navigationService: NavigationService, private cdr: ChangeDetectorRef,
    public settingsService: SettingsService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.navigationService.ObservableShowRoadLane.subscribe((resp) => {
      if (resp != null) {
        this.roadLanesVisible = resp;
      }
    }
    ));
    this.subscriptions.push(this.navigationService.ObservableNextTurn.subscribe((resp) => {
      if (resp && resp.NextTurnInstruction && resp.NextTurnInstruction.LaneInfo.length > 0) {
        this.roadLanes = resp.NextTurnInstruction.LaneInfo;
        this.cdr.detectChanges();
      }
      else {
        this.roadLanes = [];
        this.cdr.detectChanges();
      }
    }
    ));
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
