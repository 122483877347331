<div class="subscription-dialog">

    <h1 mat-dialog-title>
        {{ languageService.languageJSON.SubscriptionsDialog_Packages }}
    </h1>
    <div mat-dialog-content>
        <div class="logo">
            <img src="assets/icons/dark/logo/ev_navigation_logo.png" />
            <img id="proplus-icon" src="assets/icons/default/ui/pro_proplus_logo.svg" />
        </div>
        <div class="content">
            <p [innerHTML]="featureInfo"></p>
            <p *ngIf="!accountService.getIsAuthorized()"
                [innerHtml]="languageService.languageJSON.SubscriptionsDialog_CreateUserActivatePROAndPROPackage"></p>
            <p *ngIf="accountService.getIsAuthorized()"
                [innerHtml]="languageService.languageJSON.SubscriptionsDialog_ActivatePROAndPROPackage"></p>

            <p *ngIf="!accountService.getIsAuthorized()"><a (click)="login()">{{
                    languageService.languageJSON.SubscriptionsDialog_IAlreadyHaveAnAccount }}</a></p>
        </div>

    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="register()" *ngIf="!accountService.getIsAuthorized()">{{
            languageService.languageJSON.AccountDialog_Register }}</button>
        <button mat-button (click)="activate()" *ngIf="accountService.getIsAuthorized()">{{
            languageService.languageJSON.SubscriptionsDialog_Activate }}</button>
        <button mat-button (click)="close()">{{ languageService.languageJSON.Global_Cancel }}</button>
    </div>

</div>