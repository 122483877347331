<div class="navi-menu">
    <div class="navi-btn" (click)="startNavigation()"
        [ngClass]="{ 'grayscale': failedPlan || noRoute || loadingMap }" name="go_btn">
        <div class="start-icon navi-btn-icon">
            <img src="assets/icons/dark/ui/start_icon.svg" alt="start navigation" />
        </div>
        <h4 class="navi-btn-text">{{ languageService.languageJSON.NavigationMenu_Go }}</h4>
    </div>

    <div class="navi-btn navi-stop-btn" (click)="backToRha()" name="stop_btn">
        <img src="assets/icons/dark/ui/cancel_icon.svg" />
    </div>
</div>