<div mat-dialog-content>
    <div id="page-set-battery">
        <div class="header">
            <h2>{{ languageService.languageJSON.SliderSidebar_BatteryLevel }}</h2>
            <h2>{{ batteryLevel }}%</h2>
        </div>

        <app-rha-slider #batterySlider [imageSource]="flatRangeImg" [min]="1"
              [max]="100" [digit]="0" [step]="90"
              [altString]="'battery level'" [(value)]="batteryLevel">
            </app-rha-slider>

        <p>{{ languageService.languageJSON.NavigationSetBatteryDialog_Description }}</p>
        <div class="footer">
            <button (click)="setBatteryClick()">
                {{ languageService.languageJSON.Global_Ok }}
            </button>
        </div>
    </div>
</div>