import { Injectable } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { AccountService } from './account.service';
import { LocationService } from './location.service';
import { NavigationService } from './navigation.service';
import { ConsoleLoggerService } from './console-logger.service';
import { skip } from 'rxjs/operators';
import { InputParamsService } from './input-params.service';
import { Position } from '../models/position';
import { LogElement } from '../models/log';

const SEND_TIME = 60000;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  /** Memory cache of stored logs */
  private logs: LogElement[] = [];

  /** Collecting cache of stored logs during uploading */
  private logsTemp: LogElement[] = [];

  /** Selected car  */
  private UserVehicleId: number;

  /** Flag indicating upload is in progress */
  private uploading: boolean = false;

  /** Logging location refrence */
  private LoggerSubscriptionRef: Subscription = null;

  private watchBatteryChangedEventRef: Subscription;
  private watchNavigationChangedEventRef: Subscription;
  private watchNavigationUserTriggeredReplanRef: Subscription;
  private watchReplanEventChangeRef: Subscription;
  private userTriggeredReplanValue: boolean = null;
  private successfulReplanHttpCallValue: boolean = null;
  private successfulReplanHttpCallCount: number = 0;
  private replanEventValue: boolean = null;
  private batteryChangedValue: number = null;
  private startNavigation: boolean = false;
  private endNavigation: boolean = false;

  /** 
   *  Sending smart connected car logs to api in every minutes
   *  Data contains the following fields: src, lat, lon, spd, alt, ac, hed, ts
   */
  constructor(public accountService: AccountService, private locationService: LocationService, private navigationService: NavigationService,
    private consoleLoggerService: ConsoleLoggerService, private inputParamsService: InputParamsService) {

    this.navigationService.ObservableChangeNavigation.subscribe((resp) => {
      if (resp == "start") {
        if (this.accountService.getIsAuthorized() && this.accountService.user.getSelectedUserCar().settings.tripRecording != 0) {
          this.consoleLoggerService.log("start logger");
          this.startNavigation = true;
          this.startLogger();
        }
      }
      if (resp == "exit" || resp == "arrive") {
        this.consoleLoggerService.log("end logger");
        this.endNavigation = true;

        const lastpos = this.locationService.getLastPosition();
        this.locationReceived(lastpos);

        this.stopLogger();
        this.uploadLogs();
      }
    });
  }

  /**
   * Set active vehicle
   * Start collecting logs
   */
  private startLogger(): void {
    this.userTriggeredReplanValue = null;
    this.replanEventValue = null;
    this.batteryChangedValue = null;
    this.logs = [];
    this.logsTemp = [];

    this.UserVehicleId = this.accountService.user.getSelectedUserCar().userVehicleID;

    this.LoggerSubscriptionRef = this.locationService.ObservableLastPosition.subscribe((position: Position) => {
      this.locationReceived(position);
    });

    this.watchNavigationChangedEventRef = this.navigationService.ObservableChangeNavigation.pipe(skip(1)).subscribe((resp) => {
      this.consoleLoggerService.log(resp);
      if (resp == "replan") {
        this.replanEventValue = true;
      }
    });

    this.watchNavigationUserTriggeredReplanRef = this.navigationService.ObservableUserTriggeredReplan.pipe(skip(1)).subscribe((resp) => {
      if (resp) {
        this.consoleLoggerService.log("skip cs/wp");
        this.userTriggeredReplanValue = true;
      }
    });

    this.watchReplanEventChangeRef = this.inputParamsService.ObservableRangeInputParams.subscribe((resp) => {
      if (resp && this.LoggerSubscriptionRef) {
        this.replanEventValue = true;
      }
    });
  }

  public onBatteryChangeEvent(batteryLevel: number): void {
    this.userTriggeredReplanValue = true;
    if (this.watchBatteryChangedEventRef) {
      this.watchBatteryChangedEventRef.unsubscribe();
    }
    this.watchBatteryChangedEventRef = timer(400).subscribe(() => {
      this.consoleLoggerService.log("battery", batteryLevel);
      this.batteryChangedValue = batteryLevel;
    });
  }

  public onWeightChangeEvent(): void {
    this.userTriggeredReplanValue = true;
  }

  public successfulReplanHttpCallEvent(): void {
    if (this.LoggerSubscriptionRef) {
      // sort out the first plan from replans
      if (this.successfulReplanHttpCallCount > 0) {
        this.successfulReplanHttpCallValue = true;
      }
      else {
        this.replanEventValue = null;
      }
      this.successfulReplanHttpCallCount++;
    }
  }

  private stopLogger(): void {
    if (this.LoggerSubscriptionRef) {
      this.consoleLoggerService.log("stop logger");
      this.LoggerSubscriptionRef.unsubscribe();
      this.LoggerSubscriptionRef = null;
      this.watchNavigationChangedEventRef.unsubscribe();
      this.watchNavigationChangedEventRef = null;
      this.watchNavigationUserTriggeredReplanRef.unsubscribe();
      this.watchNavigationUserTriggeredReplanRef = null;
      this.watchReplanEventChangeRef.unsubscribe();
      this.watchReplanEventChangeRef = null;
    }
  }

  /**
   * Filtering data, saving data
   * More than two minutes between first and last log, send to the server
   */
  private locationReceived(position: Position): void {
    if (position?.Latitude) {
      const location: LogElement = {
        src: "geo", lat: position.Latitude, lon: position.Longitude, spd: position.Speed, alt: position.Altitude,
        ac: position.Accuracy, aac: position.AltitudeAccuracy, hed: position.Heading, ts: position.Timestamp,
        soc: null
      };

      if (location.lat !== null && location.lat !== undefined) {
        location.lat = Math.floor(location.lat * 1000000) / 1000000.0;
      }

      if (location.lon !== null && location.lon !== undefined) {
        location.lon = Math.floor(location.lon * 1000000) / 1000000.0;
      }

      if (!this.uploading && this.logs.length > 0 &&
        this.logs[this.logs.length - 1].lat == location.lat &&
        this.logs[this.logs.length - 1].lon == location.lon && !this.endNavigation) {
        return;
      }

      if (this.uploading && this.logsTemp.length > 0 &&
        this.logsTemp[this.logsTemp.length - 1].lat == location.lat &&
        this.logsTemp[this.logsTemp.length - 1].lon == location.lon && !this.endNavigation) {
        return;
      }

      // add state of charge
      location.soc = this.navigationService.getPredictedBattery() / 100;
      if (location.soc === 0) {
        location.soc = this.inputParamsService.getBattery() / 100;
      }

      // filter out null|undefined values from loc
      for (let propName in location) {
        if (location[propName] === null || location[propName] === undefined) {
          delete location[propName];
        }
      }

      // rounding values
      const loc = this.locationFixup(location);

      // adding flags
      if (this.replanEventValue && this.successfulReplanHttpCallValue) {
        loc.replan_event = this.replanEventValue;
        this.replanEventValue = null;
        this.successfulReplanHttpCallValue = null;

        if (this.userTriggeredReplanValue) {
          loc.user_triggered_replan = this.userTriggeredReplanValue;
          this.userTriggeredReplanValue = null;
        }

        if (this.batteryChangedValue) {
          loc.battery_changed = this.batteryChangedValue;
          this.batteryChangedValue = null;
        }
      }
      if (this.startNavigation) {
        loc.navigation_start = this.startNavigation;
        this.startNavigation = null;
      }
      if (this.endNavigation) {
        loc.navigation_end = this.endNavigation;
        this.endNavigation = null;
      }

      // Log gps data
      this.pushLogEntry(loc);

      // upload every 1 minutes
      if (loc.ts - this.logs[0].ts > (SEND_TIME) && !this.uploading) {
        this.uploadLogs();
      }
    }
  }

  private uploadLogs(): void {
    if (this.logs.length > 2) {

      this.uploading = true;
      this.consoleLoggerService.log("UPLOADING LOGS", this.logs);

      this.uploadLogsHttpCall(this.UserVehicleId, this.logs).subscribe((resp) => {
        // upload succ
        this.uploading = false;
        this.logs = this.logsTemp;
        this.logsTemp = [];
      }, (error) => {
        this.uploading = false;
        this.logs = this.logs.concat(this.logsTemp);
        this.logsTemp = [];
      });
    }
    else {
      this.logs = [];
    }
  }

  private pushLogEntry(logEntry: LogElement): void {
    if (!this.uploading) {
      this.logs.push(logEntry);
    }
    else {
      this.logsTemp.push(logEntry);
    }
  }

  private locationFixup(loc: LogElement): LogElement {
    loc = { ...loc }; // clone the object, don't be destructive

    if (loc.ts !== null && loc.ts !== undefined) {
      loc.ts = Math.round(loc.ts);
    }

    if (loc.alt !== null && loc.alt !== undefined) {
      loc.alt = Math.round(loc.alt);
    }

    if (loc.spd !== null && loc.spd !== undefined) {
      loc.spd = Math.floor(loc.spd * 100) / 100.0;
    }

    if (loc.hed !== null && loc.hed !== undefined) {
      loc.hed = Math.round(loc.hed * 100) / 100.0;
    }

    if (loc.ac !== null && loc.ac !== undefined) {
      loc.ac = Math.round(loc.ac * 100) / 100.0;
    }

    if (loc.aac !== null && loc.aac !== undefined) {
      loc.aac = Math.round(loc.aac * 100) / 100.0;
    }

    if (loc.soc !== null && loc.soc !== undefined) {
      loc.soc = Math.round(loc.soc * 10000000) / 10000000.0;
    }

    return loc;
  }

  /** Tries to upload log file with the given header information. Returns an observable. */
  private uploadLogsHttpCall(userVehicleId: number, logs: LogElement[]): Observable<any> {
    return this.accountService.uploadLog(userVehicleId, logs);
  }
}
