import { LatLng } from "leaflet";
import { ECarSettings } from "./ecar";
import { WindTemp } from "./wind-temp";

export class RangeParams {
    public RangeInputParams: ECarSettings;
    public TempWindParams: WindTemp;
    public Waypoints: Waypoint[];
}

export class Waypoint{
    public lat: number;
    public lng: number;
    public csid?: string;

    constructor(lat: number, lng: number, csid?: string) {
        this.lat = lat;
        this.lng = lng;
        if (csid){
            this.csid = csid;
        }
    }
}