import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgScrollbar } from 'ngx-scrollbar';
import { SpeechService } from 'src/app/services/speech.service';
import { WebviewService } from 'src/app/services/webview.service';
import { SettingsService } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { TutorialComponent } from '../tabbed-info-dialog/tutorial/tutorial.component';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent implements OnInit {

  @ViewChild('privacyScrollbar', { static: true }) privacyScrollbar: NgScrollbar;
  public activeComponent: string;
  public dialogTitle: string;
  public step: number = 0;
  public button: string;
  public tutorialComponent: TutorialComponent;

  @ViewChild('tutorialElement') set content(content: TutorialComponent) {
    if (content) { // initially setter gets called with undefined
      this.tutorialComponent = content;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<WelcomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public webviewService: WebviewService,
    private SpeechRecognitionService: SpeechService,
    public settingsService: SettingsService,
    public languageService: LanguageService,
    private stackedModalsService: StackedModalsService) {
    this.activeComponent = this.data.activeComponent;
  }

  ngOnInit() {
    switch (this.activeComponent) {
      case "welcome":
        this.dialogTitle = this.languageService.languageJSON.TabbedInfoDialog_Welcome;
        this.button = "Start";
        break;
      case "language":
        this.dialogTitle = this.languageService.languageJSON.TabbedInfoDialog_Welcome;
        this.button = this.languageService.languageJSON.Global_Ok;
        break;
      case "tutorial":
        this.dialogTitle = this.languageService.languageJSON.TabbedInfoDialog_Tutorial;
        break;
    }
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  public onOpenTutorialClick(): void{
    this.stackedModalsService.openModalNext(WelcomeDialogComponent, {
      autoFocus: false,
      panelClass: ['dialog-snap-to-fullscreen'],
      data: { activeComponent: "tutorial" }
    });

    this.dialogRef.close();
  }

  public onNextClick(): void {
    if (this.step == 3) {
      this.activeComponent = '';
      this.dialogRef.close();
    }

    this.step++;
    this.tutorialComponent.onNextClick();
  }
}