import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import device from 'current-device';
import { SettingsService } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-gps-error-dialog',
  templateUrl: './gps-error-dialog.component.html',
  styleUrls: ['./gps-error-dialog.component.scss']
})
export class GpsErrorDialogComponent implements OnInit {

  private androidChromeText: string[] = [];
  private androidFirefoxText: string[] = [];
  private iphoneSafariText: string[] = [];
  private iphoneSafari12Text: string[] = [];
  private iphoneChromeText: string[] = [];
  private dekstopChromeText: string[] = [];
  private desktopFirefoxText: string[] = [];
  private desktopEdgeText: string[] = [];
  private desktopSafariText: string[] = [];
  private desktopMacChromeText: string[] = [];

  /**
   * showing error dialog, when the user not allowed gps location
   */
  constructor(
    public dialogRef: MatDialogRef<GpsErrorDialogComponent>,
    public settingsService: SettingsService,
    public languageService: LanguageService,
    private utilsService:UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public fbAndroid: boolean = navigator.userAgent.includes('FB_IAB');
  public fbIos: boolean = navigator.userAgent.includes('FBIOS');
  public informationText: string[];
  private browser: string;

  ngOnInit(): void {
    this.androidChromeText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command1,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command2,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command3,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.androidFirefoxText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command1,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command2,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command5,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command6,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.iphoneSafariText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command7,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command8,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command9,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command10,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command11,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command12,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command13,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.iphoneSafari12Text = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command7,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command8,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command9,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command10,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command7,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command14,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command15,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command16,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.iphoneChromeText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command7,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command8,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command9,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command17,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.dekstopChromeText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command1,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command18,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command19,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.desktopFirefoxText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command1,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command20,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command6,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.desktopEdgeText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command1,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command21,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.desktopSafariText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command22,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command23,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command24,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command26,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];
  
    this.desktopMacChromeText = [
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command1,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command25,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command26,
      this.languageService.languageJSON.GPSErrorDialog_Definition_Command4
    ];

    // browser detection
    this.detectBrowser();
    if (device.desktop()) {
      if ((device.os as any) == "macos") {
        if (this.browser == "Chrome") {
          this.informationText = this.desktopMacChromeText;
        }
        else {
          this.informationText = this.desktopSafariText;
        }
      }
      else {
        if (this.browser == "Firefox") {
          this.informationText = this.desktopFirefoxText;
        }
        else if (this.browser == "Edge") {
          this.informationText = this.desktopEdgeText;
        }
        else {
          this.informationText = this.dekstopChromeText;
        }
      }
    }
    else {
      if (device.android()) {
        if (this.browser == "Firefox") {
          this.informationText = this.androidFirefoxText;
        }
        else {
          this.informationText = this.androidChromeText;
        }
      }
      else if (device.ios()) {
        //chrome
        if (navigator.userAgent.match('CriOS')) {
          this.informationText = this.iphoneChromeText;
        }
        //safari
        else {
          if (navigator.userAgent.includes('iPhone OS 12') || navigator.userAgent.includes('iPhone OS 11') || navigator.userAgent.includes('iPhone OS 10') || navigator.userAgent.includes('iPhone OS 9') ||
            navigator.userAgent.includes('iPhone OS 8') || navigator.userAgent.includes('iPhone OS 7')) {
            this.informationText = this.iphoneSafari12Text;
          }
          else {
            this.informationText = this.iphoneSafariText;
          }

        }

      }
      else {
        this.informationText = this.androidChromeText;
      }
    }
  }

  public accept(): void {
    this.dialogRef.close();
  }

  // browser detection data
  private header: any = [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor];
  private dataos: { name: string, value: string, version: string }[] = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
  ];
  private databrowser: { name: string, value: string, version: string }[] = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Edge', value: 'Edge', version: 'Edge' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
  ];

  // regex compare navigator data
  private matchItem(string: string, data: { name: string, value: string, version: string }[]): { name: string, version: number } {
    let i = 0,
      j = 0,
      html = '',
      regex,
      regexv,
      match,
      matches,
      version;

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i');
      match = regex.test(string);
      if (match) {
        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
        matches = string.match(regexv);
        version = '';
        if (matches) { if (matches[1]) { matches = matches[1]; } }
        if (matches) {
          matches = matches.split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) {
              version += matches[j] + '.';
            } else {
              version += matches[j];
            }
          }
        } else {
          version = '0';
        }
        return {
          name: data[i].name,
          version: parseFloat(version)
        };
      }
    }
    return { name: 'unknown', version: 0 };
  }

  private detectBrowser(): void {
    const agent = this.header.join(' '),
      os = this.matchItem(agent, this.dataos),
      browser = this.matchItem(agent, this.databrowser);
    const edge = this.detectEdge();
    this.browser = edge ? "Edge" : browser.name;
  }

  // edge detection
  private detectEdge(): number {
    const ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return 0;
  }

  public openUrl(url): void {
    this.utilsService.openUrlOnNewTab(device, url);
  }
}
