<div class="history-element">
    <div class="header">
        <div class="title">
            <p>{{ history.startLocation }} - {{ history.targetLocation }}</p>
            <p>{{ history.startTime | date }}</p>
        </div>
        <button class="evnavi-button" (click)="goBack()">
            {{ languageService.languageJSON.Global_Back }}
        </button>
    </div>
    <div class="content">
        <div class="vehicle-row">
            <div class="vehicle-tile-property">
                <div class="vehicle-tile-property-name">
                    <img
                        [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_range.svg'" />
                </div>

                <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Distance!='metric'"
                    name="range_value">
                    {{ history.distance }} km
                </div>
                <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Distance!='imperial'"
                    name="range_value">
                    {{ (history.distance * 6.21371192)/10 | turnValueIntegerThousands }} mi
                </div>
            </div>
            <div class="vehicle-tile-property">
                <div class="vehicle-tile-property-name">
                    <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/trip_icon_total_time.svg'" />
                </div>
                <div class="vehicle-tile-property-value vehicle-tile-property-time" name="time_value">
                    <span *ngIf="history.totalTime.h">
                        {{ history.totalTime.h }}{{ languageService.languageJSON.Unit_Hour_Short }}
                        {{ history.totalTime.i }} {{ languageService.languageJSON.Unit_Minute_Short }}
                    </span>
                    <span *ngIf="!history.totalTime.h">
                        {{ history.totalTime.i }} {{ languageService.languageJSON.Unit_Minute_Short }}
                    </span>
                </div>
            </div>
        </div>

        <div class="vehicle-row">
            <div class="vehicle-tile-property">
                <div class="vehicle-tile-property-name">
                    <img
                        [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/vehicle_settings_icon_capacity.svg'" />
                </div>
                <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Distance!='metric'"
                    name="topspeed_value">
                    {{ history.avgConsumption }}
                    kWh/100km</div>
                <div class="vehicle-tile-property-value" [hidden]="settingsService.getUnit().Distance!='imperial'"
                    name="topspeed_value">
                    {{ (history.avgConsumption *16.09344)/10 | turnValueIntegerThousands }} kWh/100mi</div>
            </div>
            <div class="vehicle-tile-property">
                <div class="vehicle-tile-property-name">
                    <img
                        [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/trip_icon_total_consumption.svg'" />
                </div>
                <div class="vehicle-tile-property-value" name="totalpower_value">{{
                    history.totalConsumption }} kWh</div>
            </div>
        </div>
    </div>
</div>