<div class="settings-dialog" [ngClass]="{'dialog-loader': dialogContentIsLoading}">
  <h1 mat-dialog-title>{{ title[activeStepIndex] }}</h1>
  <div mat-dialog-content>
    <div class="dialog-loader-outer" [hidden]="!dialogContentIsLoading">
      <mat-spinner class="dialog-loading-spinner"></mat-spinner>
    </div>
    <ng-scrollbar #settingsScrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="scrollable-body"
      *ngIf="activeStepIndex==0">
      <div id="dialog-body">
        <div class="dialog-element">
          <h3>{{ languageService.languageJSON.SettingsDialog_RoutingSettings }}</h3>
          <div class="settingsbox">
            <div class="valuebox">
              <label for="setting-toll-roads">{{ languageService.languageJSON.SettingsDialog_TollRoads }}</label>
              <app-switch class="value-switch" [onImage]="'./assets/icons/default/settings/settings_switch_icon_on.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_off.svg'"
                [(value)]="settingValueTollRoad" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>
            <div class="break"></div>
            <div class="valuebox">
              <label for="setting-ferries">{{ languageService.languageJSON.SettingsDialog_Ferries }}</label>
              <app-switch class="value-switch" [onImage]="'./assets/icons/default/settings/settings_switch_icon_on.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_off.svg'"
                [(value)]="settingValueFerries" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>
            <div class="break"></div>
            <div class="valuebox">
              <label for="setting-highways">{{ languageService.languageJSON.SettingsDialog_Highways }}</label>
              <app-switch class="value-switch" [onImage]="'./assets/icons/default/settings/settings_switch_icon_on.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_off.svg'"
                [(value)]="settingValueHighways" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>
          </div>
        </div>
        <div class="dialog-element">
          <h3>{{ languageService.languageJSON.SettingsDialog_SystemSettings }}</h3>
          <div class="settingsbox">
            <div class="valuebox">
              <label>{{ languageService.languageJSON.SettingsDialog_Language }}</label>
              <mat-form-field>
                <mat-select disableRipple [(value)]="settingValueLanguage">
                  <mat-option *ngFor="let languageEl of Language" [value]="languageEl.code" (click)="languageChanged()">
                    {{languageEl.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="dialog-element">
          <h3 class="charging-stations-settings-title">{{
            languageService.languageJSON.SettingsDialog_ChargingStationsSettings }}</h3>
          <label class="charging-stations-label">{{ languageService.languageJSON.Global_ChargingStations }}</label>
          <div class="settingsbox charging-station-settings">
            <div class="valuebox">
              <div [ngClass]="{'inactive': favoriteChargingStations.length==0}"
                class="charging-stations-settings-element" (click)="openSelectedPage(2)">
                <img class="chargingstation-icon" src="./assets/icons/default/ui/chargingstation_favorite.svg" />
                <h5>{{favoriteChargingStations.length}} {{ languageService.languageJSON.Map_Popup_Favorite }}</h5>
                <img class="arrow-icon" src="./assets/icons/dark/ui/open_arrow.svg" />
              </div>
            </div>
            <div class="valuebox">
              <div [ngClass]="{'inactive': disabledChargingStations.length==0}"
                class="charging-stations-settings-element" (click)="openSelectedPage(1)">
                <img class="chargingstation-icon" src="./assets/icons/default/ui/chargingstation_disabled.svg" />
                <h5>{{disabledChargingStations.length}} {{ languageService.languageJSON.Map_Popup_Disabled }} </h5>
                <img class="arrow-icon" src="./assets/icons/dark/ui/open_arrow.svg" />
              </div>
            </div>
          </div>
          <label class="charging-stations-label">{{ languageService.languageJSON.SettingsDialog_ChargingOperators
            }}</label>
          <div class="settingsbox charging-station-settings">
            <div class="valuebox">
              <div class="charging-stations-settings-element" (click)="openSelectedPage(4)">
                <img class="chargingstation-icon" src="./assets/icons/default/ui/prefer_network_icon.svg" />
                <h5>{{favoriteChargingOperators.length}} {{ languageService.languageJSON.SettingsDialog_Prefer }}</h5>
                <img class="arrow-icon" src="./assets/icons/dark/ui/open_arrow.svg" />
              </div>
            </div>
            <div class="valuebox">
              <div class="charging-stations-settings-element" (click)="openSelectedPage(3)">
                <img class="chargingstation-icon" src="./assets/icons/default/ui/avoid_network_icon.svg" />
                <h5>{{disabledChargingOperators.length}} {{ languageService.languageJSON.SettingsDialog_Avoid }} </h5>
                <img class="arrow-icon" src="./assets/icons/dark/ui/open_arrow.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-element" *ngIf="accountService.getIsAuthorized()">
          <h3>{{ languageService.languageJSON.SettingsDialog_Home }}</h3>
          <div class="address-functions" *ngIf="homeAddressText">
            <img class="manage-icon-svg"
              [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/delete_icon.svg'"
              (click)="removeAddress('home')" />
            <img class="manage-icon-svg" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/edit_icon.svg'"
              (click)="editAddress('home')" />
          </div>
          <div class="settingsbox">
            <div class="valuebox">
              <div class="address-input" (click)="editAddress('home')"
                [innerText]="homeAddressText ? homeAddressText : languageService.languageJSON.SettingsDialog_AddHomeAddress">
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-element" *ngIf="accountService.getIsAuthorized()">
          <h3>{{ languageService.languageJSON.SettingsDialog_Work }}</h3>
          <div class="address-functions" *ngIf="workAddressText">
            <img class="manage-icon-svg"
              [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/delete_icon.svg'"
              (click)="removeAddress('work')" />
            <img class="manage-icon-svg" [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/edit_icon.svg'"
              (click)="editAddress('work')" />
          </div>
          <div class="settingsbox">
            <div class="valuebox">
              <div class="address-input" (click)="editAddress('work')"
                [innerText]="workAddressText ? workAddressText : languageService.languageJSON.SettingsDialog_AddWorkAddress">
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-element">
          <h3>{{ languageService.languageJSON.SettingsDialog_NavigationSettings }}</h3>
          <div class="settingsbox">
            <div class="valuebox">
              <label for="setting-voice-guidance">{{ languageService.languageJSON.SettingsDialog_VoiceGuidance
                }}</label>
              <app-switch class="value-switch" [onImage]="'./assets/icons/default/settings/settings_switch_icon_on.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_off.svg'"
                [(value)]="settingValueVoiceGuidance" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>
          </div>
        </div>
        <div class="dialog-element">
          <h3>{{ languageService.languageJSON.SettingsDialog_MapSettings }}</h3>
          <div class="settingsbox">
            <div class="valuebox">
              <label for="setting-map-colors">{{ languageService.languageJSON.SettingsDialog_Theme }}</label>
              <app-switch class="value-switch"
                [onImage]="'./assets/icons/default/settings/settings_switch_icon_skin_light.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_skin_dark.svg'"
                [(value)]="settingValueMapColors" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>

            <div class="valuebox">
              <label for="setting-map-pois">{{ languageService.languageJSON.SettingsDialog_POIs }}</label>
              <app-switch class="value-switch" [onImage]="'./assets/icons/default/settings/settings_switch_icon_on.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_off.svg'" [(value)]="settingValuePOIs"
                (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>

          </div>
        </div>
        <div class="dialog-element">
          <h3>{{ languageService.languageJSON.SettingsDialog_UnitsSettings }}</h3>
          <div class="settingsbox">
            <div class="valuebox">
              <label for="setting-unit-distance">{{ languageService.languageJSON.Global_Distance }}</label>
              <app-switch class="value-switch"
                [onImage]="'./assets/icons/default/settings/settings_switch_icon_distance_metric.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_distance_imperial.svg'"
                [(value)]="settingValueDistance" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>
            <div class="valuebox">
              <label for="setting-unit-temperature">{{ languageService.languageJSON.SettingsDialog_Temperature
                }}</label>
              <app-switch class="value-switch"
                [onImage]="'./assets/icons/default/settings/settings_switch_icon_temperature_metric.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_temperature_imperial.svg'"
                [(value)]="settingValueTemperature" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>
            <div class="break"></div>
            <div class="valuebox">
              <label for="setting-unit-speed">{{ languageService.languageJSON.SettingsDialog_Speed }}</label>
              <app-switch class="value-switch"
                [onImage]="'./assets/icons/default/settings/settings_switch_icon_speed_metric.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_speed_imperial.svg'"
                [(value)]="settingValueSpeed" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>
            <div class="valuebox">
              <label for="setting-unit-pressure">{{ languageService.languageJSON.SettingsDialog_Pressure }}</label>
              <app-switch class="value-switch"
                [onImage]="'./assets/icons/default/settings/settings_switch_icon_pressure_metric.svg'"
                [offImage]="'./assets/icons/default/settings/settings_switch_icon_pressure_imperial.svg'"
                [(value)]="settingValuePressure" (valueChangeEvent)="switchParamsChanged()"></app-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="error-outer" *ngIf="settingsError">
        <h5 class="error-text">{{ languageService.languageJSON.Global_SomethingWentWrong }}</h5>
      </div>
    </ng-scrollbar>

    <!-- disabled charging stations -->
    <div class="charging-stations-editor" *ngIf="activeStepIndex==1">
      <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="scrollable-body chargers-scrollbar">
        <div *ngFor="let charger of disabledChargingStations; let i = index">
          <div class="charger-element">
            <img class="charger-icon" [src]="getChargerIcon(charger.img)" (click)="openDisabledCharger(charger)" />
            <div class="charger-name" (click)="openDisabledCharger(charger)">
              <h4>{{ charger.title }}</h4>
              <h5 *ngIf="charger.opid!=-1">{{ mapService.getChargingOperatorByID(charger.opid).name }}</h5>
              <h5 *ngIf="charger.opid==-1">Unkown operator</h5>
            </div>
            <img class="remove-icon" (click)="removeDisabledCharger(charger)"
              [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/cancel_icon.svg'" />
          </div>
          <div *ngIf="i!=disabledChargingStations.length-1" class="charger-divider"></div>
        </div>
      </ng-scrollbar>
      <div class="disabled-button-outer">
        <button class="disable-btn" (click)="disableAllDisabledChargers()">{{
          languageService.languageJSON.Global_ReEnable_All }}</button>
      </div>
    </div>

    <!-- favorite charging stations -->
    <div class="charging-stations-editor" *ngIf="activeStepIndex==2">
      <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="scrollable-body chargers-scrollbar">
        <div *ngFor="let charger of favoriteChargingStations; let i = index">
          <div class="charger-element">
            <img class="charger-icon" [src]="getChargerIcon(charger.img)" (click)="openDisabledCharger(charger)" />
            <div class="charger-name" (click)="openFavoriteCharger(charger)">
              <h4>{{ charger.title }}</h4>
              <h5 *ngIf="charger.opid!=-1">{{ mapService.getChargingOperatorByID(charger.opid).name }}</h5>
              <h5 *ngIf="charger.opid==-1">Unkown operator</h5>
            </div>
            <img class="remove-icon" (click)="removeFavoriteCharger(charger)"
              [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/cancel_icon.svg'" />
          </div>
          <div *ngIf="i!=favoriteChargingStations.length-1" class="charger-divider"></div>
        </div>
      </ng-scrollbar>
      <div class="disabled-button-outer">
        <button class="disable-btn" (click)="disableAllFavoriteChargers()">{{
          languageService.languageJSON.Global_Unmark_All }}</button>
      </div>
    </div>

    <!-- disabled charging operators -->
    <div class="charging-stations-editor operator-editor" *ngIf="activeStepIndex==3">
      <div class="operator-outer">
        <app-operator-search [OperatorList]="nonUsedChargingOperators"
          (operatorSelectedEvent)="disabledOperatorSelectedHandler($event)"></app-operator-search>
      </div>
      <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="scrollable-body chargers-scrollbar">
        <div *ngFor="let operator of disabledChargingOperators; let i = index">
          <div class="charger-element">
            <img class="charger-icon"
              [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/network_list_icon.svg'" />
            <div class="charger-name">
              <h4>{{ operator.name }}</h4>
            </div>
            <img class="remove-icon" (click)="removeDisabledOperator(operator)"
              [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/cancel_icon.svg'" />
          </div>
          <div class="charger-divider"></div>
        </div>
      </ng-scrollbar>
    </div>

    <!-- favorite charging operators -->
    <div class="charging-stations-editor operator-editor" *ngIf="activeStepIndex==4">

      <div class="operator-outer">
        <app-operator-search [OperatorList]="nonUsedChargingOperators"
          (operatorSelectedEvent)="favoriteOperatorSelectedHandler($event)"></app-operator-search>
      </div>
      <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="scrollable-body chargers-scrollbar">
        <div *ngFor="let operator of favoriteChargingOperators; let i = index">
          <div class="charger-element">
            <img class="charger-icon"
              [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/network_list_icon.svg'" />
            <div class="charger-name">
              <h4>{{ operator.name }}</h4>
            </div>
            <img class="remove-icon" (click)="removeFavoriteOperator(operator)"
              [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/cancel_icon.svg'" />
          </div>
          <div class="charger-divider"></div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
  <div mat-dialog-actions>
    <button name="cancel_button" *ngIf="activeStepIndex==0" mat-button (click)="dialogClose()">{{
      languageService.languageJSON.Global_Close }}</button>
    <button name="cancel_button" *ngIf="activeStepIndex!=0" mat-button (click)="activeStepIndex=0">{{
      languageService.languageJSON.Global_Back }}</button>
  </div>
</div>