<div class="opened-image-backdrop" *ngIf="openedImage" (click)="openedImage=null">
    <img *ngIf="!openedImage.landscapeImage" [src]="'assets/screenshots/light/' + openedImage.imageUrl" />
    <img *ngIf="openedImage.landscapeImage" [src]="'assets/screenshots/dark/' + openedImage.imageUrl" />
</div>

<div class="subscription-dialog">

    <h1 mat-dialog-title>
        <span>{{
            languageService.languageJSON.SubscriptionsDialog_Subscription }}</span>
        <span class="closebutton" (click)="cancel()"><img
                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/deletetrack_icon.svg'"
                width="25px"></span>
    </h1>
    <div mat-dialog-content>
        <!-- LOADER -->
        <div class="dialog-loader-outer" *ngIf="loader">
            <mat-spinner class="dialog-loading-spinner"></mat-spinner>
        </div>

        <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" #subscriptionsScrollbar
            class="scrollable-body">
            <div class="container">
                <div class="active-package-section">
                    <h4>{{ languageService.languageJSON.SubscriptionsDialog_ActivePackage }}</h4>
                    <div class="flex-row">
                        <div class="flex-col">{{ languageService.languageJSON.SubscriptionsDialog_Package }}:</div>
                        <h4 class="flex-col">{{ getSubscriptionTypeName(userSubscription.SubscriptionType) }}
                            <div *ngIf="userSubscription.SubscriptionType!='free'" class="subscription-period-marker"
                                [ngClass]="{'cancelled' : !userSubscription.AutoRenew}">
                                {{ getSubscriptionPeriodName(userSubscription) }}</div>
                        </h4>
                    </div>
                    <div *ngIf="userSubscription.SubscriptionType != 'free' && !userSubscription.AutoRenew"
                        class="flex-row">
                        <div class="flex-col">{{ languageService.languageJSON.SubscriptionsDialog_ExpireDate }}:</div>
                        <h4 class="flex-col">{{ userSubscription.ExpireDate | date:'shortDate' }}</h4>
                    </div>
                    <div *ngIf="userSubscription.SubscriptionType != 'free' && userSubscription.AutoRenew"
                        class="flex-row">
                        <div class="flex-col">{{ languageService.languageJSON.SubscriptionsDialog_NextPaymentDate }}:</div>
                        <h4 class="flex-col">
                            <span *ngIf="userSubscription.NextPaymentDate">{{ userSubscription.NextPaymentDate |
                                date:'shortDate' }}</span>
                            <span *ngIf="!userSubscription.NextPaymentDate">-</span>
                        </h4>
                    </div>

                    <a *ngIf="userSubscription.SubscriptionType != 'free' && userSubscription.AutoRenew==true"
                        (click)="cancelSubscription()">{{ languageService.languageJSON.SubscriptionsDialog_CancelSubscription }}</a>
                </div>
                <div class="divider"></div>

                <div class="features-section">
                    <div class="header section-row">
                        <div class="section-col">
                            <h2>{{ languageService.languageJSON.SubscriptionsDialog_Feature }}</h2>
                        </div>
                        <div class="section-col">
                            <span class="section-text">Free</span>
                        </div>
                        <div class="section-col">
                            <span class="section-text">PRO</span>
                        </div>
                        <div class="section-col">
                            <span class="section-text">PRO<small>+</small></span>
                        </div>
                    </div>

                    <div class="features-list" *ngFor="let feature of subscriptionFeatures"
                        [ngClass]="{'dropdown-active': feature.dropdown}">
                        <div class="section-row feature-header">
                            <div class="section-col description-col">
                                <div class="section-text">{{ feature.shortDescription }}</div>
                                <div class="triangle-outer" (click)="feature.dropdown=!feature.dropdown">
                                    <div class="dropdown-triangle" [ngClass]="{'dropdown-active': feature.dropdown }">
                                    </div>
                                </div>

                            </div>
                            <div class="section-col" *ngFor="let package of packageList">
                                <img class="feature-icon" src="assets/icons/light/ui/feature_list_icon_available.svg"
                                    *ngIf="feature[package]">
                                <img class="feature-icon"
                                    src="assets/icons/light/ui/feature_list_icon_not_available.svg"
                                    *ngIf="!feature[package]">
                            </div>
                        </div>
                        <div class="section-row feature-description" [ngClass]="{'landscape-images': feature.landscapeImage}" *ngIf="feature.dropdown">
                            <div class="description-text">{{ feature.longDescription }}</div>
                            <div class="description-image" *ngIf="!feature.landscapeImage">
                                <img [src]="'assets/screenshots/light/' + feature.screenshot1" (click)="setOpenedImage(feature.screenshot1, feature.landscapeImage)" />
                                <img [src]="'assets/screenshots/light/' + feature.screenshot2" (click)="setOpenedImage(feature.screenshot2, feature.landscapeImage)" />
                            </div>
                            <div class="description-image" *ngIf="feature.landscapeImage">
                                <img [src]="'assets/screenshots/dark/' + feature.screenshot1" (click)="setOpenedImage(feature.screenshot1, feature.landscapeImage)" />
                                <img [src]="'assets/screenshots/dark/' + feature.screenshot2" (click)="setOpenedImage(feature.screenshot2, feature.landscapeImage)" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="plan-section">
                    <div class="plan-element"
                        [ngClass]="{'inactive': compareSubscriptions(userSubscription, 'evnavigation.'+plan.type+'.yearly') }"
                        *ngFor="let plan of subscriptionPlans">
                        <div class="plan-header">
                            <h3>PRO<img *ngIf="plan.type=='proplus'" class="proplus-icon"
                                    src="assets/icons/default/ui/PRO_star.svg" /></h3>
                        </div>
                        <div class="plan-content">
                            <div class="price">
                                <h1 *ngIf="plan.period=='monthly'">{{ plan.monthlyCostMonthlyPlan }}</h1>
                                <h1 *ngIf="plan.period=='yearly'">{{ plan.monthlyCostYearlyPlan }}</h1>
                                <h4>€</h4>
                            </div>
                            <h4 class="month-text">/{{ languageService.languageJSON.Global_Month }}</h4>
                            <div class="renewal-period">
                                <div class="period-button"
                                    [ngClass]="{'inactive': compareSubscriptions(userSubscription, 'evnavigation.'+plan.type+'.monthly'), 'active': plan.period=='monthly'}"
                                    (click)="plan.period='monthly'">
                                    {{ languageService.languageJSON.SubscriptionsDialog_Plan_Monthly }}</div>
                                <div class="period-button" [ngClass]="{'active': plan.period=='yearly'}"
                                    (click)="plan.period='yearly'">{{ languageService.languageJSON.SubscriptionsDialog_Plan_Yearly }}</div>
                            </div>
                            <h4>
                                <span *ngIf="plan.period=='monthly'">{{ plan.yearlyCostMonthlyPlan }}</span>
                                <span *ngIf="plan.period=='yearly'">{{ plan.yearlyCostYearlyPlan }}</span>
                                <span>€/{{ languageService.languageJSON.Global_Year }}</span>
                            </h4>
                            <h4 class="gray">{{ languageService.languageJSON.SubscriptionsDialog_7daysFreeTrial }}</h4>
                        </div>
                        <button (click)="subscribeToSubscription(plan)">
                            {{ languageService.languageJSON.SubscriptionsDialog_TryNow }}
                        </button>
                    </div>
                </div>

                <!--<div class="divider" *ngIf="userSubscription.SubscriptionType == 'free'"></div>

                <div class="add-voucher" *ngIf="userSubscription.SubscriptionType == 'free'">
                    <h4>Voucher</h4>
                    <p>Enter a valid voucher code:</p>
                    <div class="input-el">
                        <span>EV-</span><input [(ngModel)]="voucherInput">
                    </div>
                    <button (click)="activateVoucher()">Activate Voucher</button>
                </div>-->
            </div>
        </ng-scrollbar>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="cancel()">{{ languageService.languageJSON.Global_Close
            }}</button>
    </div>

</div>