<div id="mobile-main-menu" [ngClass]="{'searched': searched}">

    <h1 *ngIf="mobileVisiblePanel=='search'" mat-dialog-title>
        <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/back.svg'" class="back-button"
            (click)="mobileResolutionService.setMobileVisiblePanel('')" />
        <span class="panel-header">{{ languageService.languageJSON.InfoSidebar_SearchOnMap }}</span>
    </h1>

    <div class="search-outer" [hidden]="selectedMode=='route'">
        <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/back.svg'"
            class="back-button back-button-search" (click)="searched=false" *ngIf="searched" />
        <app-search *ngIf="selectedMode != 'route'" (searchChanged)="searchChangedHandler($event)"
            (searchActiveChanged)="searchActiveChangedHandler($event)" [inputValue]="rhaSearchValue" id="rha-search"
            [failedPlan]="false" [ngClass]="{'search-with-addresses': isAuthorized && !rhaSearchActive}"
            (click)="searched=false">
        </app-search>

        <div class="search-element-functions addresses" *ngIf="!rhaSearchActive"
            [hidden]="mobileVisiblePanel!=='search' && isMobile()">
            <div class="search-element-functions-icon" (click)="setSearchedToHome()">
                <img [src]="'assets/icons/dark/ui/home_icon.svg'" alt="home" />
            </div>
            <div class="search-element-functions-icon" (click)="setSearchedToWork()"><img
                    [src]="'assets/icons/dark/ui/work_icon.svg'" alt="work" />
            </div>
        </div>

        <div class="search-element-functions searched-functions" [hidden]="mobileVisiblePanel=='search'">
            <div class="search-element-functions-icon start-icon" (click)="setStartCoordsParamToSearchedResult()">
                <img src="assets/icons/dark/ui/start_icon.svg" alt="set to start point"
                    [matTooltip]="languageService.languageJSON.InfoSidebar_Tooltip_SetToStart" matTooltipShowDelay="500"
                    matTooltipClass="google-tooltip" />
            </div>
            <div class="search-element-functions-icon target-icon" (click)="setEndCoordsParamToSearchedResult()"><img
                    src="assets/icons/dark/ui/target_icon.svg" alt="set to destination"
                    [matTooltip]="languageService.languageJSON.InfoSidebar_Tooltip_SetToDest" matTooltipShowDelay="500"
                    matTooltipClass="google-tooltip" />
            </div>
        </div>
    </div>

    <div class="app-menu" [hidden]="mobileVisiblePanel=='search' || searched">
        <div class="profile-menu icon-element" (click)="dropdownClick('account', $event)"
            [ngClass]="{'active': dropdown == 'account'}">
            <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/user_icon.svg'" alt="user"
                *ngIf="!isAuthorized" />
            <div *ngIf="isAuthorized && accountService.getUserAvatar()==null" class="user-icon">
                {{ userMonogram }}
            </div>
            <img *ngIf="isAuthorized && accountService.getUserAvatar()!=null" class="user-icon-image"
                [src]="apiUrl + 'storage' + accountService.getUserAvatar()" />
            <img *ngIf="subscriptionType?.includes('pro.')" id="pro-badge" src="assets/icons/default/ui/PRO_icon.svg" />
            <img *ngIf="subscriptionType?.includes('proplus.')" id="proplus-badge"
                src="assets/icons/default/ui/PROPLUS_icon.svg" />
        </div>
    </div>
</div>

<div class="menu-dropdown" [ngClass]="{'dropdown-visible': dropdown == 'account' }">
    <ng-scrollbar [autoHeightDisabled]="false" [autoWidthDisabled]="true" class="scrollable-body">
        <div class="scroll-container scroll-active-width">
            <div class="dropdown-el" *ngIf="!isAuthorized" (click)="openAccountDialog(); dropdownClick('account', $event);">
                <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_Login }}</h5>
                <img class="dropdown-el-icon login-icon"
                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/menu_icon_login.svg'" />
            </div>
            <div class="dropdown-el" *ngIf="isAuthorized" (click)="openAccountDialog(); dropdownClick('account', $event);">
                <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_EditAccount }}</h5>
                <img class="dropdown-el-icon"
                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/edit_icon.svg'" />
            </div>
            <div class="dropdown-el" *ngIf="isAuthorized"
                (click)="openSubscriptionsDialog(); dropdownClick('account', $event);">
                <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_Subscriptions }}</h5>
                <img class="dropdown-el-icon subscription-icon"
                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/menu_icon_subscriptions.svg'" />
            </div>
            <div class="dropdown-el" *ngIf="isAuthorized" [ngClass]="{'inactive': loadingMap}"
                (click)="openHistoryDialog(); dropdownClick('account', $event);">
                <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_History }}</h5>
                <img class="dropdown-el-icon"
                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/menu_icon_history.svg'" />
            </div>
            <div class="dropdown-el" (click)="openSettings(); dropdownClick('account', $event);">
                <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_Settings }}</h5>
                <img class="dropdown-el-icon settings-icon"
                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/settings_icon.svg'" />
            </div>
            <div class="dropdown-el" (click)="openFeedbackDialog(); dropdownClick('account', $event);">
                <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_Info }}</h5>
                <img class="dropdown-el-icon info-icon"
                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/info_icon.svg'" />
            </div>
            <div class="dropdown-el" *ngIf="isAuthorized" (click)="logout(); dropdownClick('account', $event);">
                <h5 class="dropdown-el-text">{{ languageService.languageJSON.Global_Logout }}</h5>
                <img class="dropdown-el-icon logout-icon"
                    [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/logout_icon.svg'" />
            </div>
        </div>
    </ng-scrollbar>
</div>