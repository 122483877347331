import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueInteger'
})
export class ValueIntegerPipe implements PipeTransform {

  transform(val: number): string {
      if (val !== undefined && val !== null) {
          return val.toFixed(0);
      } else {
          return "";
      }
  }
}