import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChargeState } from 'src/app/models/charge-state';
import { InputParamsService } from 'src/app/services/input-params.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoggerService } from 'src/app/services/logger.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-navigation-set-dialog',
  templateUrl: './navigation-set-battery-dialog.component.html',
  styleUrl: './navigation-set-battery-dialog.component.scss'
})
export class NavigationSetBatteryDialogComponent {
  public batteryLevel: number = 73;
  public flatRangeImg: string = "./assets/icons/default/slider/slider_small_button_battery.svg";

  constructor(
    public dialogRef: MatDialogRef<NavigationSetBatteryDialogComponent>,
    public languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private inputParamsService: InputParamsService, private loggerService: LoggerService,
    private navigationService: NavigationService
  ) {
    this.batteryLevel = data.chargedBatteryLevel;
  }

  public setBatteryClick() {
    this.inputParamsService.deleteChargingWaypointWithoutReplan(this.data.chargingStationId);
    this.navigationService.setSlidersValueChangedIn15seconds(true);
    this.inputParamsService.setBattery(this.batteryLevel);
    this.loggerService.onBatteryChangeEvent(this.batteryLevel);
    this.inputParamsService.ObservableBatteryChanged.next(this.batteryLevel);
    this.inputParamsService.paramsUpdate();
    setTimeout(() => {
      this.navigationService.setSlidersValueChangedIn15seconds(false);
    }, 15000);
    this.dialogRef.close();
  }
}