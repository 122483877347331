import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AccountService, SubscriptionType } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { LanguageService } from 'src/app/services/language.service';
import { SettingsService } from 'src/app/services/settings.service';
import { SubscriptionDeleteDialogComponent } from './subscription-delete-dialog/subscription-delete-dialog.component';
import { WebviewService } from 'src/app/services/webview.service';
import { UserSubscription } from 'src/app/models/user-subscription';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

const MONTHLY_COST_MONTHLY_PLAN_PRO = 4.95;
const MONTHLY_COST_MONTHLY_PLAN_PROPLUS = 7.95;

/**
   * adding, managing user subscriptions
   */
@Component({
  selector: 'app-subscriptions-dialog',
  templateUrl: './subscriptions-dialog.component.html',
  styleUrls: ['./subscriptions-dialog.component.scss']
})
export class SubscriptionsDialogComponent implements OnInit {

  public userSubscription: UserSubscription;
  public voucherInput: string;
  public loader: boolean = false;
  public openedImage: { imageUrl: string, landscapeImage: boolean } = null;

  public subscriptionFeatures: {
    shortDescription: string, free: boolean, pro: boolean, proplus: boolean, longDescription: string, screenshot1: string, screenshot2: string,
    dropdown: boolean, landscapeImage?: boolean
  }[];

  public subscriptionPlans: {
    type: string, monthlyCostMonthlyPlan: number, monthlyCostYearlyPlan: number, monthlyPriceId: string,
    yearlyCostMonthlyPlan: number, yearlyCostYearlyPlan: number, yearlyPriceId: string, period: string
  }[] = []

  public packageList = ["free", "pro", "proplus"];
  private subscriptionOrder = [
    SubscriptionType.Free,
    SubscriptionType.ProMonthly,
    SubscriptionType.ProYearly,
    SubscriptionType.ProplusMonthly,
    SubscriptionType.ProplusYearly
  ];

  constructor(public dialogRef: MatDialogRef<SubscriptionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog, public languageService: LanguageService, private fb: FormBuilder,
    public settingsService: SettingsService, public accountService: AccountService, private consoleLoggerService: ConsoleLoggerService,
    private webviewService: WebviewService, private utilsService: UtilsService) {
  }

  ngOnInit(): void {
    this.subscriptionFeatures = [
      {
        shortDescription: this.languageService.languageJSON.SubscriptionsDialog_TurnByTurn_ShortDescription,
        free: true, pro: true, proplus: true,
        longDescription: this.languageService.languageJSON.SubscriptionsDialog_TurnByTurn_LongDescription,
        screenshot1: "screenshot_turn_by_turn.png", screenshot2: "screenshot_turn_by_turn_2.png", dropdown: false
      },
      {
        shortDescription: this.languageService.languageJSON.SubscriptionsDialog_ChargingDatabase_ShortDescription,
         free: true, pro: true, proplus: true,
        longDescription: this.languageService.languageJSON.SubscriptionsDialog_ChargingDatabase_LongDescription,
        screenshot1: "screenshot_charging_database.png", screenshot2: "screenshot_charging_database_2.png", dropdown: false
      },
      {
        shortDescription: this.languageService.languageJSON.SubscriptionsDialog_RoutePlanning500km_ShortDescription,
        free: false, pro: true, proplus: true,
        longDescription: this.languageService.languageJSON.SubscriptionsDialog_RoutePlanning500km_LongDescription,
        screenshot1: "screenshot_route_planning.png", screenshot2: "screenshot_route_planning_2.png", dropdown: false
      },

      {
        shortDescription: this.languageService.languageJSON.SubscriptionsDialog_VoiceInput_ShortDescription,
        free: false, pro: true, proplus: true,
        longDescription: this.languageService.languageJSON.SubscriptionsDialog_VoiceInput_LongDescription,
        screenshot1: "screenshot_voice.png", screenshot2: "screenshot_voice_2.png", dropdown: false
      },
      {
        shortDescription: this.languageService.languageJSON.SubscriptionsDialog_AndroidAutoCarPlay_ShortDescription,
        free: false, pro: true, proplus: true,
        longDescription: this.languageService.languageJSON.SubscriptionsDialog_AndroidAutoCarPlay_LongDescription,
        screenshot1: "screenshot_androidauto.png", screenshot2: "screenshot_carplay.png", dropdown: false, landscapeImage: true
      },
      {
        shortDescription: this.languageService.languageJSON.SubscriptionsDialog_WeatherForecast_ShortDescription,
        free: false, pro: true, proplus: true,
        longDescription: this.languageService.languageJSON.SubscriptionsDialog_WeatherForecast_LongDescription,
        screenshot1: "screenshot_weather.png", screenshot2: "screenshot_weather_2.png", dropdown: false
      },
      {
        shortDescription: this.languageService.languageJSON.SubscriptionsDialog_LiveChargingStatus_ShortDescription,
        free: false, pro: false, proplus: true,
        longDescription: this.languageService.languageJSON.SubscriptionsDialog_LiveChargingStatus_LongDescription,
        screenshot1: "screenshot_charging_status.png", screenshot2: "screenshot_charging_status_2.png", dropdown: false
      },
      {
        shortDescription: this.languageService.languageJSON.SubscriptionsDialog_LiveTraffic_ShortDescription,
        free: false, pro: false, proplus: true,
        longDescription: this.languageService.languageJSON.SubscriptionsDialog_LiveTraffic_LongDescription,
        screenshot1: "screenshot_traffic.png", screenshot2: "screenshot_traffic_2.png", dropdown: false
      }
    ];

    this.subscriptionPlans = [
      {
        type: "pro",
        monthlyCostMonthlyPlan: MONTHLY_COST_MONTHLY_PLAN_PRO,
        monthlyCostYearlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PRO * 10 / 12 * 100) / 100,
        monthlyPriceId: "price_1QjdurAlcj8y7UVxwfL5IGSB",
        yearlyCostMonthlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PRO * 12 * 100) / 100,
        yearlyCostYearlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PRO * 10 * 100) / 100,
        period: "yearly",
        yearlyPriceId: "price_1QjdvIAlcj8y7UVx4OS641Pq"
      },
      {
        type: "proplus",
        monthlyCostMonthlyPlan: MONTHLY_COST_MONTHLY_PLAN_PROPLUS,
        monthlyCostYearlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PROPLUS * 10 / 12 * 100) / 100,
        monthlyPriceId: "price_1QjdkuAlcj8y7UVxi0EUbF0U",
        yearlyCostMonthlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PROPLUS * 12 * 100) / 100,
        yearlyCostYearlyPlan: Math.round(MONTHLY_COST_MONTHLY_PLAN_PROPLUS * 10 * 100) / 100,
        yearlyPriceId: "price_1QjdwGAlcj8y7UVxT9KpJfvI",
        period: "yearly"
      }
    ];

    this.accountService.ObservableSubscriptionType.subscribe({
      next: (resp) => { if (resp) { 
        this.userSubscription = this.accountService.getUserSubscription();
        this.loader = false;
      } }
    });

    this.loader = true;
    this.accountService.loadUserProfile().subscribe(() => {
      this.userSubscription = this.accountService.getUserSubscription();
      this.loader = false;
    }, () => {
      this.loader = false;
    });
  }

  public getSubscriptionTypeName(subscriptionType: SubscriptionType): string {
    if (subscriptionType.includes("proplus")) {
      return "PROPLUS";
    }
    else if (subscriptionType.includes("pro")) {
      return "PRO";
    }
    else {
      return "FREE";
    }
  }

  public getSubscriptionPeriodName(userSubscription: UserSubscription): string {
    if (!userSubscription.AutoRenew) {
      return this.languageService.languageJSON.SubscriptionsDialog_Cancelled;
    }
    else if (userSubscription.SubscriptionType.includes("yearly")) {
      return this.languageService.languageJSON.SubscriptionsDialog_YearlyPlan;
    }
    else {
      return this.languageService.languageJSON.SubscriptionsDialog_MonthlyPlan;
    }
  }

  public subscribeToSubscription(plan) {
    const subscriptionType = `evnavigation.${plan.type}.${plan.period}` as SubscriptionType;
    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.StartSubscription(subscriptionType);
      this.loader = true;
    }
    else {
      const priceId = this.getPriceId(plan);
      const userId = this.accountService.user.Profile.Id;
      this.utilsService.openUrlOnSameTab(`${environment.API_URL}stripe/?user_id=${userId}&priceId=${priceId}`);
    }
  }

  private getPriceId(plan) {
    const planType = this.subscriptionPlans.find((el) => { return el.type == plan.type });

    if (plan.period == "monthly") {
      return planType.monthlyPriceId;
    }
    else {
      return planType.yearlyPriceId;
    }
  }

  public compareSubscriptions(sub1: UserSubscription, sub2: string): number {
    if (!sub1.AutoRenew) {
      return 0;
    }

    const index1 = this.subscriptionOrder.indexOf(sub1.SubscriptionType);
    const index2 = this.subscriptionOrder.indexOf(sub2 as SubscriptionType);

    return (index1 >= index2) ? 1 : 0;
  }

  public activateVoucher(): void {
    const voucherToken = "EV-" + this.voucherInput;

    this.accountService.addVoucherSubscription(voucherToken).subscribe((resp) => {
      if (resp) {
        this.dialogRef.close();
      }
    });
  }

  public setOpenedImage(imageUrl: string, landscapeImage: boolean) {
    this.openedImage = { imageUrl: imageUrl, landscapeImage: landscapeImage };
  }

  public cancelSubscription() {
    if (this.webviewService.IsGPSTWebview()) {
      this.webviewService.OpenManageSubscription();
    }
    else {
      const dialog = this.matDialog.open(SubscriptionDeleteDialogComponent,
        {
          data: { subscriptionType: this.userSubscription },
          autoFocus: false
        });

      dialog.afterClosed().subscribe((resp) => {
        if (resp.delete) {
          this.loader = true;
          this.accountService.cancelUserSubscription().subscribe(() => {
            this.accountService.fetchWithRetryForSubscription(2, 10000, this.userSubscription.SubscriptionType, "false").subscribe((resp) => {
              this.accountService.setUserData(resp);
              this.loader = false;
            }, () => {
              this.loader = false;
            });
          }, () => { this.loader = false; });
        }
      });
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public finish(): void {
    this.dialogRef.close();
  }
}
