import { Component, Input, OnInit } from '@angular/core';
import { LatLng } from 'leaflet';
import { AccountService } from 'src/app/services/account.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import { LanguageService } from 'src/app/services/language.service';
import { MapService, ShowOnMapEnum } from 'src/app/services/map.service';
import { AddressType, SettingsService } from 'src/app/services/settings.service';
import { MapElementType } from '../../map/map.component';
import { SearchedRouteElement } from '../../../models/searched-route-element';
import { Address } from 'src/app/models/address';

@Component({
  selector: 'app-address-panel',
  templateUrl: './address-panel.component.html',
  styleUrls: ['./address-panel.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() addressType: string;
  public inputValue: string;
  public selectedAddress: Address;

  constructor(public accountService: AccountService,
    public settingsService: SettingsService,
    private consoleLoggerService: ConsoleLoggerService,
    private mapService: MapService,
    public languageService: LanguageService,
    private inputParamsService: InputParamsService) { }

  ngOnInit(): void {

    if (this.addressType === "home") {
      let homeAddress = this.settingsService.getHomeAddress();
      if (homeAddress?.Description) {
        this.inputValue = homeAddress.Description;
        this.mapService.setSearchedMapElement(MapElementType.Address,
          new Address(
            AddressType.Home,
            homeAddress.Description,
            new LatLng(homeAddress.Location.lat, homeAddress.Location.lng),
            homeAddress.BoundingBox
          ));
      }
    }
    if (this.addressType === "work") {
      let workAddress = this.settingsService.getWorkAddress();
      if (workAddress?.Description) {
        this.inputValue = workAddress.Description;
        this.mapService.setSearchedMapElement(MapElementType.Address,
          new Address(
            AddressType.Work,
            workAddress.Description,
            new LatLng(workAddress.Location.lat, workAddress.Location.lng),
            workAddress.BoundingBox
          ));
      }
    }
  }

  public searchChangedHandler(event: SearchedRouteElement): void {
    if (this.addressType === "home") {
      this.selectedAddress = new Address(
        AddressType.Home,
        event.display_name,
        new LatLng(event.lat, event.lon),
        event.boundingbox
      );
    }
    if (this.addressType === "work") {
      this.selectedAddress = new Address(
        AddressType.Work,
        event.display_name,
        new LatLng(event.lat, event.lon),
        event.boundingbox
      );
    }
    this.mapService.setSearchedMapElement(MapElementType.Address, this.selectedAddress);
  }

  public save(): void {
    if (this.selectedAddress) {
      if (this.addressType === "home") {
        this.settingsService.setHomeAddress(this.selectedAddress);
      }
      if (this.addressType === "work") {
        this.settingsService.setWorkAddress(this.selectedAddress);
      }
      this.accountService.setUserAddresses().subscribe();
    }
    this.mapService.ObservableShowOnMap.next({ type: ShowOnMapEnum.BackSettings });
  }

  public goBack(): void {
    this.mapService.ObservableShowOnMap.next({ type: ShowOnMapEnum.BackSettings });
  }
}
