<div class="feedback-content">
    <div [hidden]="!success" class="ev-logo">
        <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/logo/ev_navigation_logo.png'"  />
    </div>

    <div [hidden]="success" class="feedback-el">
        <p>Email</p>
        <input type="email" name="email" class="mail-input" [(ngModel)]="email" email>
    </div>
    <div class="error-text" [ngClass]="{ show: emailError }" style="padding-top: 0px;">{{ languageService.languageJSON.Feedback_Email_Error }}</div>

    <div [hidden]="success" class="feedback-el">
        <p>{{ languageService.languageJSON.Feedback_Rating }}</p>
        <div class="rating-icons">
            <div mat-icon-button *ngFor="let ratingId of ratingArr;index as i" (click)="rating = i+1">
                <img class="star-btn" src="assets/icons/default/ui/star.png"
                    [ngClass]="{ 'star-btn-selected': showIcon(i)==true }" alt="rating"/>
            </div>
        </div>
    </div>
    <div [hidden]="success" class="error-text" [ngClass]="{ show: msgError }" style="padding-top: 0px;">{{ languageService.languageJSON.Feedback_Rating_Missing }} </div>

    <p [hidden]="success" class="review-desc">{{ languageService.languageJSON.Feedback_Message_Title }}</p>
    <textarea [hidden]="success" [(ngModel)]='reviewText' maxlength="65536"></textarea>
    <div [hidden]="success" class="error-text" [ngClass]="{ show: msgError }">{{ languageService.languageJSON.Feedback_Rating_Missing }}</div>
    <p [hidden]="success" class="checkbox-el">
        <mat-checkbox [(ngModel)]="allowSensitiveData">
            {{ languageService.languageJSON.Feedback_AllowSensitiveData }}
        </mat-checkbox>
    </p>
    <p [hidden]="success" class="privacy-el" (click)="openPrivacyPolicy()">{{ languageService.languageJSON.Feedback_OpenPrivacyPolicy }}</p>
    <p [hidden]="success" class="privacy-el" (click)="openEula()" *ngIf="webView">{{ languageService.languageJSON.Feedback_OpenEula }}</p>
    <div [hidden]="success" class="recaptcha-outer" *ngIf="!success && !failed && !sending">
        <re-captcha #captchaRef (resolved)="resolved($event)"></re-captcha>
    </div>

    <h3 [hidden]="!sending" class="success-text">{{ languageService.languageJSON.Feedback_Email_Sending }}...</h3>
    <h3 [hidden]="!failed" class="success-text">{{ languageService.languageJSON.Feedback_Email_Failed }}</h3>
    <h3 [hidden]="!success" class="success-text"><div class="check-icon"><img alt="checked" src="assets/icons/default/ui/check.svg"/></div>{{ languageService.languageJSON.Feedback_Email_Success }}</h3>

    <div [hidden]="success" class="error-text" [ngClass]="{ show: recaptchaError }">{{ languageService.languageJSON.Feedback_Recaptcha }}</div>
    <button [hidden]="success" mat-button class="send-button" (click)="accept()">
      <div>{{ languageService.languageJSON.Global_Send }}</div>
    </button>
    <button [hidden]="!success" mat-button class="send-button" (click)="resetform()">
      <div>{{ languageService.languageJSON.Feedback_NewFeedback }}</div>
    </button>
</div>
