import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import device from 'current-device';
import { SpeechService } from 'src/app/services/speech.service';
import { SettingsService } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {

  @ViewChild('privacyScrollbar', { static: true }) privacyScrollbar: NgScrollbar;
  @Input() welcomeDialog: boolean;
  public step: number = 0;
  public nextbutton: boolean = true;
  public prevbutton: boolean = false;
  public ios: boolean = false;
  public contentArr:{img,title,text,commands?,mp4?}[] = [];

  constructor( private SpeechRecognitionService : SpeechService , public settingsService:SettingsService,
    public languageService: LanguageService) {
    
    this.contentArr = [{
      img: "assets/screenshots/" + this.settingsService.getMap().Skin + "/range_image.jpg?v=3",
      title: this.languageService.languageJSON.Global_Range,
      text: this.languageService.languageJSON.Tutorial_Step_1_Text
    },
    {
      img: "assets/screenshots/" + this.settingsService.getMap().Skin + "/search_click_image.jpg?v=3",
      title: this.languageService.languageJSON.Tutorial_Step_2_Desktop_Title,
      text: this.languageService.languageJSON.Tutorial_Step_2_Desktop_Text
    },
    {
      img: null,
      title: this.languageService.languageJSON.Tutorial_Step_3_Title,
      text: this.languageService.languageJSON.Tutorial_Step_3_Text,
      mp4: "assets/screenshots/" + this.settingsService.getMap().Skin + "/planning.mp4?v=3"
    }];
    
    if (device.ios()){
      this.ios = true;
    }
    if (!device.desktop()) {
      this.contentArr[1] = {
        img: "assets/screenshots/" +  this.settingsService.getMap().Skin + "/search_tap_image.jpg?v=3",
        title: this.languageService.languageJSON.Tutorial_Step_2_Mobile_Title,
        text: this.languageService.languageJSON.Tutorial_Step_2_Mobile_Text
      }
    }

    if( SpeechRecognitionService.VoiceRecognitionAvailable() ){
      this.contentArr.push(
        {
          img: "assets/screenshots/" +  this.settingsService.getMap().Skin + "/voice_instruction_image.jpg?v=3",
          title: this.languageService.onlyEnglish.VoiceInputInfoDialog_Title,
          text: this.languageService.onlyEnglish.VoiceInputInfoDialog_Description,
          commands: [
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_SkipWaypoint,
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_SkipChargingStation,
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_StopNavigation,
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_NumberOfPersons,
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_NavigateHome,
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_NavigateWork,
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_RemainingDistance,
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_ETE,
            this.languageService.onlyEnglish.Tutorial_Step_4_Command_BatteryLevel
          ]
        }
      );
    }
  }

  ngOnInit() {
  }

  public onNextClick(): void {
    this.step++;
  }
  
  public onPrevClick(): void {
    this.step--;
  }
}
