import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  public title: string = null;
  public message: string = null;
  public warning: string = null;
  public warning2: string = null;
  public icon: string = null;
  public buttons: string[] = [];

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService) {
    
    this.buttons = [this.languageService.languageJSON.Global_Yes, this.languageService.languageJSON.Global_No];
    
    if (data.title) {
      this.title = data.title;
    }
    if (data.message) {
      this.message = data.message;
    }
    if (this.data.warning) {
      this.warning = this.data.warning;
    }
    if (this.data.warning2){
      this.warning2 = this.data.warning2;
    }
    if (this.data.buttons) {
      this.buttons = this.data.buttons;
    }
    if (this.data.icon) {
      this.icon = this.data.icon;
    }
  }

  ngOnInit() {
  }

  public accept(): void {
    this.dialogRef.close(true);
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
