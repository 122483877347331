<div style="padding-top:0">
  <div class="welcome-outer">
    <div class="welcome-logo">
      <img [src]="'/assets/icons/' + settingsService.getMap().Skin + '/logo/ev_navigation_logo.png'" />
      <div class="gpst-logo-section">
        <h3>Powered by</h3>
        <a href="https://gpstuner.com/"><img src="/assets/icons/dark/logo/gpst_logo.svg" /></a>
      </div>
    </div>
    <img class="tutorial-img" [src]="">
    <h3>{{ languageService.languageJSON.Tutorial_Step_0_Text }}</h3>
    <div class="welcome-buttons">
      <div class="stores">
        <a href="https://play.google.com/store/apps/details?id=com.gpstuner.evnavigation" target="_blank"><img
            *ngIf="!ios"
            [src]="'assets/icons/default/social/'+ settingsService.getLanguage() +'/google_play_button.svg'"
            id="download-android-icon" /></a>
        <a href="https://apps.apple.com/app/ev-navigation/id1494131819" target="_blank">
          <img [src]="'assets/icons/default/social/'+ settingsService.getLanguage() +'/app_store_button.svg'"
            id="download-ios-icon" /></a>
      </div>
      <div class="social">
        <a href="https://twitter.com/evnavigation" target="_blank">
          <div class="twitter">
            <div class="twitter-logo"></div> {{ languageService.languageJSON.Welcome_Twitter }}
          </div>
        </a>
        <a href="https://www.facebook.com/EVNavigation" target="_blank">
          <div class="facebook"><img class="fb-logo" src="assets/icons/default/social/facebook_icon.svg" /> {{
            languageService.languageJSON.Welcome_Facebook }}</div>
        </a>
      </div>
    </div>
  </div>
  <div class="changelog-outer" *ngIf="!hideChangelog">
    <h2>What's New</h2>

    <div class="changelog-element no-border">
      <div class="flex-el">
        <div class="changelog-type-outer" *ngIf="changeLogData[0].features.length>0">
          <img class="changelog-type-icon"
            [src]="'assets/icons/'+ settingsService.getMap().Skin +'/ui/feature_icon.svg'" />
          <div class="logs">
            <h4>Features</h4>
            <ul>
              <li *ngFor="let log of changeLogData[0].features">{{ log }}</li>
            </ul>
          </div>
        </div>
        <div class="changelog-type-outer" *ngIf="changeLogData[0].improvements.length>0">
          <img class="changelog-type-icon"
            [src]="'assets/icons/'+ settingsService.getMap().Skin +'/ui/improvement_icon.svg'" />
          <div class="logs">
            <h4>Improvements</h4>
            <ul>
              <li *ngFor="let log of changeLogData[0].improvements">{{ log }}</li>
            </ul>
          </div>
        </div>
        <div class="changelog-type-outer" *ngIf="changeLogData[0].bugfixes.length>0">
          <img class="changelog-type-icon"
            [src]="'assets/icons/'+ settingsService.getMap().Skin +'/ui/bugfix_icon.svg'" />
          <div class="logs">
            <h4>Bugfixes</h4>
            <ul>
              <li *ngFor="let log of changeLogData[0].bugfixes">{{ log }}</li>
            </ul>
          </div>
        </div>

      </div>
      <div class="flex-el">
        <div class="version" *ngIf="changeLogData[0].version">
          v{{ changeLogData[0].version }}
        </div>
      </div>
    </div>

    <div class="changelog-opener-outer">
      <div class="changelog-opener" (click)="changelogDetailed=!changelogDetailed"
        [ngClass]="{'opened': changelogDetailed}">
        <img class="clock-icon" [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/computer_icon_clock.svg'" />
        <h4>Version History</h4>
        <img class="opener-icon" [src]="'assets/icons/' + settingsService.getMap().Skin + '/ui/open_arrow.svg'" />
      </div>
    </div>

    <div class="changelog-element" *ngFor="let changeLog of changeLogData; let i = index"
      [hidden]="i==0 || !changelogDetailed">
      <div class="flex-el">
        <div class="changelog-type-outer" *ngIf="changeLog.title">
          <div class="logs">
            <h4>{{ changeLog.title }}</h4>
          </div>
        </div>
        <div class="changelog-type-outer" *ngIf="changeLog.features.length>0">
          <img class="changelog-type-icon"
            [src]="'assets/icons/'+ settingsService.getMap().Skin +'/ui/feature_icon.svg'" />
          <div class="logs">
            <h4>Features</h4>
            <ul>
              <li *ngFor="let log of changeLog.features">{{ log }}</li>
            </ul>
          </div>
        </div>
        <div class="changelog-type-outer" *ngIf="changeLog.improvements.length>0">
          <img class="changelog-type-icon"
            [src]="'assets/icons/'+ settingsService.getMap().Skin +'/ui/improvement_icon.svg'" />
          <div class="logs">
            <h4>Improvements</h4>
            <ul>
              <li *ngFor="let log of changeLog.improvements">{{ log }}</li>
            </ul>
          </div>
        </div>
        <div class="changelog-type-outer" *ngIf="changeLog.bugfixes.length>0">
          <img class="changelog-type-icon"
            [src]="'assets/icons/'+ settingsService.getMap().Skin +'/ui/bugfix_icon.svg'" />
          <div class="logs">
            <h4>Bugfixes</h4>
            <ul>
              <li *ngFor="let log of changeLog.bugfixes">{{ log }}</li>
            </ul>
          </div>
        </div>

      </div>
      <div class="flex-el">
        <div class="version" *ngIf="changeLog.version">
          v{{ changeLog.version }}
        </div>
      </div>
    </div>
  </div>
</div>