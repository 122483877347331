import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MapService } from 'src/app/services/map.service';
import { AccountService } from 'src/app/services/account.service';
import { UtilsService } from 'src/app/services/utils.service';
import { FormGroup, FormControl } from '@angular/forms';
import { InputParamsService } from 'src/app/services/input-params.service';
import { SettingsService } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { StackedModalsService } from 'src/app/services/stacked-modals.service';
import { ECarType } from '../../../models/ecar-type';
import { ECar } from 'src/app/models/ecar';

@Component({
  selector: 'app-car-selector-dialog',
  templateUrl: './car-selector-dialog.component.html',
  styleUrls: ['./car-selector-dialog.component.scss']
})
export class CarSelectorDialogComponent implements OnInit {

  @ViewChild('carSelectorScrollbar', { static: true }) carSelectorScrollbar: NgScrollbar;
  // cartypes, chargertypes, variables
  public CarTypes: any[];
  public carType: string = "tesla";
  public carTypeIndex: number = 0;
  public carSubType: string = "Model 3";
  public chargerTypes: { name: string, icon: string }[] = [
    { name: "CCS", icon: "plug_ccs.svg" }, { name: "CHADEMO", icon: "plug_chademo.svg" },
    { name: "SCHUKO", icon: "plug_schuko.svg" }, { name: "TESLA", icon: "plug_supercharger.svg" },
    { name: "TYPE 1", icon: "plug_type1.svg" }, { name: "TYPE 2", icon: "plug_type2.svg" }
  ];
  public batteryImg: string = "./assets/icons/default/slider/slider_small_button_battery.svg";
  public fastchargeImg: string = "./assets/icons/default/slider/slider_small_button_fastchargelimit.svg";
  public batteryStateOfHealthMin: number = 50;
  public batteryStateOfHealthMax: number = 110;
  public batteryStateOfHealth: number = 100;
  public fastChargeLimit: number = 90;
  public fastChargeLimitMin: number = 75;
  public fastChargeLimitMax: number = 90;
  public selectedChargerTypes = [false, false, false, false, false, false];
  public csError: boolean = false;
  public carSelectorError: boolean = false;
  public ECars: ECarType[] = [];
  public selectedCar: ECarType;
  public multipleCarType: boolean = false;
  public dialogContentIsLoading: boolean = true;
  public customCarNameForm: FormGroup;
  public editedUserCar: ECar = null;
  public isAuthorized: boolean = false;
  public tripRecordingSettings: number = 1;

  constructor(public dialogRef: MatDialogRef<CarSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private mapService: MapService, public accountService: AccountService,
    private matDialog: MatDialog, private utilsService: UtilsService, private cdr: ChangeDetectorRef,
    private inputParamsService: InputParamsService, public settingsService: SettingsService,
    public languageService: LanguageService, private consoleLoggerService: ConsoleLoggerService,
    private _ngZone: NgZone, private stackedModalsService: StackedModalsService) {
  }

  ngOnInit(): void {
    if (this.data && this.data.editedUserCarId) {
      this.editedUserCar = { ... this.accountService.getUserProfile().getCarById(this.data.editedUserCarId) };
      this.tripRecordingSettings = this.editedUserCar.settings.tripRecording != undefined ? this.editedUserCar.settings.tripRecording : 1;
    }

    this.selectedCar = new ECarType(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    this.isAuthorized = this.accountService.getIsAuthorized();

    this.customCarNameForm = new FormGroup({
      customCarName: new FormControl()
    });

    if (!this.isAuthorized) {
      if (this.inputParamsService.getBatteryStateOfHealth()) {
        this.batteryStateOfHealth = this.inputParamsService.getBatteryStateOfHealth();
      }
      if (this.inputParamsService.getFastChargeLimit()) {
        this.fastChargeLimit = this.inputParamsService.getFastChargeLimit();
      }
    }

    // load cars, set default car, load unit, chargertypes
    this.mapService.ObservableECarsLoaded.subscribe((resp) => {
      if (resp != null) {
        this.ECars = [...resp.ECars];
        if (this.ECars != undefined && this.ECars != null && this.ECars.length != undefined && this.ECars.length > 0) {
          this.CarTypes = resp.CarTypes;

          this.selectDefaultCar();

          if (this.editedUserCar != null) {
            this.customCarNameForm.patchValue({ 'customCarName': this.editedUserCar.name });
            this.selectedCar = this.editedUserCar.ECarType;
            if (this.editedUserCar.settings.batteryStateOfHealth) {
              this.batteryStateOfHealth = this.editedUserCar.settings.batteryStateOfHealth;
            }
            if (this.editedUserCar.settings.fastChargeLimit) {
              this.fastChargeLimit = this.editedUserCar.settings.fastChargeLimit;
            }
            this.carType = this.selectedCar.Type;
          }
          else if (!this.accountService.getIsAuthorized() && this.mapService.getSelectedCar()) {
            this.selectedCar = this.mapService.getSelectedCar();
            this.carType = this.selectedCar.Type;
          }
          if (this.selectedCar.Subtype) {
            this.carSubType = this.selectedCar.Subtype;
          }
          this.carTypeIndex = this.getCarTypeIndex(this.carType);

          if (!this.accountService.getIsAuthorized()) {
            this.selectedChargerTypes = [...this.mapService.getChargingStations()];
          }
          else {
            this.setChargerTypes(this.selectedCar.ChargerTypes);
          }

          this.multipleTypeSelector();

          this.dialogContentIsLoading = false;


          this.cdr.detectChanges();
        }
      }
    });
  }

  private selectDefaultCar(): void {
    let defCarId: number = this.utilsService.defaultCarIndex;
    for (let i = 0; i < this.ECars.length; ++i) {
      if (this.ECars[i].Id == defCarId) {
        this.selectedCar = this.ECars[i];
        break;
      }
    }
  }

  public openGreenCarsCompareLink() {
    const url = `https://greencarscompare.com/global/cars/${this.selectedCar.GreenCarsLink}/?utm_medium=referral&utm_source=evnavigation.com`;
    window.open(url, '_blank');
  }

  public clickOk(): void {
    if (!this.csError) {

      let selectedChargersArr = this.selectChargingStationArray();

      if (this.accountService.getIsAuthorized()) {
        let cname: string = this.customCarNameForm.get('customCarName').value || "";

        this.carSelectorError = false;
        const settings = this.inputParamsService.getECarSettings();
        if (this.editedUserCar != null) {
          const userCar = this.accountService.getUserProfile().getCarById(this.data.editedUserCarId);
          if ((userCar.name && userCar.name != cname) || (userCar.name == "" && userCar.ECarType.Name != cname) ||
            userCar.ECarType.ChargerTypes.toString() != selectedChargersArr.toString() ||
            userCar.settings.batteryStateOfHealth != this.batteryStateOfHealth ||
            userCar.settings.fastChargeLimit != this.fastChargeLimit ||
            userCar.settings.tripRecording != this.tripRecordingSettings) {
            settings.batteryStateOfHealth = this.batteryStateOfHealth;
            settings.fastChargeLimit = this.fastChargeLimit;
            settings.tripRecording = this.tripRecordingSettings;
            this.dialogContentIsLoading = true;
            this.accountService.editVehicle(this.editedUserCar.userVehicleID, this.selectedCar.Id, cname, selectedChargersArr, JSON.stringify(settings), true).subscribe(() => {
              this.dialogRef.close();
            },
              (error) => {
                this.dialogContentIsLoading = false;
                if (error && error.name === "TimeoutError") {
                  this.carSelectorError = true;
                }
                else if (error && error.status && error.status == 401) {
                  this.dialogRef.close();
                }
              });
          }
          else {
            this.dialogRef.close();
          }
        }
        else {
          this.accountService.addVehicle(this.selectedCar.Id, cname, selectedChargersArr,
            this.batteryStateOfHealth, this.fastChargeLimit).subscribe(
              () => {
                this.dialogContentIsLoading = false;
                this.dialogRef.close();
              },
              (error) => {
                this.dialogContentIsLoading = false;
                if (error && error.name === "TimeoutError") {
                  this.carSelectorError = true;
                }
                else if (error && error.status && error.status == 401) {
                  this.dialogRef.close();
                }
              });
        }
      }
      else {
        if (this.inputParamsService.AcceptCookies) {
          localStorage.setItem("chargingStations", this.selectedChargerTypes.toString());

          if (this.inputParamsService.getBatteryStateOfHealth() != this.batteryStateOfHealth) {
            localStorage.setItem("batteryStateOfHealth", this.batteryStateOfHealth.toString());
            this.inputParamsService.setBatteryStateOfHealth(this.batteryStateOfHealth);
          }
          if (this.inputParamsService.getFastChargeLimit() != this.fastChargeLimit) {
            localStorage.setItem("fastChargeLimit", this.fastChargeLimit.toString());
            this.inputParamsService.setFastChargeLimit(this.fastChargeLimit);
          }
        }
        this.selectedCar.ChargerTypes = selectedChargersArr;
        if (this.mapService.getChargingStations().toString() != this.selectedChargerTypes.toString()) {
          this.mapService.setChargingStations(this.selectedChargerTypes);
        }
        if (JSON.stringify(this.mapService.getSelectedCar()) != JSON.stringify(this.selectedCar)) {
          this.mapService.setSelectedCar(this.selectedCar, true);
        }

        this.dialogRef.close();
      }
    }
  }

  // set new vehicle with charging stations data in the application
  private selectChargingStationArray(): number[] {
    let selectedChargersArr = [];
    for (let i = 0; i < this.selectedChargerTypes.length; i++) {
      if (this.selectedChargerTypes[i] == true) {
        selectedChargersArr.push(i + 1);
      }
    }
    return selectedChargersArr;
  }

  public carSubTypeChanged(): void {
    // change vehicle inside pop up window
    for (let i = 0; i < this.ECars.length; i++) {
      if (this.carType == this.ECars[i].Type) {
        if (!this.ECars[i].Subtype || this.ECars[i].Subtype && this.carSubType == this.ECars[i].Subtype) {
          this.selectedCar = { ...this.ECars[i] };
          this.setChargerTypes(this.selectedCar.ChargerTypes);
          break;
        }
      }
    }
  }

  public carTypeChanged(): void {
    this.carTypeIndex = this.getCarTypeIndex(this.carType);
    if (this.CarTypes[this.carTypeIndex].subtypes.length > 0) {
      this.carSubType = this.CarTypes[this.carTypeIndex].subtypes[0];
    }

    this.carSubTypeChanged();
  }

  public carChanged(ecar: ECarType): void {
    this.selectedCar = { ...ecar };
    this.setChargerTypes(this.selectedCar.ChargerTypes);
  }

  private setChargerTypes(chargerTypesArr: number[]): void {
    // process charger types data
    for (let i = 0; i < this.selectedChargerTypes.length; i++) {
      this.selectedChargerTypes[i] = false;
    }

    for (let i = 0; i < chargerTypesArr.length; i++) {
      this.selectedChargerTypes[chargerTypesArr[i] - 1] = true;
    }

    this.multipleTypeSelector();
  }

  public tripRecordingValueChanged(): void {

  }

  public checkChargerTypes(): void {
    let csTrue = false;
    for (let i = 0; i < this.selectedChargerTypes.length; i++) {
      if (this.selectedChargerTypes[i] == true) {
        csTrue = true;
      }
    }
    if (csTrue) {
      this.csError = false;
    }
    else {
      this.csError = true;
    }
  }

  private multipleTypeSelector(): void {
    let ecarTypes = this.ECars.filter(it => {
      if (it.Subtype) {
        return (it.Type == this.carType && it.Subtype == this.carSubType);
      }
      else {
        return it.Type == this.carType;
      }
    });
    this.multipleCarType = ecarTypes.length > 1 ? true : false;
  }

  private getCarTypeIndex(carType): number {
    return this.CarTypes.findIndex(carTypeElement => carTypeElement.name == carType);
  }

  public checkCarAndAddNewSubscription(): void {
    if (this.data && this.data.editedUserCarId) {
      this.addNewSubscription();
    }
    else {
      let selectedChargersArr = this.selectChargingStationArray();
      let cname: string = this.customCarNameForm.get('customCarName').value || "";
      this.dialogContentIsLoading = true;
      this.carSelectorError = false;
      const settings = this.inputParamsService.getECarSettings();
      settings.batteryStateOfHealth = this.batteryStateOfHealth;
      settings.fastChargeLimit = this.fastChargeLimit;
      settings.tripRecording = this.tripRecordingSettings;

      this.accountService.addVehicle(this.selectedCar.Id, cname, selectedChargersArr,
        this.batteryStateOfHealth, this.fastChargeLimit).subscribe(
          (resp) => {
            if (resp.success) {
              this.dialogContentIsLoading = false;

              this.editedUserCar = this.accountService.user.getCarById(resp.success.id);
              this.addNewSubscription();
            }
          },
          (error) => {
            this.dialogContentIsLoading = false;
            if (error && error.name === "TimeoutError") {
              this.carSelectorError = true;
            }
            else if (error && error.status && error.status == 401) {
              this.dialogRef.close();
            }
          });
    }
  }

  public addNewSubscription(): void {
    let selectedChargersArr = this.selectChargingStationArray();

    let cname: string = this.customCarNameForm.get('customCarName').value || "";
    this.dialogContentIsLoading = true;
    this.carSelectorError = false;
    const settings = this.inputParamsService.getECarSettings();
    settings.batteryStateOfHealth = this.batteryStateOfHealth;
    settings.fastChargeLimit = this.fastChargeLimit;
    settings.tripRecording = this.tripRecordingSettings;
    if (this.editedUserCar != null) {
      this.accountService.editVehicle(this.editedUserCar.userVehicleID, this.selectedCar.Id, cname, selectedChargersArr, JSON.stringify(settings), true).subscribe(() => {
      },
        (error) => {
          this.dialogContentIsLoading = false;
          if (error && error.name === "TimeoutError") {
            this.carSelectorError = true;
          }
          else if (error && error.status && error.status == 401) {
            this.dialogRef.close();
          }
        });
    }
  }

  public clickCancel(): void {
    this.dialogRef.close();
    if (this.data && this.data.firstUserCar) {
      this.accountService.addDefaultVehicleToUser();
    }
  }
}
