import { Component, ElementRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
  
})
export class AppComponent {
 
  constructor(private _elementRef: ElementRef) {
    
  }
  ngOnInit(): void {
    if (environment.production) {
      this._elementRef.nativeElement.removeAttribute("ng-version"); 
    }
  }
}


