<h1 mat-dialog-title>Alert</h1>
<div mat-dialog-content>
    <div class="flex-row">
        <img
            [src]="'assets/icons/' + settingsService.getMap().Skin + '/voicecommand/not_available.svg'">
    </div>
    <div class="flex-row">
        <h5 [ngClass]="{'long-msg': errorMsg.length>30}">{{errorMsg}}</h5>
    </div>
</div>
<div mat-dialog-actions *ngIf="notSupported">
    <button mat-button (click)="close()">Ok</button>
</div>