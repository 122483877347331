import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable, Observer } from 'rxjs';
import { AccountService } from '../services/account.service';

@Injectable({
  providedIn: 'root'
})
export class AccountResolverService implements Resolve<Observable<any>> {

  constructor(public accountService: AccountService) { }

  // pre-load user data
  resolve() {
    const authorized = this.accountService.initAccount();

    const observable: Observable<any> = new Observable((observer: Observer<any>) => {
      if (authorized) {
        const accountHttpCalls = [
          this.accountService.loadUserProfile(),
          this.accountService.getUserSettings()
        ];
        forkJoin(accountHttpCalls).subscribe((resp) => {
          observer.next(null);
          observer.complete();
        }, (error) => {
          observer.next(null);
          observer.complete();
        });
      }
      else {
        observer.next(null);
        observer.complete();
      }
    });
    return observable;
  }
}
