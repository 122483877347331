
<!-- Generator: Adobe Illustrator 21.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 178 66" style="enable-background:new 0 0 178 66;" xml:space="preserve">
<rect id="charging" x="8" y="8" class="st0" [attr.width]="150 * (batteryLevel/100)" height="50"/>
<path id="battery" class="st1" d="M176,17h-10v-1.2C166,7.1,158.9,0,150.1,0H15.8C7.1,0,0,7.1,0,15.8v34.3C0,58.9,7.1,66,15.8,66
	h134.3c8.8,0,15.9-7.1,15.9-15.8V49h10c1.1,0,2-0.9,2-2V19C178,17.9,177.1,17,176,17z M69,8h28v50H69V8z M67,58H39V8h28V58z M99,8
	h28v50H99V8z M8,50.2V15.8C8,11.5,11.5,8,15.8,8H37v50H15.8C11.5,58,8,54.5,8,50.2z M158,50.2c0,4.3-3.6,7.8-7.9,7.8H129V8h21.1
	c4.3,0,7.9,3.5,7.9,7.8V50.2z"/>
</svg>

