import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgScrollbar } from 'ngx-scrollbar';
import { FeedbackComponent } from 'src/app/components/modals/tabbed-info-dialog/feedback/feedback.component';
import { LanguageService } from 'src/app/services/language.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-tabbed-info-dialog',
  templateUrl: './tabbed-info-dialog.component.html',
  styleUrls: ['./tabbed-info-dialog.component.scss']
})
export class TabbedInfoDialogComponent implements OnInit {

  public menuSelected: string = "welcome";
  private subscriptions: any = [];
  @ViewChildren(FeedbackComponent) feedbackComponents: QueryList<FeedbackComponent>;
  @ViewChild('panelScrollBar', { static: true }) panelScrollBar: NgScrollbar;

  constructor(
    public dialogRef: MatDialogRef<TabbedInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef,
    public settingsService: SettingsService,
    public languageService: LanguageService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.feedbackComponents.changes.subscribe((comps: QueryList<FeedbackComponent>) => {
      this.feedbackComponents.forEach(feedbackTab => {
        this.subscriptions.push(feedbackTab.ObservableTabChangeRequest.subscribe((resp) => {
          if (resp) {
            this.changeSelectedMenu(resp);
          }
        }));
      });
    });

    if (this.data.openMenu != undefined && this.data.openMenu != null) {
      this.changeSelectedMenu(this.data.openMenu);
    }
  }

  changeSelectedMenu(name: string) {
    this.menuSelected = name;
    this.cdr.detectChanges();
    this.panelScrollBar.scrollTo({top: 0, duration: 0});
  }

  clickClose(): void {
    this.dialogRef.close();
  }
}
