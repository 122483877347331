<div [ngClass]="{'desktop': Desktop, 'non-desktop': !
Desktop, 'hud-bottom-opened': hudBottomOpened || chargeState!=ChargeState.None, 'hide-navigation-hud': mobileVisiblePanel == 'car',
    'charge-details': chargeState!=ChargeState.None}"
    class="navigation-hud">
    <div class="hud-top-outer">
        <div class="hud-left-outer" *ngIf="turn">
            <div class="hud-left">
                <div class="turn-icon">
                    <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/turn/'  + turn.Icon[0]"
                        alt="turn icon" />
                </div>
                <div class="turn-distance">
                    <div [hidden]="!showMetricKMeter(turnDistance)">
                        {{ turnDistance/1000 | turnValueIntegerThousands }}<small class="turn-distance-unit">km</small>
                    </div>
                    <div [hidden]="!showMetricMeter(turnDistance)">{{ turnDistance | turnValueInteger }}<small
                            class="turn-distance-unit">m</small>
                    </div>
                    <div [hidden]="!showImperialMile(turnDistance)">
                        {{ turnDistance*0.000621371192 | turnValueIntegerThousands }}<small
                            class="turn-distance-unit">mi</small>
                    </div>
                    <div [hidden]="!showImperialYard(turnDistance)">
                        {{ turnDistance*1.0936133 | turnValueInteger }}<small class="turn-distance-unit">yd</small>
                    </div>
                </div>
            </div>
            <div class="second-turn-icon" *ngIf="turn.CombinedTurn && !Desktop">
                <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/turn/' + turn.Icon[1]"
                    alt="second turn icon" />
            </div>

        </div>

        <div class="hud-side"
            *ngIf="!Desktop || (mobileResolutionService.getDevice().tablet() && mobileResolutionService.isPortrait() === false)">
            <div class="location-button" [ngStyle]="{ visibility: mapDragged ? 'visible' : 'hidden' }"
                (click)="navigationService.ObservableSetCenter.next(true)">
                <div class="locate-icon">
                    <img alt="locate"
                        [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/locate_icon.svg'" />
                </div>
            </div>
        </div>

        <div class="hud-right" [ngClass]="{'left-stop': Desktop && !turn, 'one-item-row': computersArray.length<2}">
            <div class="mobile-roadlanes"
                *ngIf="turn && !Desktop && roadLanesVisible && turn.LaneInfo.length>1 && !floatingInfoVisible">
                <app-navigation-roadlanes class="portrait" [Desktop]="false"></app-navigation-roadlanes>
                <app-navigation-roadlanes class="landscape" [Desktop]="true"></app-navigation-roadlanes>
            </div>

            <div class="navigation-hud-bottom-panel">
                <div class="border-flex-closer-outer">
                    <div class="border-flex-left-button">
                        <img class="mobile-selected-car" [src]="mapService.getSelectedCarIcon()"
                            (click)="setMobileVisiblePanel('car')" alt="car selector" />
                    </div>
                    <div class="border-flex-closer" *ngIf="!Desktop" (click)="toggleBottomPanel()">
                        <div class="triangle-icon"></div>
                    </div>
                    <div class="border-flex-right-button">
                        <app-voice-input [Desktop]="false"></app-voice-input>
                    </div>
                </div>
                <div class="navigation-hud-panel-border">
                    <div class="border-flex"></div>
                    <div class="border-flex-battery" (click)="toggleBottomPanel()"
                        *ngIf="chargeState==ChargeState.None">
                        <app-battery [batteryLevel]="estimatedBatteryLevel"></app-battery>
                        <h4>{{ estimatedBatteryLevel }}</h4><small>%</small>
                    </div>
                    <div class="border-flex-charger" *ngIf="chargeState!=ChargeState.None">
                        <img id="charger-icon" alt="turn icon" src="assets/icons/default/ui/charger_icon.svg" />
                    </div>
                    <div class="border-flex"></div>
                </div>
                <div class="navigation-hud-bottom-panel-container">
                    <div class="hud-bottom-rows-container">
                        <div class="hud-bottom-row" *ngFor="let computerElement of computersArray; let i = index;"
                            (click)="toggleBottomPanel()" [ngClass]="{'border-top': i>0}"
                            [hidden]="chargeState!=ChargeState.None">
                            <div class="hud-bottom-row-container">
                                <div class="flex-el flex-icon">
                                    <div class="flex-el-bottom">
                                        <img [src]="computerElement.Icon">
                                        <div *ngIf="computerElement.BatteryReservedWarning"
                                            class="battery-reserved-warning-icon"
                                            (click)="openBatteryReservedSnackbar()">!</div>
                                    </div>
                                </div>
                                <div class="flex-el flex-values">
                                    <div class="flex-el flex-dist">
                                        <div class="flex-el-bottom">
                                            <img class="flex-el-bottom-icon"
                                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/computer_icon_distance.svg'" />
                                            <div class="flex-computer"
                                                [hidden]="!showMetricKMeter(computerElement.DistToDest)">
                                                <h3>{{ computerElement.DistToDest/1000 | turnValueIntegerThousands }}
                                                </h3>
                                                <h5>km</h5>
                                            </div>
                                            <div class="flex-computer"
                                                [hidden]="!showMetricMeter(computerElement.DistToDest)">
                                                <h3>{{ computerElement.DistToDest | turnValueInteger }}</h3>
                                                <h5>m</h5>
                                            </div>
                                            <div class="flex-computer"
                                                [hidden]="!showImperialMile(computerElement.DistToDest)">
                                                <h3>{{ computerElement.DistToDest*0.000621371192 |
                                                    turnValueIntegerThousands }}
                                                </h3>
                                                <h5>mi</h5>
                                            </div>
                                            <div class="flex-computer"
                                                [hidden]="!showImperialYard(computerElement.DistToDest)">
                                                <h3>{{ computerElement.DistToDest*1.0936133 | turnValueInteger }}</h3>
                                                <h5>yd</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-el">
                                        <div class="flex-el-bottom">
                                            <img class="flex-el-bottom-icon"
                                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/computer_icon_clock.svg'" />
                                            <h3 class="flex-computer">{{ computerElement.TimeToDest }}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="hud-bottom-row" *ngIf="chargeState!=ChargeState.None" (click)="toggleBottomPanel()">
                            <div class="hud-bottom-row-container">
                                <p [innerHTML]="arrivedAtMessage"></p>
                            </div>
                            <div class="hud-bottom-row-container">
                                <div class="flex-el flex-values">
                                    <div class="flex-el">
                                        <div class="flex-el-bottom"><img class="flex-el-bottom-icon"
                                                src="/assets/icons/dark/ui/computer_icon_distance.svg">
                                            <div class="flex-computer">
                                                <h3>{{ arrivedChargerDetails?.BatteryMinPercent }}→{{ arrivedChargerDetails?.BatteryMaxPercent }}%</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-el">
                                        <div class="flex-el-bottom"><img class="flex-el-bottom-icon"
                                                src="/assets/icons/dark/ui/computer_icon_clock.svg">
                                            <h3 class="flex-computer">{{ chargeTime }}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="hud-bottom-row" [hidden]="computersArray.length>0 || chargeState!=ChargeState.None"
                            (click)="toggleBottomPanel()">
                            <div class="hud-bottom-row-container">
                                <div class="flex-el flex-icon">
                                    <div class="flex-el-bottom"><img
                                            [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/target_icon.svg'">
                                    </div>
                                </div>
                                <div class="flex-el flex-values">
                                    <div class="flex-el flex-dist">
                                        <div class="flex-el-bottom">
                                            <img class="flex-el-bottom-icon"
                                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/computer_icon_distance.svg'" />
                                            <div>
                                                <h3 class="placeholder flex-computer">--</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-el">
                                        <div class="flex-el-bottom">
                                            <img class="flex-el-bottom-icon"
                                                [src]="'/assets/icons/' + settingsService.getMap().Skin + '/ui/computer_icon_clock.svg'" />
                                            <h3 class="placeholder flex-computer">--</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hud-bottom-row button-menu">
                        <div class="hud-bottom-row-container" [ngClass]="{'single-grid': computersArray.length<2 }"
                            *ngIf="chargeState==ChargeState.None">
                            <div class="flex-el grid1" *ngIf="computersArray.length>0 && computersArray[0].Type=='wp'">
                                <button class="hud-menu-btn" (click)="skipWaypoint()">
                                    <img src="assets/icons/default/ui/waypoint.svg" />{{
                                    languageService.languageJSON.Global_Skip }}
                                </button>
                            </div>
                            <div class="flex-el grid1" *ngIf="computersArray.length>0 && computersArray[0].Type=='cs'">
                                <button class="hud-menu-btn" (click)="skipChargingStation()"
                                    [ngClass]="{'inactive': CannotSkipMoreChargingStation}">
                                    <img src="assets/icons/dark/ui/chargingplan_icon.svg" />{{
                                    languageService.languageJSON.Global_Skip }}
                                </button>
                            </div>
                            <div class="flex-el grid2"
                                [ngClass]="{'flexible-stop': computersArray.length>1 && !NextWaypoint }">
                                <button class="hud-menu-btn" (click)="stopNavigation()">
                                    <img [src]="'/assets/icons/dark/ui/target_icon.svg'" />{{
                                    languageService.languageJSON.Global_Stop }}
                                </button>
                            </div>
                        </div>
                        <div class="hud-bottom-row-container" *ngIf="chargeState==ChargeState.ArriveToCharger">
                            <div class="flex-el grid1">
                                <button class="hud-menu-btn" (click)="startCharge()">
                                    <img src="assets/icons/light/turn/charge.svg" />{{ languageService.languageJSON.TurnList_CHARGE }}
                                </button>
                            </div>
                            <div class="flex-el grid2">
                                <button class="hud-menu-btn" (click)="replanNavigation()" *ngIf="!arrivedChargerIsEndpoint">
                                    <img [src]="'/assets/icons/light/ui/start_icon_list.svg'" />{{ languageService.languageJSON.Global_Replan }}
                                </button>
                                <button class="hud-menu-btn" (click)="stopNavigation()" *ngIf="arrivedChargerIsEndpoint">
                                    <img [src]="'/assets/icons/light/ui/target_icon_blue.svg'" />{{ languageService.languageJSON.Global_Stop }}
                                </button>
                            </div>
                        </div>
                        <div class="hud-bottom-row-container single-grid" *ngIf="chargeState==ChargeState.Charging || chargeState==ChargeState.ChargeFinish">
                            <div class="flex-el grid2">
                                <button class="hud-menu-btn" (click)="finishCharge()">
                                    <img src="assets/icons/light/turn/charge.svg" /> {{ languageService.languageJSON.NavigationHud_ChargingIsFinished }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class='speedlimit-outer' *ngIf="turn && speedLimit<130 && speedLimit>0 && !floatingInfoVisible"
                    [ngClass]="{'roadlanes': roadLanesVisible && turn.LaneInfo.length>1}">
                    <div class="speedlimit">
                        <img class="speedlimit-icon" src="assets/icons/light/ui/slider_small_button_battery.svg">
                        <div class='speedlimit-value' *ngIf="settingsService.getUnit().Speed == 'metric'">
                            {{ speedLimit }} </div>
                        <div class='speedlimit-value' *ngIf="settingsService.getUnit().Speed == 'imperial'">
                            {{ speedLimit*0.621371192 | valueInteger }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-flex-closer landscape" *ngIf="!Desktop" (click)="toggleBottomPanel()">
                <div class="triangle-icon"></div>
            </div>
        </div>

        <div class="second-turn-icon" *ngIf="turn?.CombinedTurn && Desktop">
            <img [src]="'assets/icons/' + settingsService.getMap().Skin + '/turn/' + turn.Icon[1]"
                alt="second turn icon" />
        </div>

        <div class="loader" *ngIf="Desktop && !turn">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    <div class="floating-info" [ngClass]="{'roadlanes': roadLanesVisible && turn.LaneInfo.length>1, 'no-turn': !turn}"
        *ngIf="floatingInfoVisible && batteryOptimizationSettingsRequired" (click)="floatingInfoOnClick()">
        <h4>{{ languageService.languageJSON.NavigationHud_BatterySaveWarning }}</h4>
        <div class="battery-settings-button">
            <img class="settings-icon" alt="settings icon" [src]="'/assets/icons/dark/ui/settings_icon.svg'" />
            <h4>{{
                languageService.languageJSON.Global_Settings }}</h4>
        </div>
    </div>
</div>