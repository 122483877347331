<div class="subscription-dialog">

    <h1 mat-dialog-title>
        {{ languageService.languageJSON.SubscriptionsDialog_CancelSubscription }}
    </h1>
    <div mat-dialog-content>
        <div class="logo">
            <img src="assets/icons/dark/logo/ev_navigation_logo.png" />
            <h2 *ngIf="!type?.includes('proplus.')">PRO</h2>
            <img *ngIf="type?.includes('proplus.')" id="proplus-icon" src="assets/icons/default/ui/pro_logo.svg" />
        </div>

        <div class="content">
            <p [innerHTML]="deleteSubscriptionDescription"></p>

            <p class="grey">
                {{ languageService.languageJSON.SubscriptionsDialog_WereSorryToSeeYouLeave }}
            </p>
        </div>

    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="delete()">{{
            languageService.languageJSON.Global_Delete }}</button>
        <button mat-button (click)="close()">{{
            languageService.languageJSON.Global_Cancel }}</button>
    </div>

</div>