import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { AccountService } from 'src/app/services/account.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { SettingsService, MapSkin, Unit } from 'src/app/services/settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { ConsoleLoggerService } from 'src/app/services/console-logger.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MapElementType } from '../../map/map.component';
import { ChargingOperator } from '../../../models/charging-operator';
import { ChargingStation, ChargingStationDetails } from 'src/app/models/charging-station';
import { UnitSettings } from 'src/app/models/unit-settings';
import { RoutingSettings } from 'src/app/models/routing-settings';
import { VoiceGuidenceSettings } from 'src/app/models/voice-guidence-settings';
import { MapSettings } from 'src/app/models/map-settings';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss']
})

export class SettingsDialogComponent implements OnInit {

  @ViewChild('settingsScrollbar') settingsScrollbar: NgScrollbar;
  /**
   * 0 settings
   * 1 disabled charging stations
   * 2 active charging stations
   *  */
  public activeStepIndex: number = 0;
  public title: string[];
  public Language: { code: string, name: string }[];

  public settingValueTollRoad: number = 1;
  public settingValueFerries: number = 1;
  public settingValueHighways: number = 1;
  public settingValueVoiceGuidance: number = 1;
  public settingValueMapColors: number = 0;
  public settingValuePOIs: number = 0;
  public settingValueDistance: number = 1;
  public settingValueTemperature: number = 1;
  public settingValueSpeed: number = 1;
  public settingValuePressure: number = 1;
  public settingValueLanguage: string;

  public dialogOpenTollRoad: number = 1;
  public dialogOpenFerries: number = 1;
  public dialogOpenHighways: number = 1;

  public dialogContentIsLoading: boolean = false;
  public settingsError: boolean = false;

  public homeAddressText: string = "";
  public workAddressText: string = "";

  public disabledChargingStations: ChargingStationDetails[] = [];
  public favoriteChargingStations: ChargingStationDetails[] = [];

  public chargingOperatorsList: ChargingOperator[] = [];
  public nonUsedChargingOperators: ChargingOperator[] = [];
  public disabledChargingOperators: ChargingOperator[] = [];
  public favoriteChargingOperators: ChargingOperator[] = [];

  constructor(public dialogRef: MatDialogRef<SettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog, public mapService: MapService,
    private inputParamsService: InputParamsService, private cdr: ChangeDetectorRef,
    public accountService: AccountService, public settingsService: SettingsService,
    public languageService: LanguageService, private consoleLoggerService: ConsoleLoggerService) {
  }

  ngOnInit(): void {
    this.Language = this.settingsService.getLanguageList()
    // Init unit settings
    const unit: UnitSettings = this.settingsService.getUnit();
    this.settingValueDistance = (unit.Distance === Unit.Metric ? 1 : 0);
    this.settingValueTemperature = (unit.Temperature === Unit.Metric ? 1 : 0);
    this.settingValueSpeed = (unit.Speed === Unit.Metric ? 1 : 0);
    this.settingValuePressure = (unit.Pressure === Unit.Metric ? 1 : 0);

    // Init routing settings
    const routing: RoutingSettings = this.settingsService.getRouting();
    this.settingValueTollRoad = (routing.TollRoads == true ? 1 : 0);
    this.settingValueFerries = (routing.Ferries == true ? 1 : 0);
    this.settingValueHighways = (routing.Highways == true ? 1 : 0);

    // Init voice settings
    const voiceguidance: VoiceGuidenceSettings = this.settingsService.getVoiceGuidence();
    this.settingValueVoiceGuidance = (voiceguidance.Mute == true ? 0 : 1);

    // Init map settings
    const mapsettings: MapSettings = this.settingsService.getMap();
    this.settingValueMapColors = (mapsettings.Skin === MapSkin.Light ? 1 : 0);
    this.settingValuePOIs = (mapsettings.POIs == true ? 1 : 0);

    this.dialogOpenTollRoad = this.settingValueTollRoad;
    this.dialogOpenFerries = this.settingValueFerries;
    this.dialogOpenHighways = this.settingValueHighways;

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogClose();
    });

    // Init charging station settings
    const disabledChargingStationIds = this.settingsService.getDisabledChargingStationIds();
    const favoriteChargingStationIds = this.settingsService.getFavoriteChargingStationIds();

    if (disabledChargingStationIds) {
      this.mapService.getChargingStationDetail(disabledChargingStationIds).subscribe((resp) => {
        if (resp) {
          this.disabledChargingStations = resp;
        }
      });
    }

    if (favoriteChargingStationIds) {
      this.mapService.getChargingStationDetail(favoriteChargingStationIds).subscribe((resp) => {
        if (resp) {
          this.favoriteChargingStations = resp;
        }
      });
    }

    // Init charging operator settings
    
    this.chargingOperatorsList = this.mapService.chargingOperatorsArray;
    this.initNonUsedChargingOperators();

    this.settingValueLanguage = this.settingsService.getLanguage();

    this.initAddresses();

    if (this.data && this.data == MapElementType.DisabledChargingStation && disabledChargingStationIds.length > 0) {
      this.activeStepIndex = 1;
    }
    if (this.data && this.data == MapElementType.FavoriteChargingStation && favoriteChargingStationIds.length > 0) {
      this.activeStepIndex = 2;
    }

    this.initTitle();

    this.cdr.detectChanges();
  }

  private initNonUsedChargingOperators(): void {
    const favoriteChargingOperatorIds = this.settingsService.getFavoriteChargingOperatorIds();
    this.favoriteChargingOperators = [];
    for (let i = 0; i < favoriteChargingOperatorIds.length; i++) {
      const favoriteOP = this.mapService.getChargingOperatorByID(favoriteChargingOperatorIds[i]);
      if (favoriteOP) {
        this.favoriteChargingOperators.push(favoriteOP);
      }
    }

    const disabledChargingOperatorIds = this.settingsService.getDisabledChargingOperatorIds();
    this.disabledChargingOperators = [];
    for (let i = 0; i < disabledChargingOperatorIds.length; i++) {
      const disabledOP = this.mapService.getChargingOperatorByID(disabledChargingOperatorIds[i]);
      if (disabledOP) {
        this.disabledChargingOperators.push(disabledOP);
      }
    }

    this.nonUsedChargingOperators = this.chargingOperatorsList.filter((operator) => { return disabledChargingOperatorIds.includes(operator.id) || favoriteChargingOperatorIds.includes(operator.id) ? false : true });
  }

  private initTitle(): void {
    this.title = [
      this.languageService.languageJSON.Global_Settings,
      this.languageService.languageJSON.SettingsDialog_DisabledChargingStations,
      this.languageService.languageJSON.SettingsDialog_FavoriteChargingStations,
      this.languageService.languageJSON.SettingsDialog_Avoided,
      this.languageService.languageJSON.SettingsDialog_Preferred,
    ];
  }

  public openSelectedPage(activeStepIndex: number): void {
    this.activeStepIndex = activeStepIndex;
    this.mapService.ObservableClearLastOpenedChargingStation.next(true);
  }

  private initAddresses(): void {
    // Init addresses
    const homeAddress = this.settingsService.getHomeAddress();
    if (homeAddress) {
      this.homeAddressText = homeAddress.Description;
    }

    const workAddress = this.settingsService.getWorkAddress();
    if (workAddress) {
      this.workAddressText = workAddress.Description;
    }
  }

  public switchParamsChanged(): void {
    this.dialogContentIsLoading = true;

    setTimeout(() => {
      // Save unit settings
      const unit = {};
      unit['distance'] = (this.settingValueDistance > 0 ? 'metric' : 'imperial');
      unit['temperature'] = (this.settingValueTemperature > 0 ? 'metric' : 'imperial');
      unit['speed'] = (this.settingValueSpeed > 0 ? 'metric' : 'imperial');
      unit['pressure'] = (this.settingValuePressure > 0 ? 'metric' : 'imperial');
      this.settingsService.setUnit(this.settingsService.unitJSONtoUnitSettings(unit));

      // Init routing settings
      const routing = {};
      routing['tollroads'] = (this.settingValueTollRoad > 0);
      routing['ferries'] = (this.settingValueFerries > 0);
      routing['highways'] = (this.settingValueHighways > 0);

      // Init voice settings
      const voiceguidance = {};
      voiceguidance['mute'] = (this.settingValueVoiceGuidance == 0);

      // Init map settings
      const mapsettings = {};
      mapsettings['skin'] = (this.settingValueMapColors > 0 ? MapSkin.Light : MapSkin.Dark);
      mapsettings['pois'] = (this.settingValuePOIs > 0);
      if (this.settingsService.getMap().Skin != mapsettings['skin'] || this.settingsService.getMap().POIs != mapsettings['pois']) {
        this.settingsService.setMap(this.settingsService.mapJSONtoMapSettings(mapsettings));
      }

      // Save
      if (this.inputParamsService.AcceptCookies || this.accountService.getIsAuthorized()) {
        this.settingsService.setUnit(this.settingsService.unitJSONtoUnitSettings(unit));
        this.settingsService.setRounting(this.settingsService.routingJSONtoRoutingSettings(routing));
        this.settingsService.setVoiceGuidence(this.settingsService.voiceGuidenceJSONtoVoiceGuidenceSettings(voiceguidance));
        this.settingsService.setMap(this.settingsService.mapJSONtoMapSettings(mapsettings));
      }

      this.dialogContentIsLoading = false;
    }, (10));
  }

  public editAddress(addressType: string): void {
    this.mapService.ObservableShowOnMap.next({ type: MapElementType.Address, data: addressType });
    this.dialogRef.close();
  }

  public removeAddress(addressType: string): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      {
        data: {
          title: this.languageService.languageJSON.SettingsDialog_DeleteAddress_Title,
          message: addressType === "home" ? this.languageService.languageJSON.SettingsDialog_DeleteAddress_Desc_Home : this.languageService.languageJSON.SettingsDialog_DeleteAddress_Desc_Work,
          buttons: [this.languageService.languageJSON.Global_Delete,
          this.languageService.languageJSON.Global_Cancel]
        }, autoFocus: false
      });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        if (addressType === "home") {
          this.settingsService.removeHomeAddress();
          this.homeAddressText = "";
        }
        if (addressType === "work") {
          this.settingsService.removeWorkAddress();
          this.workAddressText = "";
        }
        this.accountService.setUserAddresses().subscribe();
      }
    });
  }

  public getChargerIcon(charger_img: string): string {
    let chargerIcon = "assets/icons/" + this.settingsService.getMap().Skin + "/map/";
    switch (charger_img) {
      case "cs_big1":
        chargerIcon += "chargingstation_big1.svg";
        break;
      case "cs_big1_light":
        chargerIcon += "chargingstation_big1.svg";
        break;
      case "cs_big2":
        chargerIcon += "chargingstation_big2.svg";
        break;
      case "cs_big2_light":
        chargerIcon += "chargingstation_big2.svg";
        break;
      case "cs_big3":
        chargerIcon += "chargingstation_big3.svg";
        break;
      case "cs_big3_light":
        chargerIcon += "chargingstation_big3.svg";
        break;
    }
    return chargerIcon;
  }

  public openDisabledCharger(charger: ChargingStationDetails): void {
    this.mapService.ObservableShowOnMap.next({ type: MapElementType.DisabledChargingStation, data: charger });
    this.dialogRef.close();
  }

  public removeDisabledCharger(charger: ChargingStationDetails): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      {
        data: {
          title: this.languageService.languageJSON.SettingsDialog_ChargingStation_ReEnable_Title,
          message: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_Desc,
          buttons: [this.languageService.languageJSON.Global_Normal,
          this.languageService.languageJSON.Global_Cancel]
        }, autoFocus: false
      });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        this.settingsService.setToNormalChargingStation(charger.id);
        this.disabledChargingStations.splice(this.disabledChargingStations.findIndex(element => element.id === charger.id), 1);
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setDisabledChargingStations().subscribe();
        }
        if (this.disabledChargingStations.length == 0) {
          this.activeStepIndex = 0;
        }
        this.mapService.ObservableSetChargingStationRecalc.next(true);
      }
    });
  }

  public disableAllDisabledChargers(): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      {
        data: {
          title: this.languageService.languageJSON.SettingsDialog_ChargingStation_ReEnable_Title,
          message: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_All_Desc,
          buttons: [this.languageService.languageJSON.Global_Normal,
          this.languageService.languageJSON.Global_Cancel]
        }, autoFocus: false
      });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        this.disabledChargingStations = [];
        this.settingsService.setDisabledChargingStationIds([]);
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setDisabledChargingStations().subscribe();
        }
        this.activeStepIndex = 0;
        this.mapService.ObservableSetChargingStationRecalc.next(true);
      }
    });
  }

  public openFavoriteCharger(charger: ChargingStationDetails): void {
    this.mapService.ObservableShowOnMap.next({ type: MapElementType.FavoriteChargingStation, data: charger });
    this.dialogRef.close();
  }

  public removeFavoriteCharger(charger: ChargingStationDetails): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      {
        data: {
          title: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_Title,
          message: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_Desc,
          buttons: [this.languageService.languageJSON.Global_Normal,
          this.languageService.languageJSON.Global_Cancel]
        }, autoFocus: false
      });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        this.settingsService.setToNormalChargingStation(charger.id);
        this.favoriteChargingStations.splice(this.favoriteChargingStations.findIndex(element => element.id === charger.id), 1);
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setFavoriteChargingStations().subscribe();
        }
        if (this.favoriteChargingStations.length == 0) {
          this.activeStepIndex = 0;
        }
        this.mapService.ObservableSetChargingStationRecalc.next(true);
      }
    });
  }

  public disableAllFavoriteChargers(): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      {
        data: {
          title: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_Title,
          message: this.languageService.languageJSON.SettingsDialog_ChargingStation_Unmark_All_Desc,
          buttons: [this.languageService.languageJSON.Global_Normal,
          this.languageService.languageJSON.Global_Cancel]
        }, autoFocus: false
      });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        this.favoriteChargingStations = [];
        this.settingsService.setFavoriteChargingStationIds([]);
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setFavoriteChargingStations().subscribe();
        }
        this.activeStepIndex = 0;
        this.mapService.ObservableSetChargingStationRecalc.next(true);
      }
    });
  }

  public favoriteOperatorSelectedHandler(operator: ChargingOperator): void {
    const favoriteOperators = this.settingsService.getFavoriteChargingOperatorIds();
    favoriteOperators.push(operator.id);
    this.settingsService.setFavoriteChargingOperatorIds(favoriteOperators);
    this.dialogContentIsLoading = true;
    if (this.accountService.getIsAuthorized()) {
      this.accountService.setFavoriteChargingOperators().subscribe(() => {
        this.initNonUsedChargingOperators();
        this.dialogContentIsLoading = false;
        this.mapService.ObservableSetChargingStationRecalc.next(true);
      });
    }
    else {
      this.initNonUsedChargingOperators();
      this.dialogContentIsLoading = false;
      this.mapService.ObservableSetChargingStationRecalc.next(true);
    }
  }

  public removeFavoriteOperator(operator: ChargingOperator): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      {
        data: {
          title: "Unmark charging operator",
          message: "Do you really want to set this charging station back to normal?",
          buttons: [this.languageService.languageJSON.Global_Normal,
          this.languageService.languageJSON.Global_Cancel]
        }, autoFocus: false
      });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        const favoriteOperators = this.settingsService.getFavoriteChargingOperatorIds();
        favoriteOperators.splice(this.favoriteChargingOperators.findIndex(element => element.id === operator.id), 1);
        this.settingsService.setFavoriteChargingOperatorIds(favoriteOperators);
        this.dialogContentIsLoading = true;
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setFavoriteChargingOperators().subscribe(() => {
            this.initNonUsedChargingOperators();
            this.dialogContentIsLoading = false;
            this.mapService.ObservableSetChargingStationRecalc.next(true);
          });
        }
        else {
          this.initNonUsedChargingOperators();
          this.dialogContentIsLoading = false;
          this.mapService.ObservableSetChargingStationRecalc.next(true);
        }
      }
    });
  }

  public disabledOperatorSelectedHandler(operator: ChargingOperator): void {
    const disabledOperators = this.settingsService.getDisabledChargingOperatorIds();
    disabledOperators.push(operator.id);
    this.settingsService.setDisabledChargingOperatorIds(disabledOperators);
    this.dialogContentIsLoading = true;
    if (this.accountService.getIsAuthorized()) {
      this.accountService.setDisabledChargingOperators().subscribe(() => {
        this.initNonUsedChargingOperators();
        this.dialogContentIsLoading = false;
        this.mapService.ObservableSetChargingStationRecalc.next(true);
      });
    }
    else {
      this.initNonUsedChargingOperators();
      this.dialogContentIsLoading = false;
      this.mapService.ObservableSetChargingStationRecalc.next(true);
    }
  }

  public removeDisabledOperator(operator: ChargingOperator): void {
    const confirmationDialog = this.matDialog.open(ConfirmationDialogComponent,
      {
        data: {
          title: this.languageService.languageJSON.SettingsDialog_ReenableTitle,
          message: this.languageService.languageJSON.SettingsDialog_ReenableDesc,
          buttons: [this.languageService.languageJSON.Global_Normal,
          this.languageService.languageJSON.Global_Cancel]
        }, autoFocus: false
      });

    confirmationDialog.afterClosed().subscribe((resp) => {
      if (resp) {
        const disabledOperators = this.settingsService.getDisabledChargingOperatorIds();
        disabledOperators.splice(this.disabledChargingOperators.findIndex(element => element.id === operator.id), 1);
        this.settingsService.setDisabledChargingOperatorIds(disabledOperators);
        this.dialogContentIsLoading = true;
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setDisabledChargingOperators().subscribe(() => {
            this.initNonUsedChargingOperators();
            this.dialogContentIsLoading = false;
            this.mapService.ObservableSetChargingStationRecalc.next(true);
          });
        }
        else {
          this.initNonUsedChargingOperators();
          this.dialogContentIsLoading = false;
          this.mapService.ObservableSetChargingStationRecalc.next(true);
        }
      }
    });
  }

  public languageChanged(): void {
    this.settingsService.setLanguage(this.settingValueLanguage);
    this.initTitle();
  }

  public dialogClose(): void {
    if (this.accountService.getIsAuthorized()) {
      this.settingsError = false;
      this.dialogContentIsLoading = true;
      this.accountService.setUserSettings().subscribe(() => {
        this.dialogRef.close();
        if (
          this.dialogOpenTollRoad != this.settingValueTollRoad ||
          this.dialogOpenFerries != this.settingValueFerries ||
          this.dialogOpenHighways != this.settingValueHighways
        ) {
          this.inputParamsService.paramsUpdate();
        }
      },
        (error) => {
          this.dialogContentIsLoading = false;
          if (error && error.name === "TimeoutError") {
            this.settingsError = true;
            this.cdr.detectChanges();
            this.settingsScrollbar.scrollTo({ bottom: 0, duration: 0 });

          }
          else if (error && error.status && error.status == 401) {
            this.dialogRef.close();
          }
        });
    }
    else {
      this.dialogRef.close();
      if (
        this.dialogOpenTollRoad != this.settingValueTollRoad ||
        this.dialogOpenFerries != this.settingValueFerries ||
        this.dialogOpenHighways != this.settingValueHighways
      ) {
        this.inputParamsService.paramsUpdate();
      }
    }
  }
}
