import { Injectable } from '@angular/core';
import { LatLng } from 'leaflet';
import { Rha } from '../models/rha';
import { Lane, Route, RouteEVSpeedLimitSegment, RoutePlanElement, RoutePlanType, RoutePoint, Turn } from '../models/route';
import { WindElement } from '../models/wind';
import { InputParamsService } from './input-params.service';
import { MapService } from './map.service';
import { UtilsService } from './utils.service';
import { ECarType } from '../models/ecar-type';
import { ChargingOperator } from '../models/charging-operator';
import { AccountService, SubscriptionType } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class DataParserService {

  constructor(private utilsService: UtilsService, private mapService: MapService, private inputParamsService: InputParamsService,
    private accountService: AccountService) { }

  public parseRhaData(resp): Rha {
    const rhaResponse: Rha = {
      Range1: resp.range1,
      Range2: resp.range2,
      Temperature: resp.temperature,
      WeatherCode: resp.weathercode,
      Wind: resp.wind.map((windEl) => {
        let windElement: WindElement = {
          Location: new LatLng(windEl[0][0], windEl[0][1]),
          Angle: windEl[1],
          Speed: windEl[2],
          Opacity: windEl[3]
        }
        return windElement;
      })
    };

    return rhaResponse;
  }

  public parseRouteData(resp: any): Route {
    resp.route = this.utilsService.CalculateDistanceBetweenRoutePoints(resp.route);
    resp.route = this.utilsService.CalculateTimeBetweenRoutePoints(resp.route, resp.routeSegments, resp.chargeplan);

    /** Parse route data */
    const routeData: Route = {
      RoutePlan: this.getRoutePlan(resp),
      RoutePoints: resp.route.map((routeEl) => {
        const routePoint: RoutePoint = {
          Location: new LatLng(routeEl[0], routeEl[1]),
          Soc: 1 - routeEl[2],
          RouteSpeedLimit: routeEl[3],
          EVSpeedLimit: routeEl[4],
          Altitude: routeEl[5],
          JamFactor: routeEl[6],
          DistanceFromPreviousPoint: routeEl[7],
          TimeFromPreviousPoint: routeEl[8]
        };

        return routePoint;
      }),
      RouteInfo: {
        ChargeTime: resp.routeData.chargetime,
        Distance: resp.routeData.length,
        ChargingStops: resp.routeData.stops,
        TotalTime: this.calculateTotalTime(resp.route),
        TotalConsumption: null
      },
      RouteEVSpeedLimitSegments: resp.routeSegments.map((routeSegment) => {
        const evSpeedLimitSegment: RouteEVSpeedLimitSegment = {
          SpeedLimit: routeSegment[0],
          StartRouteIndex: routeSegment[1],
          EndRouteIndex: routeSegment[2],
          ShowInfoAtRouteIndex: routeSegment[3]
        }

        return evSpeedLimitSegment;
      }),
      Temperature: resp.temperature,
      Turns: this.getTurns(resp),
      WaypointsAddresses: resp.waypointsInfo,
      WeatherCode: resp.weathercode,
      Wind: resp.wind.map((windEl) => {
        let windElement: WindElement = {
          Location: new LatLng(windEl[0][0], windEl[0][1]),
          Angle: windEl[1],
          Speed: windEl[2],
          Opacity: windEl[3]
        }
        return windElement;
      }),
      Incidents: resp.incidents.map((incident: any) => {
        return {
          Location: new LatLng(incident.lat, incident.lon),
          Type: incident.type,
          Severity: incident.severity
        };
      }),
      RoadSurfaceAlerts: resp.roadsurfacealerts.map((alert: any) => {
        return {
          Location: new LatLng(alert.lat, alert.lon),
          Type: alert.type,
          Severity: alert.severity
        };
      })
    };

    routeData.RouteInfo.TotalConsumption = this.calculateTotalConsumption(routeData);

    return routeData;
  }

  private calculateTotalTime(route: any): number {
    let totalTime: number = 0;
    for (let i = 0; i < route.length; i++) {
      totalTime += route[i][8];
    }
    return Math.round(totalTime);
  }

  private calculateTotalConsumption(routeData: Route): number {
    let actPercent = routeData.RoutePlan[0].BatteryLevel;
    let sumPercent = 0;
    for (let i = 1; i < routeData.RoutePlan.length; i++) {
      if (routeData.RoutePlan[i].Type == RoutePlanType.WayPoint) {
        sumPercent += actPercent - routeData.RoutePlan[i].BatteryLevel;
        actPercent = routeData.RoutePlan[i].BatteryLevel;
      }
      if (routeData.RoutePlan[i].Type == RoutePlanType.Charger) {
        sumPercent += actPercent - routeData.RoutePlan[i].BatteryMinPercent;
        actPercent = routeData.RoutePlan[i].BatteryMaxPercent;
      }
      if (routeData.RoutePlan[i].Type == RoutePlanType.EndPoint) {
        sumPercent += actPercent - routeData.RoutePlan[i].BatteryLevel;
      }
    }

    return Math.round(sumPercent / 100 * this.mapService.getSelectedCar().DesignCapacity * this.inputParamsService.getBatteryStateOfHealth() / 100);
  }

  private getRoutePlan(resp: any): RoutePlanElement[] {
    const routePlan: RoutePlanElement[] = [];

    /** Start Point */
    const startPoint: RoutePlanElement = {
      Type: RoutePlanType.StartPoint,
      Name: this.mapService.parseAddressString(resp.waypointsInfo[0], resp.route[0][0], resp.route[0][1]),
      Icon: 'start_icon_list.svg',
      Location: new LatLng(resp.route[0][0], resp.route[0][1]),
      DistanceFromStart: null,
      DistanceFromPreviousElement: null,
      BatteryLevel: 100 - Math.round(resp.route[0][2] * 100),
      BatteryReservedWarning: false,
      WaypointIndex: null,
      BatteryMinPercent: null,
      BatteryMaxPercent: null,
      ChargeTimeHour: null,
      ChargeTimeMin: null,
      OperatorName: null,
      OperatorId: null,
      ChargerIndex: null,
      ChargePower: null,
      ChargingStationId: null,
      WeatherImg: null
    }

    routePlan.push(startPoint);

    let chargeplanIndex: number = 0;
    let waypointIndex: number = 1;
    let waypointsParams = this.inputParamsService.getWaypointsParams();
    let endpointCharger: boolean = false;

    for (let i = 0; i < resp.turns.length; i++) {
      /** Charger */
      if (resp.turns[i][0] == 100 || resp.turns[i][0] == 106) {
        let routePlanType;

        if (resp.chargingstations[chargeplanIndex][0] == waypointsParams[waypointsParams.length - 1].lat &&
          resp.chargingstations[chargeplanIndex][1] == waypointsParams[waypointsParams.length - 1].lng) {
            routePlanType = RoutePlanType.ChargerEndPoint;
            endpointCharger = true;
        }
        else {
          routePlanType = RoutePlanType.Charger
        }
        let chargeTimeMin: number = Math.round(resp.chargeplan[chargeplanIndex][2] / 5) * 5;

        let chargeTimeHour = Math.floor(chargeTimeMin / 60);
        chargeTimeMin = Math.floor(chargeTimeMin % 60);

        const charger: RoutePlanElement = {
          Type: routePlanType,
          Name: resp.chargingstations[chargeplanIndex][4].title,
          Icon: this.utilsService.chargingstationsImg[resp.chargeplan[chargeplanIndex][4] - 1][0],
          Location: new LatLng(resp.chargingstations[chargeplanIndex][0], resp.chargingstations[chargeplanIndex][1]),
          DistanceFromStart: resp.chargeplan[chargeplanIndex][3] / 1000,
          DistanceFromPreviousElement: resp.chargeplan[chargeplanIndex][3] / 1000 - routePlan[routePlan.length - 1].DistanceFromStart,
          BatteryReservedWarning: resp.chargeplan[chargeplanIndex][0] < this.inputParamsService.getECarSettings().batterySafetyLimitRoute,
          BatteryMinPercent: resp.chargeplan[chargeplanIndex][0],
          BatteryMaxPercent: resp.chargeplan[chargeplanIndex][1],
          ChargeTimeHour: chargeTimeHour,
          ChargeTimeMin: chargeTimeMin,
          OperatorName: resp.chargingstations[chargeplanIndex][4].opid == -1 ? "Unknown operator" : this.mapService.getChargingOperatorByID(resp.chargingstations[chargeplanIndex][4].opid).name,
          OperatorId: resp.chargingstations[chargeplanIndex][4].opid,
          ChargingStationId: resp.chargingstations[chargeplanIndex][4].id,
          ChargerIndex: chargeplanIndex,
          ChargePower: resp.chargingstations[chargeplanIndex][2],
          BatteryLevel: null,
          WaypointIndex: null,
          WeatherImg: this.accountService.getUserSubscriptionType() != SubscriptionType.Free ?
            this.utilsService.getWeatherImgById(resp.chargingstations[chargeplanIndex][4].weathercode) : null
        }
        chargeplanIndex++;

        routePlan.push(charger);
      }
      /** Way Point */
      if (resp.turns[i][0] == 103) {
        const waypoint: RoutePlanElement = {
          Type: RoutePlanType.WayPoint,
          Name: this.mapService.parseAddressString(resp.waypointsInfo[waypointIndex], resp.route[resp.turns[i][4]][0], resp.route[resp.turns[i][4]][1]),
          Icon: null,
          Location: new LatLng(resp.route[resp.turns[i][4]][0], resp.route[resp.turns[i][4]][1]),
          DistanceFromStart: resp.turns[i][3] / 1000,
          DistanceFromPreviousElement: resp.turns[i][3] / 1000 - routePlan[routePlan.length - 1].DistanceFromStart,
          BatteryReservedWarning: 100 - Math.round(resp.route[resp.turns[i][4]][2] * 100) < this.inputParamsService.getECarSettings().batterySafetyLimitRoute,
          BatteryLevel: 100 - Math.round(resp.route[resp.turns[i][4]][2] * 100),
          WaypointIndex: waypointIndex,
          BatteryMinPercent: null,
          BatteryMaxPercent: null,
          ChargeTimeHour: null,
          ChargeTimeMin: null,
          OperatorName: null,
          OperatorId: null,
          ChargerIndex: null,
          ChargePower: null,
          ChargingStationId: null,
          WeatherImg: this.accountService.getUserSubscriptionType() != SubscriptionType.Free ?
            this.utilsService.getWeatherImgById(resp.waypointsInfo[waypointIndex].weathercode) : null
        }
        routePlan.push(waypoint);

        waypointIndex++;
      }
    }

    /** End Point */
    if (!endpointCharger) {
      const endPoint: RoutePlanElement = {
        Type: RoutePlanType.EndPoint,
        Name: this.mapService.parseAddressString(resp.waypointsInfo[resp.waypointsInfo.length - 1], resp.route[resp.route.length - 1][0],
          resp.route[resp.route.length - 1][1]),
        Icon: 'target_icon_blue.svg',
        Location: new LatLng(resp.route[resp.route.length - 1][0], resp.route[resp.route.length - 1][1]),
        DistanceFromStart: resp.turns[resp.turns.length - 1][3] / 1000,
        DistanceFromPreviousElement: resp.turns[resp.turns.length - 1][3] / 1000 - routePlan[routePlan.length - 1].DistanceFromStart,
        BatteryLevel: 100 - Math.round(resp.route[resp.route.length - 1][2] * 100),
        BatteryReservedWarning: 100 - Math.round(resp.route[resp.route.length - 1][2] * 100) < this.inputParamsService.getECarSettings().batterySafetyLimitRoute,
        WaypointIndex: null,
        BatteryMinPercent: null,
        BatteryMaxPercent: null,
        ChargeTimeHour: null,
        ChargeTimeMin: null,
        OperatorName: null,
        OperatorId: null,
        ChargerIndex: null,
        ChargePower: null,
        ChargingStationId: null,
        WeatherImg: this.accountService.getUserSubscriptionType() != SubscriptionType.Free ?
          this.utilsService.getWeatherImgById(resp.waypointsInfo[resp.waypointsInfo.length - 1].weathercode) : null
      }
      routePlan.push(endPoint);
    }

    return routePlan;
  }

  private getTurns(resp: any): Turn[] {
    let turns: Turn[] = resp.turns.map((turnEl, index) => {
      const turn: Turn = {
        Idx: index,
        TypeIndex: turnEl[0],
        Id: this.utilsService.turnList[turnEl[0]].id,
        Instruction: [],
        Icon: [],
        CombinedTurn: (turnEl[0] < 12 || turnEl[0] >= 93) ? false : true,
        RoutePointIdxStart: turnEl[1],
        RoundaboutExitNumber: turnEl[2],
        DistanceFromStart: turnEl[3],
        RoutePointIdxEnd: turnEl[4],
        IsLeftHandTraffic: turnEl[5],
        LaneInfo: this.getLaneInfo(turnEl[6])
      }

      return turn;
    });

    // filter enter roundabout
    turns = turns.filter((el) => {
      return (el.TypeIndex != 94);
    });

    return turns;
  }

  private getLaneInfo(laneData: any[]): Lane[] {
    return laneData.map((laneEl) => {
      let roadLane: Lane = { Highlighted: laneEl[0], Images: [] };

      let orderedLaneIcons = [];
      for (let i = 0; i < laneEl[1].length; i++) {
        for (let j = 0; j < this.utilsService.laneTable.length; j++) {
          if (laneEl[1][i] == this.utilsService.laneTable[j]) {
            orderedLaneIcons.push(j);
            break;
          }
        }
      }

      orderedLaneIcons.sort();
      orderedLaneIcons = orderedLaneIcons.map((laneIdx) => { return this.utilsService.laneTable[laneIdx] });
      orderedLaneIcons.forEach((roadLaneIcon, i) => {
        roadLane.Images.push(this.utilsService.laneList[roadLaneIcon].toLocaleLowerCase() + "_" + (i + 1) + ".png");
      });

      return roadLane;
    });
  }

  public parseVehiclesArray(vehiclesData): ECarType[] {
    vehiclesData.sort((a, b) => { return a.vehicle_order - b.vehicle_order });
    const ECars = [];
    for (let i = 0; i < vehiclesData.length; i++) {
      // selecting active cars
      if (vehiclesData[i].is_active == 1) {
        ECars.push(new ECarType(vehiclesData[i].id, vehiclesData[i].car_weight, JSON.parse(vehiclesData[i].chargertypes), vehiclesData[i].designcapacity,
          vehiclesData[i].drag_coefficient, vehiclesData[i].drag_cross, vehiclesData[i].name, vehiclesData[i].range, vehiclesData[i].factory_range,
          vehiclesData[i].range_source, vehiclesData[i].topspeed, vehiclesData[i].totalpower, vehiclesData[i].image, parseFloat(vehiclesData[i].chargepower),
          vehiclesData[i].fastchargepower, vehiclesData[i].type, vehiclesData[i].subtype, vehiclesData[i].gcc_link));
      }
    }
    return ECars;
  }

  public parseChargingOperatorsArray(operatorsData): ChargingOperator[] {
    return operatorsData.sort((a, b) => { return a.name.localeCompare(b.name) }).map((operatorEl: any) => {
      return {
        name: operatorEl.name,
        id: parseInt(operatorEl.id),
        url: operatorEl.url
      }
    });
  }
}
