export class Computer {
  Type: string;
  Icon: string;
  DistToDest: number;
  TimeToDest: number;
  BatteryReservedWarning: boolean;

  constructor(type: string, icon: string, distToDest: number, timeToDest: number, batteryReservedWarning: boolean) {
    this.Type = type;
    this.Icon = icon;
    this.DistToDest = distToDest;
    this.TimeToDest = timeToDest;
    this.BatteryReservedWarning = batteryReservedWarning;
  }

  public Set(newComp: Computer) {
    if (this.Type != newComp.Type) {
      this.Type = newComp.Type;
    }

    if (this.Icon != newComp.Icon) {
      this.Icon = newComp.Icon;
    }

    if (this.DistToDest != newComp.DistToDest) {
      this.DistToDest = newComp.DistToDest;
    }

    if (this.TimeToDest != newComp.TimeToDest) {
      this.TimeToDest = newComp.TimeToDest;
    }

    if (this.BatteryReservedWarning != newComp.BatteryReservedWarning) {
      this.BatteryReservedWarning = newComp.BatteryReservedWarning;
    }
  }
}