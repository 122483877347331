import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService, SubscriptionType } from 'src/app/services/account.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-subscription-delete-dialog',
  templateUrl: './subscription-delete-dialog.component.html',
  styleUrl: './subscription-delete-dialog.component.scss'
})
export class SubscriptionDeleteDialogComponent {

  public type: SubscriptionType;
  public deleteSubscriptionDescription: string;

  constructor(public dialogRef: MatDialogRef<SubscriptionDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService, private accountService: AccountService) {

    this.type = this.data.subscriptionType.SubscriptionType;

    const subscriptionType = this.type?.includes('proplus.') ? "PRO<small>+</small>" : "PRO";
    this.deleteSubscriptionDescription = this.languageService.languageJSON.SubscriptionsDialog_DeleteSubscriptionDescription.replace("$subscriptionType", subscriptionType);
  }

  public close(): void {
    this.dialogRef.close({ delete: false });
  }

  public delete(): void {
    this.dialogRef.close({ delete: true });
  }
}
