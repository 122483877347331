<h1 mat-dialog-title>{{ languageService.languageJSON.GPSErrorDialog_Title }}</h1>
<div mat-dialog-content>
    <img *ngIf="!fbAndroid && !fbIos" alt="warning" class="gps-error-icon" [src]="'assets/icons/' + settingsService.getMap().Skin +  '/ui/start_icon.svg'" />
    <ul *ngIf="!fbAndroid && !fbIos">
        <li *ngFor="let informationTextElement of informationText" [innerHtml]="informationTextElement">
        </li>
    </ul>

    <div class="fb-error">
        <p *ngIf="fbAndroid" [innerHtml]="languageService.languageJSON.GPSErrorDialog_Facebook_Info_Android">
        </p>
        <img *ngIf="fbAndroid" [src]="'assets/icons/default/social/'+ settingsService.getLanguage() +'/google_play_button.svg'" class="download-icon"
            (click)="openUrl('https://play.google.com/store/apps/details?id=com.gpstuner.evnavigation')" />
        <p *ngIf="fbIos" [innerHtml]="languageService.languageJSON.GPSErrorDialog_Facebook_Info_Ios">
        </p>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="accept()">{{ languageService.languageJSON.Global_Ok }}</button>
</div>