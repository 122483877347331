import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  public title: string = "Info";
  public message: string = null;
  public button: string = "";

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public languageService: LanguageService) {
    this.button = this.languageService.languageJSON.Global_Close;
    if (data.title) {
      this.title = data.title;
    }
    if (data.message) {
      this.message = data.message;
    }
    if (this.data.button) {
      this.button = this.data.button;
    }
  }

  ngOnInit() {
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
