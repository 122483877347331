// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APPLE_CLIENT_ID: 'com.gpstuner.evnaviapp.client',
  APPLE_REDIRECT_URI: 'https://dev.evnavigation.dev.gpstuner.net/oauth/token.php',
  TRACK_CLOSE_TIME_SECS: 10*60,
  TRACK_CLOSE_DISTANCE_METERS: 2000,
  API_URL: 'https://staging-api.evnavigation.com/',
  BACKEND_URL : 'https://staging.evnavigation.com/',
  consolelog: true
};

/*
 * In delopment mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
